import allImages from '../../images';
const images = allImages['br'];

export const brTips = [
    {
        id: 1,
        question: "In which state is the macaw palm most common?",
        answers: ['Mato Grosso do Sul'],
        images: [ 
            images['br_macaw_palms.png'],
        ],
        full: "Macaw palms have thin leaves compared to other palms, and often have a visible spear shaped top. They are very common around Mato Grosso do Sul and the surrounding states, but can be seen much further out sometimes. These palms should be used with caution, and single trees are much less of an indicator than multiple trees.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "MGDS Macaw Palms",
    },
    {
        id: 2,
        question: "In which states is very tall grass most common?",
        answers: ['Tocantins', 'Goiás', 'Bahia','Minas Gerais' ],
        images: [ 
            images['br_tall_grass.png'],
        ],
        full: "Very tall grass can be often seen in the centre of the country, most notably in the Tocantins, Goiás, Bahia, and Minas Gerais states.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Central Tall Grass",
    },
    {
        id: 3,
        question: "Which state uses long, yellow pole paint with black lettering that starts with a number?",
        answers: ['Goiás'],
        images: [ 
            images['br_goias_pole_marking.png'],
        ],
        full: "Similar to the Bahia and Pernambuco poles, Goiás poles also use long, yellow pole paint with a black paint code, but it always starts with a number. They also have a rarer yellow on black pole ID, which can be either paint or a plate.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Goiás Pole Marking",

    },
    {
        id: 4,
        question: "Which states use this small yellow or orange pole plate?",
        answers: ['Rio Grande do Norte', 'Pernambuco'],
        images: [ 
            images['br_yellow_pole_plate.png'],
        ],
        full: "This small yellow or orange plate attached onto the pole by thin metal or zip tie is found in Rio Grande do Norte and Pernambuco. This ID is usually much higher up the pole in Pernambuco than in Rio Grande do Norte.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "RGDN/Pernambuco Yellow Pole Plate",
    },
    {
        id: 5,
        question: "Which state primarily uses this small beige plate with numbers that is built into the surface of the pole?",
        answers: ['Maranhão'],
        images: [ 
            images['br_maranhao_plate.png'],
        ],
        full: "Most poles in Maranhão have a small beige plate with numbers built into the surface of the pole. This pole ID can be found in Pará and Piauí more rarely.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Maranhão Plate",
    },
    {
        id: 6,
        question: "Which state sometimes paints its street names onto its poles?",
        answers: ['Paraná'],
        images: [ 
            images['br_parana_street_name_pole.png'],
        ],
        full: "Utility poles in Paraná on intersections are sometimes painted with the street name. This can sometimes extend to Santa Catarina or Rio Grande do Sul if there are ladder poles in the city.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Paraná Street Name Pole",
    },
    {
        id: 7,
        question: "Which state has more divided highways than any other state?",
        answers: ['São Paulo'],
        images: [ 
            images['br_sp_divided_highway.png'],
        ],
        full: "São Paulo has far more divided highways than any other state.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "São Paulo Divided Highway",
    },
    {
        id: 8,
        question: "In which state can you find phone numbers on the bottom of kilometer markers?",
        answers: ['São Paulo'],
        images: [ 
            images['br_sp_km_marker.png'],
        ],
        full: "Phone numbers can sometimes be seen underneath kilometre markers in São Paulo.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "São Paulo KM Marker",
    },
    {
        id: 9,
        question: "Cobblestone pavement is most commonly found in which states?",
        answers: ['Rio Grande do Sul', 'Pernaumbuco', 'Bahia', 'Espirito Santo', 'Serigipe', 'Alagoas', 'Paraíba', 'Rio Grande do Norte',],
        images: [ 
            images['br_cobblestone_pavement.png'],
        ],
        full: "Cobblestone pavement is most common in the northeast and in Rio Grande do Sul.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Cobblestone Pavement",
    },
    {
        id: 10,
        question: "Which states most frequently use hexagonal pavement?",
        answers: ['Santa Catarina', 'Minas Gerais'],
        images: [ 
            images['br_hexagonal_pavement.png'],
        ],
        full: "Roads with hexagonal concrete tiles can be found across Brazil, but they are more common in Santa Catarina and Minas Gerais.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "SC/MG Hexagonal Pavement",
    },
    {
        id: 11,
        question: "Which state can have roads made of red brick?",
        answers: ['Acre'],
        images: [ 
            images['br_acre_red_brick_road.png'],
        ],
        full: "Some roads in Acre are made of red brick.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Acre Red Brick Road",
    },
    {
        id: 12,
        question: "Which states use cobblestone pavement with randomly sized stones?",
        answers: ['Minas Gerais', 'Rio Grande do Sul'],
        images: [ 
            images['br_random_cobblestone.png'],
        ],
        full: "Cobblestone roads with randomly sized stones are most common in southeast Rio Grande do Sul and Minas Gerais.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "MG/RGDS Randomly Sized Cobblestone Pavement",
    },
    {
        id: 13,
        question: "The western regions of which states use this pavement with uneven but mostly square-shaped stones?",
        answers: ['Santa Catarina', 'Rio Grande do Sul'],
        images: [ 
            images['br_square_uneven_pavement.png'],
        ],
        full: "These uneven but mostly square stone roads are seen in western Rio Grande do Sul and west Santa Catarina. The soil will often be very dark in these regions too. NOTE: The same roads are seen in the Misiones province of Argentina.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "West SC/RGDS Square Uneven Cobblestone Pavement",
    },
    {
        id: 14,
        question: "Pavements in which states can be brown tiles that are either jagged and random or square?",
        answers: ['Rio Grande do Sul', 'Santa Catarina'],
        images: [ 
            images['br_rgds_square_pavement.png'],
        ],
        full: "You can often see pavements in Rio Grande do Sul that have brown tiles but are either jagged and random, or square. This style can sometimes be seen in Santa Catarina.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "RGDS Square Pavement",
    },
    {
        id: 15,
        question: "Roads in which state commonly have red gravel on the sides of its roads?",
        answers: ['Piuaí'],
        images: [ 
            images['br_piaui_gravel.png'],
        ],
        full: "Most roads in Piauí have red gravel on the side of the road. Some roads in Generation 4 now have tarmac and kerbs, but some gravel shoulders remain.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Piauí Red Gravel Shoulders",
    },
    {
        id: 16,
        question: "These insulators resembling brown mushrooms are found in which state?",
        answers: ['Pernambuco'],
        images: [ 
            images['br_pernambuco_insulators.png'],
        ],
        full: "Insulators that resemble brown mushrooms are only found in Pernambuco.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Pernambuco Brown Mushroom Insulators",
    },
    {
        id: 17,
        question: "These insulators resembling white mushrooms are found in which state?",
        answers: ['Bahia'],
        images: [ 
            images['br_bahia_insulators.png'],
        ],
        full: "Insulators that resemble white mushrooms are found in Bahia and rarely Brasília.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Bahia White Mushroom Insulators",
    },
    {
        id: 18,
        question: "Transparent insulators are most common in which states?",
        answers: ['Santa Catarina', 'Alagoas'],
        images: [ 
            images['br_transparent_insulators.png'],
        ],
        full: "Transparent insulators are mostly in Alagoas in the northeast, and Santa Catarina in the south. They can be seen rarely in Sergipe, Ceará, and Brasília.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "SC/AL Transparent Insulators",
    },
    {
        id: 19,
        question: "This pole with one insulator on top and alternating horizontal insulators is found in which states?",
        answers: ['Mato Grosso', 'Acre'],
        images: [ 
            images['br_mg_pole.png'],
        ],
        full: "Poles that have one insulator on top and alternating horizontal insulators can be found in Mato Grosso and southern Acre. It is also very rare in Rio Grande do Sul.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "MG/AC Pole",
    },
    {
        id: 20,
        question: "In which states are poles with horizontal insulators most common?",
        answers: ['Pará', 'Mato Grosso'],
        images: [ 
            images['br_horizontal_insulators.png'],
        ],
        full: "Poles with horizontal insulators are very common in Pará and less so in Mato Grosso. They are rarely found in Acre, Amapá, Tocantins, Santa Catarina and Rio Grande do Sul.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Pará/Mato Grosso Horizontal Insulators",
    },
    {
        id: 21,
        question: "Which state uses this trident pole with wide insulators?",
        answers: ['Maranhão'],
        images: [ 
            images['br_maranhao_pole.png'],
        ],
        full: "Poles with these wide insulators in a trident formation are common in Maranhão. The insulator itself is common across many states, but not in this configuration.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Maranhão Trident Pole",
    },
    {
        id: 22,
        question: "Which states use this spherical or conical insulator suspended on a road above the insulator bar, often referred to as 'lollipop' insulators?",
        answers: ['Rio Grande de Norte', 'Paraíba', 'Pernambuco', 'Sergipe'],
        images: [ 
            images['br_lollipop_insulators.png'],
        ],
        full: "These insulators are spherical or cone shaped and suspended on a rod above the insulator bar. They are often referred to as “lollipop” insulators. They are very common in Rio Grande de Norte, Paraíba, Pernambuco, and more rarely, Sergipe.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "RGDN/PB/PE/SE Lollipop Insulators",
    },
    {
        id: 23,
        question: "Which states frequently use concrete trash baskets as opposed to metal ones?",
        answers: ['Roraíma', 'Acre'],
        images: [ 
            images['br_concrete_trash_bin.png'],
        ],
        full: "Brazil often uses metal trash baskets outside houses. In Acre and Rondônia, however, it is much more common to see them made of concrete.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Roraíma/Acre Concrete Trash Bin",
    },
    {
        id: 24,
        question: "In which state does gen 4 coverage have a smudge on the front right of the camera, in addition to always featuring a white car with a blue stripe?",
        answers: ['Amapá'],
        images: [ 
            images['br_amapa_gen4.png'],
        ],
        full: "In all Generation 4 coverage in Amapá, the front right of the camera is smudged. In addition, all Generation 4 coverage in Amapá features the white car with the blue stripe.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Amapá Gen 4 Smudge & Car",
    },
    {
        id: 25,
        question: "Which state has this unique landscape of small grass hills and sparse trees that have roads cutting through instead of going around the hills?",
        answers: ['Acre'],
        images: [ 
            images['br_acre_landscape.png'],
        ],
        full: "Acre has a unique landscape of small grass hills and sparsely growing trees. The roads often do not cut around the hills like in most of Brazil, but ride the hills instead. A similar landscape can also be seen near Maraba in the state of Pará.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Acre Landscape", 
    },
    {
        id: 26,
        question: "Which state is characterized by grassy plains with mountains in the background, as well as distinctly thin bunched grass?",
        answers: ['Roraíma'],
        images: [ 
            images['br_roraima_landscape.png'],
        ],
        full: "Rural locations in Roraima are mostly open grassy plains with large, distant mountains in the background. Occasionally, you are on roads within the mountains. Furthermore, the grass in Roraima is distinctly thin, and grows in bunches.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Roraíma Landscape",
    },
    {
        id: 27,
        question: "Which state's landscape is characterized by lush green fields?",
        answers: ['Rio Grande do Sul'],
        images: [ 
            images['br_rgds_landscape.png'],
        ],
        full: "The landscape in Rio Grande do Sul can be hilly or flat with lush, green fields. Southern areas can have some resemblance to Uruguay.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "RGDS Landscape",
    },
    {
        id: 28,
        question: "Which states often have rolling grassy hills?",
        answers: ['Espírito Santo', 'Minas Gerais', 'Rio de Janeiro'],
        images: [ 
            images['br_hilly_landscape.png'],
        ],
        full: "Minas Gerais, Rio de Janeiro and Espírito Santo often have rolling grassy hills.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Minas/Rio/ES Hilly Landscape",
    },
    {
        id: 29,
        question: "Which state often has large mountains with exposed rock?",
        answers: ['Espírito Santo'],
        images: [ 
            images['br_es_mountains.png'],
        ],
        full: "Espírito Santo, and the surrounding areas, often have large mountains with exposed rock.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Espírito Santo Mountains",
    },
    {
        id: 30,
        question: "Which state are these pines found in?",
        answers: ['Paraná', 'Santa Catarina', 'Rio Grande do Sul'],
        images: [ 
            images['br_parana_pines.png'],
        ],
        full: "These distinct trees, called Paraná pines, have long horizontal branches with leaves only at the end. They grow mainly in the southern Brazilian states of Paraná, Santa Catarina, and Rio Grande do Sul.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Paraná Pines",

    },
    {
        id: 31,
        question: "In which state are round poles most common?",
        answers: ['São Paulo'],
        images: [ 
            images['br_sp_round_pole.png'],
        ],
        full: "Alongside ladder poles, round poles can often be found across the south of Brazil, especially São Paulo. These poles are cylindrical and made of concrete. They are also found relatively frequently in Rio Grande do Sul, Minas Gerais, Rio de Janeiro, and Espirito Santo. Regardless of the state, the centre of large cities will often have round poles.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "São Paulo Round Pole",
    },
    {
        id: 32,
        question: "In which state are wooden poles most common?",
        answers: ['Rio Grande do Sul', 'Roraima', 'Amazonas', 'Rio de Janeiro'],
        images: [ 
            images['br_wooden_pole.png'],
        ],
        full: "Some states also use wooden poles. They are most commonly seen in Rio Grande do Sul, Roraima, Amazonas, and Rio de Janeiro.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Wooden Pole",
    },
    {
        id: 33,
        question: "Buildings with European influence (namely German) tend to be in which states?",
        answers: ['Santa Catarina', 'Rio Grande do Sul', 'Paraná'],
        images: [ 
            images['br_southern_architecture.png'],
        ],
        full: "Buildings in the southern states, mainly Santa Catarina, can be of European influence (typically vaguely German) in design and style.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Southern Architecture",
    },
    {
        id: 34,
        question: "Which state commonly has wooden houses on stilts?",
        answers: ['Acre'],
        images: [ 
            images['br_acre_houses.png'],
        ],
        full: "Wooden houses on stilts are very common in the state of Acre. NOTE: They are also seen more rarely in low-lying Amazonas and Pará, as well as various other countries.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Acre Stilt Houses",
    },
    {
        id: 35,
        question: "Which states use this yellow paint pole marking with a black code that starts with a letter ('B' in the image')?",
        answers: ['Bahia', 'Pernambuco'],
        images: [ 
            images['br_bahia_pole_marking.png'],
        ],
        full: "Markings on poles are used by the electricity companies to identify them. This pole ID is long, yellow pole paint with a black code. The ones that start with a letter are found in Bahia and Pernambuco.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Bahia/Pernambuco Pole Marking w/ Letter",
    },
    {
        id: 36,
        question: "Carnaúba palms are most common in which states?",
        answers: ['Ceará', 'Piauí', 'Rio Grande do Norte'],
        images: [ 
            images['br_carnauba_palm.png'],
        ],
        full: "Carnaúba palms have short but strong, fan-like leaves and a trunk that is smooth at the top and spiked at the bottom. They are common in Ceará but are also in Piauí and Rio Grande do Norte.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Carnaúba Palms",
    },
    {
        id: 37,
        question: "Açaí palms are most common in which states?",
        answers: ['Pará'],
        images: [ 
            images['br_acai_palm.png'],
        ],
        full: "Açai palms produce fruits that are popular globally. Most of these palms are found near Belem, but can be seen across the Amazon. They are distinct in the way the leaflets droop downward along with their unusually thin trunks.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Belem Açaí Palms",
    },
    {
        id: 38,
        question: "Which state(s) use area codes starting with 1?",
        answers: ['São Paulo'],
        images: [ 
            images['br_1_code.png'],
        ],
        full: "Area codes starting with 1 are used in São Paulo.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "São Paulo 1 Area Code",
    },
    {
        id: 39,
        question: "Which state(s) use area codes starting with 2?",
        answers: ['Rio de Janeiro', 'Espírito Santo'],
        images: [ 
            images['br_2_code.png'],
        ],
        full: "Area codes starting with 2 are used in Rio de Janeiro and Espírito Santo.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "RJ/ES 2 Area Code",
    },
    {
        id: 40,
        question: "Which state(s) use area codes starting with 3?",
        answers: ['Minas Gerais'],
        images: [ 
            images['br_3_code.png'],
        ],
        full: "Area codes starting with 3 are used in Minas Gerais.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Minas Gerais 3 Area Code",
    },
    {
        id: 41,
        question: "Which state(s) use area codes starting with 4?",
        answers: ['Paraná', 'Santa Catarina'],
        images: [ 
            images['br_4_code.png'],
        ],
        full: "Area codes starting with 4 are used in Paraná and Santa Catarina.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "PR/SC 4 Area Code",

    },
    {
        id: 42,
        question: "Which state(s) use area codes starting with 5?",
        answers: ['Rio Grande do Sul'],
        images: [ 
            images['br_5_code.png'],
        ],
        full: "Area codes starting with 5 are used in Rio Grande do Sul.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Rio Grande do Sul 5 Area Code",
    },
    {
        id: 43,
        question: "Which state(s) use area codes starting with 6?",
        answers: ['Mato Grosso do Sul', 'Mato Grosso', 'Rondônia', 'Acre', 'Goías', 'Distrito Federal', 'Tocantins'],
        images: [ 
            images['br_6_code.png'],
        ],
        full: "Area codes starting with 6 are used in Mato Grosso do Sul, Mato Grosso, Rondônia, Acre, Goías, Distrito Federal, and Tocantins.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "6 Area Code",

    },
    {
        id: 44,
        question: "Which state uses the area code 67?",
        answers: ['Mato Grosso do Sul'],
        images: [ 
            images['br_mgds_67.png'],
        ],
        full: "The area code 67 is used in Mato Grosso do Sul.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Mato Grosso do Sul 67 Area Code",
    },
    {
        id: 45,
        question: "Which state uses the area code 61?",
        answers: ['Distrito Federal'],
        images: [ 
            images['br_61_code.png'],
        ],
        full: "The area code 61 is used in Distrito Federal. (Brasilia)",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Distrito Federal 61 Area Code",
    },
    {
        id: 46,
        question: "Which state uses the area code 63?",
        answers: ['Tocantins'],
        images: [ 
            images['br_tocantins_63.png'],
        ],
        full: "The area code 63 is used in Tocantins.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Tocantins 63 Area Code",
    },
    {
        id: 47,
        question: "Which state uses the area code 69?",
        answers: ['Rondônia'],
        images: [ 
            images['br_rondonia_69.png'],
        ],
        full: "The area code 69 is used in Rondônia.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Rondônia 69 Area Code",
    },
    {
        id: 48,
        question: "Which state uses the area code 68?",
        answers: ['Acre'],
        images: [ 
            images['br_acre_68.png'],
        ],
        full: "The area code 68 is used in Acre.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Acre 68 Area Code",
    },
    {
        id: 49,
        question: "Which state uses the area codes 62 and 64?",
        answers: ['Goiás'],
        images: [ 
            images['br_goias_code.png'],
        ],
        full: "The area codes 62 and 64 are used in Goiás.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Goiás 62/64 Area Code",
    },
    {
        id: 50,
        question: "Which state uses the area codes 65 and 66?",
        answers: ['Mato Grosso'],
        images: [ 
            images['br_mg_code.png'],
        ],
        full: "The area codes 65 and 66 are used in Mato Grosso.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Mato Grosso 65/66 Area Code",
    },
    {
        id: 51,
        question: "Which state(s) use area codes starting with 7?",
        answers: ['Bahia'],
        images: [ 
            images['br_7_code.png'],
        ],
        full: "Area codes starting with 7 are used in Bahia.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Bahia 7 Area Code",
    },
    {
        id: 52,
        question: "Which state(s) use area codes starting with 8?",
        answers: ['Pernambuco', 'Alagoas', 'Paraíba', 'Rio Grande do Norte', 'Ceará', 'Piauí'],
        images: [ 
            images['br_8_code.png'],
        ],
        full: "Area codes starting with 8 are used in the northeast states of Pernambuco, Alagoas, Paraíba, Rio Grande do Norte, Ceará, and Piauí.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Northeast 8 Area Code",
    },
    {
        id: 53,
        question: "Which state(s) use area codes starting with 9?",
        answers: ['Pará', 'Amazonas', 'Amapá', 'Roraima', 'Maranhão'],
        images: [ 
            images['br_9_code.png'],
        ],
        full: "Area codes starting with 9 are used in the Amazonian states of Pará, Amazonas, Amapá, Roraima, and Maranhão.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "North/Northwest 9 Area Code",
    },
    {
        id: 54,
        question: "Which state uses the area codes 98 and 99?",
        answers: ['Maranhão'],
        images: [ 
            images['br_maranhao_code.png'],
        ],
        full: "The area codes 98 and 99 are used in Maranhão.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Maranhão 98/99 Area Code",
    },
    {
        id: 55,
        question: "Which state uses the area code 96?",
        answers: ['Amapá'],
        images: [ 
            images['br_amapa_96.png'],
        ],
        full: "The area code 96 is used in Amapá.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Amapá 96 Area Code",
    },
    {
        id: 56,
        question: "Which state uses the area code 95?",
        answers: ['Roraima'],
        images: [ 
            images['br_roraima_95.png'],
        ],
        full: "The area code 95 is used in Roraima.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Roraima 95 Area Code",
    },
    {
        id: 57,
        question: "Which state uses the area codes 92 and 97?",
        answers: ['Amazonas'],
        images: [ 
            images['br_amazonas_code.png'],
        ],
        full: "The area codes 92 and 97 are used in Amazonas. In practice, though, you will mostly see 92 in urban areas.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Amazonas 92/97 Area Code",

    },
    {
        id: 58,
        question: "Which state uses the area code 79?",
        answers: ['Sergipe'],
        images: [ 
            images['br_sergipe_79.png'],
        ],
        full: "The area code 79 is used in Sergipe.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Sergipe 79 Area Code",

    },
    {
        id: 59,
        question: "Which state uses the area code 82?",
        answers: ['Alagoas'],
        images: [ 
            images['br_alagoas_82.png'],
        ],
        full: "The area code 82 is used in Alagoas.",   
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Alagoas 82 Area Code",
    },
    {
        id: 60,
        question: "Which state uses the area codes 85 and 88?",
        answers: ['Ceará'],
        images: [ 
            images['br_ceara_code.png'],
        ],
        full: "The area codes 85 and 88 are used in Ceará. 85 is used in the Forteleza area, while 88 is used in the rest of the state.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Ceará 85/88 Area Code",
    },
    {
        id: 61,
        question: "Which state uses the area code 84?",
        answers: ['Rio Grande do Norte'],
        images: [ 
            images['br_rn_84.png'],
        ],
        full: "The area code 84 is used in Rio Grande do Norte.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Rio Grande do Norte 84 Area Code",
    },
    {
        id: 62,
        question: "Which state uses the area code 83?",
        answers: ['Paraíba'],
        images: [ 
            images['br_paraiba_83.png'],
        ],
        full: "The area code 83 is used in Paraíba.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Paraíba 83 Area Code",
    },
    {
        id: 63,
        question: "Which state uses the area codes 81 and 87?",
        answers: ['Pernambuco'],
        images: [ 
            images['br_pernambuco_87.png'],
        ],
        full: "The area codes 81 and 87 are used in Pernambuco. 81 is used in the Recife area, while 87 is used in the rest of the state.",
        source: "Plonk It Guide - Area Codes",
        sourceURL: "https://www.plonkit.net/brazil",
        short: "Pernambuco 81/87 Area Code",
    }
]
export const brSources = ['Plonk It Guide', 'Plonk It Guide - Area Codes'];
