import allImages from '../../images';
const images = allImages['jp'];

export const jpTips = [
    {
        id: 1,
        question: "The pole plate of which region has many characters and is sometimes yellow?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_pole_plate.png'],
        ],
        full: "Hokkaido's pole plate has many characters and is sometimes yellow.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Pole Plate",
        mode: 'region'
    },
    {
        id: 2,
        question: "The pole plate of which region has one horizontal and one vertical plate?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_pole_plate.png'],
        ],
        full: "Tohoku's pole plate can be silver in color and has one horizontal and one generic vertical plate.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Pole Plate",
        mode: 'region'
    },
    {
        id: 3,
        question: "The pole plate of which region is often silver with handwritten characters, and features horizontal characters above three vertical digits?",
        answers: ['Kanto'],
        images: [
            images['jp_kanto_pole_plate.png'],
        ],
        full: "Kanto's pole plate is often silver with handwritten characters, and features horizontal characters above three vertical digits. It can also sometimes be white.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kanto Pole Plate",
        mode: 'region'
    },
    {
        id: 4,
        question: "The pole plate of which region includes multiple horizontal plates?",
        answers: ['Hokuriku'],
        images: [
            images['jp_hokuriku_pole_plate.png'],
        ],
        full: "Hokuriku's pole plate features multiple horizontal plates, including the NTT plate. NOTE: The prefectures of Niigata and Nagano (in Tohoku and Chubu respectively) also commonly feature horizontal plates.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokuriku Pole Plate",
        mode: 'region'
    },
    {
        id: 5,
        question: "The pole plate of which region has a rounded shape and is sometimes yellow?",
        answers: ['Chubu'],
        images: [
            images['jp_chubu_pole_plate.png'],
        ],
        full: "Chubu's pole plate has a rounded shape and is sometimes yellow.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu Pole Plate",
        mode: 'region'
    },
    {
        id: 6,
        question: "The pole plate of which region has Japanese written vertically on the right, with other characters below it in rows of 2?",
        answers: ['Kansai'],
        images: [
            images['jp_kansai_pole_plate.png'],
        ],
        full: "Kansai's pole plate has Japanese written vertically on the right, with other characters below it in rows of 2.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kansai Pole Plate",
        mode: 'region'
    },
    {
        id: 7,
        question: "The pole plate of which region is sometimes larger than the NTT plate, has 'Energia' written in Latin, and can have an orange arrow?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_pole_plate.png'],
        ],
        full: "Chugoku's pole plate is sometimes larger than the NTT plate, has 'Energia' written in Latin, and can have an orange arrow.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Pole Plate",
        mode: 'region'
    },
    {
        id: 8,
        question: "The pole plate of which region features multiple rows of characters from left to right and can have this orange band?",
        answers: ['Shikoku'],
        images: [
            images['jp_shikoku_pole_plate.png'],
        ],
        full: "Shikoku's pole plate has multiple rows of characters from left to right and can have the orange band shown.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Shikoku Pole Plate",
        mode: 'region'
    },
    {
        id: 9,
        question: "The pole plate of which region has 3 numbers horizontally near the top, with 4 vertical characters below to the right?",
        answers: ['Kyushu'],
        images: [
            images['jp_kyushu_pole_plate.png'],
        ],
        full: "Kyushu's pole plate has 3 numbers horizontally near the top, with 4 vertical characters below them on the right.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu Pole Plate",
        mode: 'region'
    },
    {
        id: 10,
        question: "The pole plate of which region can have up to 3 or 4 vertical plates, and horizontal bars between characters?",
        answers: ['Okinawa'],   
        images: [
            images['jp_okinawa_pole_plate.png'],
        ],
        full: "Okinawa's pole plate can have up to 3 or 4 vertical plates, and features horizontal bars between the characters.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Okinawa Pole Plate",
    },
    {
        id: 11,
        question: "In which region are reflectors with gray (plus white or, more uncommonly, yellow) used?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_reflector.png'],
        ],
        full: "Tohoku uses gray and white reflectors, which are also uncommonly found in other regions. They are sometimes gray and yellow. ",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Reflector",
        mode: 'region'
    },
    {
        id: 12,
        question: "In which region are these reflectors found?",
        answers: ['Hokuriku'],
        images: [
            images['jp_hokuriku_reflector.png'],
        ],
        full: "Hokuriku uses these reflectors comprised of three detached spiral stripes.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokuriku Reflector",
        mode: 'region'
    },
    {
        id: 13,
        question: "In which region are these reflectors with diagonal stripes, alternating colors, and yellow text on the top and bottom found?",
        answers: ['Chubu'],
        images: [
            images['jp_chubu_reflector.png'],
        ],
        full: "Chubu uses these reflectors with diagonal stripes of alternating colors and yellow text on the top and bottom.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu Reflector",
        mode: 'region'
    },
    {
        id: 14,
        question: "In which region are these smaller reflectors with 4 sections and yellow text on top found?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_reflector.png'],
        ],
        full: "Chugoku uses these smaller reflectors with 4 sections and yellow text on top.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Reflector",
        mode: 'region'
    },
    {
        id: 15,
        question: "In which region are these tall reflectors with washed out colors found?",
        answers: ['Kansai'],
        images: [
            images['jp_kansai_reflector.png'],
        ],
        full: "Kansai uses these tall reflectors with washed out colors.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kansai Reflector",
        mode: 'region'
    },
    {
        id: 16,
        question: "In which region are these small reflectors with 6 sections found?",
        answers: ['Shikoku'],
        images: [
            images['jp_shikoku_reflector.png'],
        ],
        full: "Shikoku uses these small reflectors with 6 sections.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Shikoku Reflector",
        mode: 'region'
    },
    {
        id: 17,
        question: "In which region are these reflectors with darker reflectors in the middle and protruding bumps found?",
        answers: ['Kyushu, Okinawa'],
        images: [
            images['jp_kyushu_reflector.png'],
        ],
        full: "Kyushu & Okinawa use these reflectors with darker reflectors in the middle and protruding bumps.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu & Okinawa Reflector",
        mode: 'region'
    },
    {
        id: 18,
        question: "In which region can this guy wire with wide black and yellow stripes be found?",
        answers: ['Chubu', 'Okinawa'],
        images: [
            images['jp_chubu_guywire.png'],
        ],
        full: "Chubu and Okinawa use this guy wire with wide black and yellow stripes.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu & Okinawa Guy Wire",
        mode: 'region'
    },
    {
        id: 19,
        question: "In which region can this bottle-shaped guy wire with black and yellow stripes be found?",
        answers: ['Kansai', 'Hokuriku', 'Shikoku'],
        images: [
            images['jp_kansai_guywire.png'],
        ],
        full: "Kansai, Hokuriku, and Shikoku use this bottle-shaped guy wire with black and yellow stripes.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kansai, Hokuriku, & Shikoku Guy Wire",
        mode: 'region'
    },
    {
        id: 20,
        question: "In which region are these yellow-orange guy wires with a bulge at the bottom found?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_guywire.png'],
        ],
        full: "Chugoku uses these yellow-orange guy wires with a large bulge near the bottom.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Guy Wire",
        mode: 'region'
    },
    {
        id: 21,
        question: "In which region are these yellow guy wires with a flat black lid in the middle found?",
        answers: ['Kyushu', 'Okinawa'],
        images: [
            images['jp_kyushu_guywire.png'],
        ],
        full: "Kyushu and Okinawa use these yellow guy wires with a flat black lid at the middle.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu & Okinawa Guy Wire",
        mode: 'region'
    },
    {
        id: 22,
        question: "These attachments with a 120 degree angle can be found in which region?",
        answers: ['Kansai'],
        images: [
            images['jp_kansai_pole.png'],
            images['jp_kansai_pole2.png']
        ],
        full: "These attachments with an approximately 120-degree angle can commonly be found on poles in Kansai. Sometimes there is no angle, and instead just an angled downward attachment.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kansai 120 Deg Pole Attachment",
        mode: 'region'
    },
    {
        id: 23,
        question: "These attachments with a 90 degree angle can be found in which region?",
        answers: ['Shikoku'],
        images: [
            images['jp_shikoku_attachment.png'],
        ],
        full: "These smaller attachments with a 90-degree angle can commonly be found on poles in Shikoku.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Shikoku 90 Deg Pole Attachment",
        mode: 'region'
    },
    {
        id: 24,
        question: "Poles with a plus-shaped bar attached to the side can be found in which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_plus_bar.png'],
        ],
        full: "The electrical poles in Hokkaido either have this plus-shaped bar attached to the side of the pole, or one transformer mounted on two bars (in another tip). Note the attachment to the side of the pole rather than the middle, differentiating it from Kansai or Chugoku, which are attached to the middle.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Plus Bar Attached to Side of Pole",
        mode: 'region'
    },
    {
        id: 25,
        question: "Poles with a transformer mounted on two bars can be found in which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_double_bar.png'],
        ],
        full: "The electrical poles in Hokkaido either have this transformer mounted on two bars, or a plus-shaped bar attached to the side of the pole (in another tip). NOTE: While the Kanto transformer may also be mounted on two bars, Hokkaido will never have a wooden plate.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Transformer Mounted on Two Bars",
        mode: 'region'
    },
    {
        id: 26,
        question: "Poles with multiple transformers mounted on the side of a square can be found in which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_square_bar.png'],
        ],
        full: "In Hokkaido, if there is more than one transformer, they will often be mounted on the sides of a square.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Multiple Transformers on Square Bar",
        mode: 'region'
    },
    {
        id: 27,
        question: "Transformers with additional square holding brackets (note the two visible on the transformer side here) can be found most commonly in which region?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_square_brackets.png'],
        ],
        full: "The transformers in Tohoku often have additional square holding brackets and the logo of the Tohoku Electrical Company, and are sometimes mounted on a round plate on a zigzag bar.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Transformers with Square Brackets",
        mode: 'region'
    },
    {
        id: 28,
        question: "This logo can be found on transformers in which region?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_logo.png'],
        ],
        full: "The transformers in Tohoku often have additional square holding brackets and the logo of the Tohoku Electrical Company (pictured here, above the '30'), and are sometimes mounted on a round plate on a zigzag bar.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Transformers with Logo",
        mode: 'region'
    },
    {
        id: 29,
        question: "Transformers mounted on a round plate on a zigzag bar can be found in which region?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_circle_plate.png'],
        ],
        full: "The transformers in Tohoku often have additional square holding brackets and the logo of the Tohoku Electrical Company, and are sometimes mounted on a round plate on a zigzag bar. (Note the circular plate the transformers are atop, and the curving bar).",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Transformers on Round Plate & Zigzag Bar",
        mode: 'region'

    },
    {
        id: 30,
        question: "Transformers in which region can be mounted on the side of a square with a straight bar mounted on the top of the square?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_square_bar.png'],
        ],
        full: "In Tohoku, you may also find transformers mounted on the side of a square, with a straight bar mounted on the top of the square. Rarely, you may find a square without a bar. NOTE: While Kansai may have a square attachment as well, the bar will be mounted on the bottom rather than the top.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Transformers on Square with Straight Bar",
        mode: 'region'
    },
    {
        id: 31,
        question: "Transformers in which region can have two or three insulators and are often mounted on a wooden plate and two bars?",
        answers: ['Kanto'],
        images: [
            images['jp_kanto_transformers.png'],
        ],
        full: "The transformers in Kanto, which can have three or two insulators, are often mounted on a wooden plate and two bars, or a round plate and a T-shaped bar. (covered in another tip)",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kanto Insulators + Transformers on Wooden Plate w/ Two Bars",
        mode: 'region'
    },
    {
        id: 32,
        question: "Transformers in which region can have two or three insulators and be mounted on a round plate with a T-shaped bar?",
        answers: ['Kanto'],
        images: [
            images['jp_kanto_round_plate.png'],
        ],
        full: "The transformers in Kanto, which can have three or two insulators, may be mounted on a wooden plate and two bars, or a round plate and a T-shaped bar. (covered in another tip)",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kanto Insulators & Transformers on Round Plate w/ T-Shaped Bar",
        mode: 'region'
    },
    {
        id: 33,
        question: "Transformers in which region are attached with thin metal bars and often have small 'lamp-shaped' insulators?",
        answers: ['Hokuriku'],
        images: [
            images['jp_hokuriku_transformers.png'],
        ],
        full: "The transformers in Hokuriku, which are attached with thin metal bars, often have these small “lamp” shaped insulators. There will never be a bar present except for the attachment mentioned above. NOTE: The prefecture of Niigata will have the Tohoku transformer design, as opposed to the Hokuriku one.",
        // Note: This will be incorrectly reported as belonging to Hokuriku, with current setup. Possible solution: Make a custom answer list for this question.
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokuriku Thin Metal Bars & Lamp Insulators",
        mode: 'region'
    },
    {
        id: 34,
        question: "Transformers in which region often have painted yellow numbers, black 'tap' connectors, and are attached by a large triangle and horizontal bars?",
        answers: ['Chubu'],
        images: [
            images['jp_chubu_transformers.png'],
        ],
        full: "The transformers in Chubu, which often have painted yellow numbers and black “tap” connectors, are either attached with large triangle and horizontal bars, or these thin metal brackets. (covered in another tip)",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu Tap Connectors & Triangle Attachment",
        mode: 'region'
    },
    {
        id: 35,
        question: "Transformers in which region often have painted yellow numbers, black 'tap' connectors, and are attached by these thin metal brackets?",
        answers: ['Chubu'],
        images: [
            images['jp_chubu_brackets.png'],
        ],
        full: "The transformers in Chubu, which often have painted yellow numbers and black “tap” connectors, are either attached with large triangle and horizontal bars (covered in another tip), or these thin metal brackets.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu Tap Connectors & Thin Metal Brackets",
        mode: 'region'
    },
    {
        id: 36,
        question: "Transformers in which region have a straight or zigzag bar mounted on the bottom of a square attachment, with the transformers themselves being mounted on the square's sides?",
        answers: ['Kansai'],
        images: [
            images['jp_kansai_transformer.png'],
        ],
        full: "The transformers in Kansai have either a straight or zigzag bar mounted on the bottom of a square. The transformer(s) will be mounted on the sides of that same square. While this is uncommon, the bar and transformer(s) may be mounted on a vertical bar, rather than a square. NOTE: While Tohoku may have a square attachment as well, the bar will be mounted on the top rather than the bottom.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kansai Square w/ Straight or Zigzag Bar on Bottom",
        mode: 'region'
    },
    {
        id: 37,
        question: "Transformers in which region have white stickers with red numbers on the bottom or side?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_transformer_numbers.png'],
        ],
        full: "In Chugoku, a white sticker will often be stuck to the bottom of the transformer with red numbers. They may also be painted on the side.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Transformer Numbers",
        mode: 'region'
    },
    {
        id: 38,
        question: "Transformers in which region have a cross-shaped bar attached in the middle of the pole, mounted by a full triangle, with long horizontal or short vertical insulator(s) at the end?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_transformer.png'],
        ],
        full: "The transformers in Chugoku, which have a cross-shaped bar attached in the middle of the pole, mounted by a full triangle, will have a long horizontal or short vertical insulator(s) at the end.",
        // Note: This picture is pretty bad at showing the full triangle
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Cross-Shaped Bar & Full Triangle",
        mode: 'region'
    },
    {
        id: 39,
        question: "Transformers in which region have a cross-shaped bar attached in the middle of the pole, mounted by a half triangle, with 'hat shaped' short horizontal insulators at the end, as well as white stickers on the bottoms with black numbers?",
        answers: ['Shikoku'],
        images: [
            images['jp_shikoku_transformer.png'],
        ],
        full: "The transformers in Shikoku, which also have a cross-shaped bar attached in the middle of the pole, mounted by a half triangle, will have “hat shaped” short horizontal insulators at the end. A white sticker will often be stuck to the bottom of the transformer with black numbers.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Shikoku Cross-Shaped Bar & Half Triangle",
        mode: 'region'
    },
    {
        id: 40,
        question: "Poles in which region feature a straight bar attached on the side of the pole with three insulators on the end and mount transformers 180 degrees from one another?",
        answers: ['Kyushu'],
        images: [
            images['jp_kyushu_transformer.png'],
        ],
        full: "The transformers in Kyushu, which have a straight bar attached on the side of the pole with three insulators at the end, will have transformers mounted 180-degrees from one another.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu Straight Bar & Three Insulators",
        mode: 'region'
    },
    {
        id: 41,
        question: "Poles in which region have a T-shaped bar attached on the side of the pole with transformers mounted on opposite sides of it?",
        answers: ['Okinawa'],
        images: [
            images['jp_okinawa_transformer.png'],
        ],
        full: "Transformers in Okinawa, which have a T-shaped bar attached on the side of the pole, will have transformers mounted on opposite sides of the pole.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Okinawa T-Shaped Bar",
        mode: 'region'
    },
    {
        id: 42,
        question: "In which regions are these gray 'insulator-esque' blocks commonly found?",
        answers: ['Chubu', 'Kansai', 'Chugoku', 'Shikoku'],
        images: [
            images['jp_insulator_blocks.png'],
        ],
        full: "These grey “insulator-esque” blocks, which wires are fed through, can be found in the regions of Chubu, Kansai, Chugoku, Shikoku, and very rarely in Hokkaido.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Gray Insulator Blocks",
        mode: 'region'
    },
    {
        id: 43,
        question: "This pole top with four thicker bars and a circular piece at the top is found in which region?",
        answers: ['Chubu'],
        images: [
            images['jp_chubu_pole_top.png'],
        ],
        full: "This pole top, with four thicker bars and a circle piece at the top, is common in the Chubu region.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu Pole Top",
        mode: 'region'
    },
    {
        id: 44,
        question: "This tent-shaped pole top with two sides filled in is found in which region?",
        answers: ['Hokuriku'],
        images: [
            images['jp_hokuriku_pole_top.png'],
        ],
        full: "This pole top, with two sides filled in and shaped like a tent, is common in the Hokuriku region.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokuriku Pole Top",
        mode: 'region'
    },
    {
        id: 45,
        question: "In which region is this pole top with four skinny bars commonly found?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_pole_top.png'],
        ],
        full: "This pole top, with four thin, skinny bars, is common in the Chugoku region.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Pole Top",
        mode: 'region'
    },
    {
        id: 46,
        question: "In which region is this tuning fork pole top with a long top bar that sticks out at each end and horizontal bar(s) below found?",
        answers: ['Kanto'],
        images: [
            images['jp_kanto_pole_top.png'],
        ],
        full: "This tuning fork pole top, with a long top bar that sticks out each end and horizontal bar(s) below, is found the majority of the time in the Kanto region.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan", 
        short: "Kanto Tuning Fork Pole Top",
        mode: 'region'
    },
    {
        id: 47,
        question: "In which region is this tuning fork pole top with a short top bar and a horizontal bar below most commonly found?",
        answers: ['Chubu'],
        images: [
            images['jp_chubu_tuning_fork.png'],
        ],
        full: "This tuning fork pole top, with a short top bar and horizontal bar below, is found the majority of the time in the Chubu region.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu Tuning Fork Pole Top",
        mode: 'region'
    },
    {
        id: 48,
        question: "In which region is this tuning fork pole top with a thin wire and horizontal bar below found?",
        answers: ['Kansai'],
        images: [
            images['jp_kansai_tuning_fork.png'],
        ],
        full: "This tuning fork pole top, with a thin wire and horizontal bar below, is common in the Kansai region.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kansai Tuning Fork Pole Top",
        mode: 'region'
    },
    {
        id: 49,
        question: "This infrastructural arrow is essentially exclusive to which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_arrow.png'],
        ],
        full: "This infrastructural arrow informs drivers of where the shoulder line is when under snow, and is located almost exclusively in Hokkaido. Note that alternative designs exist rarely in Aomori, Gifu, and various areas of Hokkaido.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Arrow",
        mode: 'region'
    },
    {
        // Note: Technically this answer should include Yamaguchi pref.
        id: 50,
        question: "This flat, white bollard with a series of separated orange reflectors on one side and the white reflectors on the other is found in which region?",
        answers: ['Kyushu'],
        images: [
            images['jp_kyushu_bollard.png'],
        ],
        full: "This flat, white bollard, found on the island of Kyushu and in Yamaguchi Prefecture, will have a series of separated orange reflectors on one side, and the same white on the opposite. NOTE: Rarely, you may find similar bollards in Hokkaido. However, both sides will have orange reflectors.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu Bollard",
    },
    {
        id: 51,
        question: "This bollard with a black stripe just under the reflector can be found in which region?",
        answers: ['Shikoku'],
        images: [
            images['jp_shikoku_bollard.png'],
        ],
        full: "Some bollards on the island of Shikoku can have a black stripe just under the reflector. They can be found normally, attached to guardrails or walls (as pictured here).",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Shikoku Bollard Black Stripe",
        mode: 'region'
    },
    {
        id: 52,
        question: "This thick white bollard with an octagonal reflector and a yellow stripe underneath can be found in which region?",
        answers: ['Tohoku'],
        images: [
            images['jp_tohoku_bollard.png'],
        ],
        full: "This thick, white bollard, found in the Tohoku region, will have an octagonal reflector, and yellow stripe just underneath. In addition, you can find the same bollard, but with a rectangular top. Finally, there are two other variations. You can find a black octagonal, and rectangular version. They are often found on National Highways or Expressways and can have the number printed on them.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku Bollard Yellow Stripe",
        mode: 'region'
    },
    {
        id: 53,
        question: "This extendable bollard with only one reflector and a red stripe under the reflector is found in which prefecture?",
        answers: ['Toyama'],
        images: [
            images['jp_toyama_bollard.png'],
        ],
        full: "The Toyama prefecture bollard has one reflector, is extendable and has a red stripe underneath the reflector. NOTE: Be careful: if there is either a second reflector, or a yellow stripe instead of a red one, these are different bollards. These are more common variations found across many prefectures.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Toyama Bollard",
        mode: 'prefecture'
    },
    {
        id: 54,
        question: "This bollard with an octagonal reflector, a red stripe underneath it, and a sloped connection where the base meets the reflector is found in which prefecture?",
        answers: ['Okayama'],
        images: [
            images['jp_okayama_bollard.png'],
        ],
        full: "This bollard, with an octagonal reflector, and red stripe underneath it, is exclusive to the Okayama prefecture. Additionally, there will be a sloped connection where the base meets the reflector. Note: This picture also features the prefecture kanji for Okayama.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Okayama Bollard",
        mode: 'prefecture'
    },
    {
        id: 55,
        question: "This striped bollard (covering over the original) with a thinner top half and no reflectors are especially common to which prefecture as well as that prefecture's region as a whole?",
        answers: ['Akita'],
        images: [
            images['jp_akita_striped_bollard.png'],
        ],
        full: "These striped bollards (which cover over the original) have a thinner top half, and no reflectors, and are common to the Tohoku region. They are especially common in Akita prefecture.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tohoku (Akita) Striped Bollard",
        mode: 'prefecture'
    },
    {
        id: 56,
        question: "In the region where green bands on guardrails can be found, which prefectures have the most prevalent use of them?",
        answers: ['Oita', 'Miyazaki'],
        images: [
            images['jp_green_bands.png'],
        ],
        full: "Green bands on guardrails are most commonly found in Oita and Miyazaki, but can be found on all of Kyushu.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu (Oita/Miyazaki) Green Bands",
        mode: 'prefecture'
    },
    {
        id: 57,
        question: "In which prefecture are yellow guardrails found?",
        answers: ['Yamaguchi'],
        images: [
            images['jp_yamaguchi_yellow_guardrail.png'],
        ],
        full: "These distinctive yellow guardrails can be found in the Yamaguchi Prefecture.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Yamaguchi Yellow Guardrail",
        mode: 'prefecture'
    },
    {
        id: 58,
        question: "In which region can you find these chevron-like stickers on guardrails and other infrastructure?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_chevron.png'],
        ],
        full: "You can find these chevron-like stickers, or more rarely orange ones, on guardrails in Hokkaido. They can be found on the end or side of a guardrail, stuck to metal posts between wire fences (often found in Hokkaido), or on railings (which are often found on bridges) underneath the inside of the railing.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Chevron Stickers",
        mode: 'region'
    },
    {
        id: 59,
        question: "Guardrail endings with these red and yellow stripes are commonly found in which region?",
        answers: ['Chugoku'],
        images: [
            images['jp_chugoku_guardrail_ending.png'],
        ],
        full: "Guardrail endings with these red and yellow striped stickers can commonly be found across Chugoku, except in Yamaguchi. They can also rarely be found in Niigata.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku Guardrail Ending",
        mode: 'region'
    },
    {
        id: 60,
        question: "Although most of Japan uses horizontal traffic lights, vertical traffic lights can be found across the northwestern coast and which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_vertical_traffic_light.png'],
        ],
        full: "Vertical traffic lights can be found in Hokkaido, and along the northwestern coast. The usual horizontal traffic lights can be found everywhere else.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Vertical Traffic Light",
        mode: 'region'
    },
    {
        id: 61,
        question: "These black signs with two yellow circles can be found in which prefecture?",
        answers: ['Kagawa'],
        images: [
            images['jp_kagawa_sign.png'],
        ],
        full: "These specific black signs with two yellow circles are found in Kagawa. Other variations can be seen, including them stacked on top of each other, or with an arrow attached above.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kagawa Black Sign with Yellow Circles",
        mode: 'prefecture'

    },
    {
        id: 62,
        question: "Generally speaking, these kerosene tanks are most common in which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_kerosene_tanks.png'],
        ],
        full: "Kerosine tanks, otherwise known as “Claymores”, can be found more commonly the further north you go. They are abundant in Hokkaido, but can be found in prefectures south of it, or in high elevation mountainous areas.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Northern Kerosene Tank",
        mode: 'region'
    },
    {
        id: 63,
        question: "Tanks with hot water, gas, or propane are most frequently found in which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hot_water_tank.png'],
        ],
        full: "Hot water, gas and propane tanks can be found more frequently in northern prefectures or high elevation mountainous areas. Here are a few common variations you can find: the standard hot water tank, covered and exposed propane tanks, cylindrical tanks, and circular tripod tanks. (Check Plonk It for corresponding pictures) Moreover, these are even more common in rural towns or villages, where major water or gas lines are unavailable.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Northern Hot Water Tank",
        mode: 'region'
    },
    {
        id: 64,
        question: "In which prefectures can you most commonly find these snow prevention roads, appearing as small circles in the pavement?",
        answers: ['Niigata', 'Toyama', 'Ishikawa', 'Fukui'],
        images: [
            images['jp_snow_prevention_road.png'],
        ],
        full: "Snow prevention roads, known as ‘Shosetsu’, are found mainly in northwest coastal prefectures. They appear as tiny circles in the pavement, and spray warm underground water to clear snow. In addition to the prefectures provided, they can also sometimes be found in Fukushima, Yamagata, Akita, and Kyoto, among others.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Northwest Coast Snow Prevention Road",
        mode: 'prefecture'
    },
    {
        id: 65,
        question: "Biking routes in which prefecture use an outside white and inside blue line, especially along the coast?",
        answers: ['Ehime'],
        images: [
            images['jp_ehime_blue_road_line.png'],
        ],
        full: "Cycling routes in Ehime use an outside white, with an inside blue line. They are more commonly found along the coast but may be found further inland too. While exceptions do exist, and can be found in other prefectures, they are the extreme minority.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Ehime Blue Road Line",
        mode: 'prefecture'
    },
    {
       id: 66,
       question: "These unique red roofs are most common in which two prefectures of the region they are found in?",
       answers: ['Hiroshima', 'Shimane'],
        images: [
            images['jp_chugoku_red_roof.png'],
        ], 
        full: "These unique red-tiled roofs are most commonly found in Chugoku, especially in the prefectures of Hiroshima and Shimane.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chugoku (Hiroshima/Shimane) Red Roof",
        mode: 'prefecture'
    },
    {
        id: 67,
        question: "In which regions will roofs be most commonly level or flat sloped instead of tiled to compensate for heavy snowfall?",
        answers: ['Tohoku', 'Hokkaido'],
        images: [
            images['jp_northern_roof.png'],
        ],
        full: "The further you are north, roofs generally transition from tiled to level or flat sloped in order to compensate for heavy snowfall. Northern houses often have ladders attached to the side, giving roof access to clear snow. NOTE: In addition to north to south, high-elevation mountainous regions or high snowfall areas often use such roofs, even in more central or southern prefectures.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Northern Level Roof",
        mode: 'region'
    },
    {
        id: 68,
        question: "In which region are these buildings of white concrete and flat roofs found?",
        answers: ['Okinawa'],
        images: [
            images['jp_okinawa_architecture.png'],
        ],
        full: "Okinawa can often be recognised by the white concrete buildings with flat roofs.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Okinawa White Concrete Architecture",
        mode: 'region'
    },
    {
        id: 69,
        question: "Cabbage is most commonly found in which region?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_cabbage.png'],
        ],
        full: "Cabbage is abundant in Hokkaido and some northern prefectures. Plants found south of Hokkaido often have smaller leaves. It is often found growing along the roadside.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Hokkaido Cabbage",
        mode: 'region'
    },
    {
        id: 70,
        question: "Green tea plants, which are often grown in hedged rows and typically have fans on poles to prevent frost from falling on the plants (as seen in the background here), are most common in which prefectures?",
        answers: ['Shizuoka', 'Kagoshima'],
        images: [
            images['jp_tea_hedge.png'],
        ],
        full: "Green tea plants are often grown in hedged rows. You will often see these fans mounted on poles, which serve the purpose of preventing frost from falling on the plants. The prefectures of Shizuoka (36%) and Kagoshima (34%) make up the majority of the production, with Mie (7%), Miyazaki (4%), and other prefectures (17%) making up the minority. Note: Shizuoka Prefecture is a bit southwest of Tokyo, and Kagoshima Prefecture is 'max southwest' of mainland Japan (for those who aren't well-versed in prefecture locations).",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Shizuoka/Kagoshima Tea Hedges",
        mode: 'prefecture'
    },
    {
       id: 71,
       question: "Generally, in which regions can rice production be most commonly found?",
       answers: ['Tohoku', 'Chubu', 'Kanto', 'Hokkaido'],
       images: [
            images['jp_rice.png'],
        ],
        full: "Rice is mainly produced north of Tokyo. During growth, it resembles straws of grass. And when ready for harvest, brown husks are visible. They are also commonly planted in beds of water. The regions of Chubu (21%), Kanto (15%), Tohoku (27%), and Hokkaido (7%) make up the majority of production.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Northern Rice Production",
        mode: 'region'
    },
    {
        id: 72,
        question: "Which regions does the Japanese White Birch dominantly grow in?",
        answers: ['Chubu', 'Hokkaido'],
        images: [
            images['jp_white_birch.png'],
        ],
        full: "Japanese White Birch grows dominantly in the Chubu and Hokkaido regions, and to a lesser extent in Tohoku. Small areas of white birch can be found throughout Japan, so use this information primarily to help assist your region guess. NOTE: Japanese Beech is another species of tree found in Japan. They, however, have more grey and spotted bark, as opposed to the mostly white Japanese White Birch.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Chubu/Hokkaido White Birch",
        mode: 'region'
    },
    {
        id: 73,
        question: "In which regions does bamboo grow prominently?",
        answers: ['Kyushu', 'Shikoku', 'Chugoku'],
        images: [
            images['jp_bamboo.png'],
        ],
        full: "In general, bamboo grows more often in the south, and slowly decreases further north. Population size and areas where it grows can vary, so use this information primarily in helping assist your region guess.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Southern Bamboo",
        mode: 'region'
    },
    {
        id: 74,
        question: "Chinese silver grass grows predominantly in which region?",
        answers: ['Kyushu'],
        images: [
            images['jp_kyushu_grass.png'],
        ],
        // img src (not in map): https://www.google.com/maps/@32.7461044,130.2473325,3a,25.2y,86.75h,91.07t/data=!3m6!1e1!3m4!1sI3rm0a8IyfK3vsfWmZvsOg!2e0!7i16384!8i8192?coh=205409&entry=ttu
        full: "Chinese Silver Grass, referred to as Kyushu grass, is indeed very abundant on the island of Kyushu. However, use this tip with caution, as they can be seen outside of this area. NOTE: Another notable region they can be found in is the prefectures of Niigata, Yamagata and Akita. Though, combining the rather large quantity found on Kyushu, its normally distinct vegetation, along with other information, there often will be a clear distinction.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Kyushu Chinese Silver Grass",
        mode: 'region'
    },
    {
        id: 75,
        question: "Sugarcane grows almost exclusively in which regions?",
        answers: ['Okinawa', 'Kyushu'],
        images: [
            images['jp_sugar_cane.png'],
        ],
        full: "Sugarcane is almost exclusively found on the islands in southern Kyushu and Okinawa.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Okinawa/Kyushu Sugarcane",
        mode: 'region'
    },
    {
        id: 76,
        question: "Which prefecture uses square colored stickers on the backs of its signs?",
        answers: ['Akita'],
        images: [
            images['jp_akita_sticker.png'],
        ],
        full: "In Akita, square colored stickers can be commonly found on the back of signs.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Akita Square Sticker",
        mode: 'prefecture'
    },
    {
        id: 77,
        question: "Which prefecture uses metallic stickers on sign posts, sometimes with a yellow/green band and/or writing on the pole?",
        answers: ['Aomori'],
        images: [
            images['jp_aomori_pole.png'],
        ],
        full: "Signposts in Aomori often have a metallic sticker and sometimes a yellow/green band and/or writing on the pole.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Aomori Metallic Sticker",
        mode: 'prefecture'
    },
    {
        id: 78,
        question: "In which prefecture are these small red circular stickers found on the backs of signs?",
        answers: ['Iwate'],
        images: [
            images['jp_iwate_sticker.png'],
        ],
        // img src: https://www.google.com/maps/@40.0187633,141.4583245,3a,15y,177.31h,99.89t/data=!3m6!1e1!3m4!1sVapWxhrLlyFN_WSYh3E9BQ!2e0!7i13312!8i6656?coh=205409&entry=ttu
        full: "In Iwate, small circular red sticker can be found on the backs of streetside signs. Be aware that it can get sun bleached!",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Iwate Red Sticker",
        mode: 'prefecture'
    },
    {
        id: 79,
        question: "This blue and white sticker (with blue at the top and bottom) is found in which prefecture?",
        answers: ['Miyagi'],
        images: [
            images['jp_miyagi_sticker.png'],
        ],
        full: "A blue/white sticker (with blue at the top and bottom) can be found on posts in Miyagi.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Miyagi Blue/White Sticker",
        mode: 'prefecture'
    },
    {
        id: 80,
        question: "In which prefecture can stripes be found at the top of sign posts?",
        answers: ['Miyagi'],
        images: [
            images['jp_miyagi_stripe.png'],
        ],
        full: "Stripes can be sometimes found on top of street sign poles. As far as I currently know, this is unique to Miyagi.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Miyagi Signpost Stripe",
        mode: 'prefecture'
    },
    {
        id: 81,
        question: "These tricolor pole bands are common in which prefecture?",
        answers: ['Yamagata'],
        images: [
            images['jp_yamagata_bands.png'],
        ],
        full: "Tri color pole bands e.g red/yellow/blue are common in Yamagata. Typically there are 3 but sometimes more.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Yamagata Tricolor Bands",
        mode: 'prefecture'
    },
    {
        id: 82,
        question: "Yellow stickers on the backs of signs specifically mounted to phone poles can be found in which prefecture?",
        answers: ['Yamagata'],
        images: [
            images['jp_yamagata_sticker.png'],
        ],
        full: "Yellow stickers on the backs of road signs specifically mounted to phone poles can be found in Yamagata.", 
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Yamagata Yellow Sticker on Phone Pole Signs",
        mode: 'prefecture'
    },
    {
        id: 83,
        question: "These white stickers with writing on signposts are found in which prefecture?",
        answers: ['Gunma'],
        images: [
            images['jp_gunma_sticker.png'],
        ],
        full: "White stickers with writing on signposts are commonly found in Gunma.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Gunma White Sticker",
        mode: 'prefecture'
    },
    {
        id: 84,
        question: "These black and white stickers on signposts are found in which prefecture?",
        answers: ['Kanagawa'],
        images: [
            images['jp_kanagawa_sticker.png'],
        ],
        full: "Black/white stickers are found on poles in Kanagawa. A similar, larger sticker can be found in Tokyo.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Kanagawa Black/White Sticker",
        mode: 'prefecture'
    },
    {
        id: 85,
        question: "Poles with small blue plates (which are often faded, as shown) can be found in which prefecture?",
        answers: ['Ibaraki'],
        images: [
            images['jp_ibaraki_tag.png'],
        ],
        full: "Small blue plates on poles which are often faded are common in Ibaraki Prefecture.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Ibaraki Blue Plate",
        mode: 'prefecture'
    },
    {
        id: 86,
        question: "These stickers on the backs of signs with a colored sign top are found in which prefecture?",
        answers: ['Tochigi'],
        images: [
            images['jp_tochigi_sticker.png'],
        ],
        full: "Stickers on the backs of signs with a colored sign top are found in Tochigi.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Tochigi Sticker",
        mode: 'prefecture'
    },
    {
        id: 87,
        question: "Striped signposts in this fashion are found in which prefecture?",
        answers: ['Aichi'],
        images: [
            images['jp_aichi_stripes.png'],
        ],
        full: "Horizontal stripes are very commonly seen here on signposts here.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Aichi Striped Signpost",
        mode: 'prefecture'
    },
    {
        id: 88,
        question: "In which prefecture are these double yellow stickers with a white sticker above found?",
        answers: ['Gifu'],
        images: [
            images['jp_gifu_sticker.png'],
        ],
        full: "Double yellow + white stickers are commonly found together in Gifu.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Gifu Double Yellow & White Sticker",
        mode: 'prefecture'
    },
    {
        id: 89,
        question: "These yellow inspection seals on the backs of signs or signposts are found in which prefecture?",
        answers: ['Shizuoka'],
        images: [
            images['jp_shizuoka_sticker.png'],
        ],
        full: "Yellow inspection seals on back of signs/on poles are common in Shizuoka.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Shizuoka Yellow Inspection Seal",
        mode: 'prefecture'
    },
    {
        id: 90,
        question: "These black and white stickers on signposts are found in which prefecture?",
        answers: ['Tokyo'],
        images: [
            images['jp_tokyo_sticker.png'],
        ],
        full: "Tokyo has these black/white stickers similar to those in neighboring Kanagawa. The ones in Kanagawa are smaller.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Tokyo Black/White Sticker",
        mode: 'prefecture'
    },
    {
        id: 91,
        question: "Which prefecture most commonly uses dark brown signposts, which typically have large white stickers with numbers as well as a small orange and gray sticker?",
        answers: ['Nagano'],
        images: [
            images['jp_nagano_signpost.png'],
        ],
        full: "This image shows three metas for Nagano. Signposts tend to be dark brown in Nagano. Large white stickers with numbers are quite common and distinct. Lastly a small orange and gray sticker can typically be found together on poles and are quite common.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Nagano Dark Brown Signpost & Stickers",
        mode: 'prefecture'
    },
    {
        id: 92,
        question: "These yellow/red thin snow poles are found frequently in which prefecture?",
        answers: ['Niigata'],   
        images: [
            images['jp_niigata_snow_pole.png'],
        ],
        full: "Yellow/red thin snow poles are quite common in Niigata.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Niigata Yellow/Red Snow Pole",
        mode: 'prefecture'
    },
    {
        id: 93,
        question: "These triple white pole stickers are found in which prefecture?",
        answers: ['Yamanashi'],
        images: [
            images['jp_yamanashi_stripes.png'],
        ],
        full: "In Yamanashi, poles with three white stickers are common.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Yamanashi Triple White Sticker",
        mode: 'prefecture'
    },
    {
        id: 94,
        question: "Poles in which prefecture are unpainted and gray and often have a yellow sticker?",
        answers: ['Hyogo'],
        images: [
            images['jp_hyogo_pole.png'],
        ],
        full: "Two Hyogo metas can be seen here. Unlike other prefectures Hyogo does not paint their sign poles, leaving them a gray/metallic color. Additionally these signs commonly have a yellow sticker.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Hyogo Gray Pole & Yellow Sticker",
        mode: 'prefecture'
    },
    {
        id: 95,
        question: "Most poles in which prefecture have these thick white bands on them?",
        answers: ['Hokkaido'],
        images: [
            images['jp_hokkaido_band.png'],
        ],
        full: "Thick white bands are seen on most poles but can be found elsewhere too.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Hokkaido Thick White Band",
        mode: 'prefecture'
    },
    {
        id: 96,
        question: "Blue stickers with white text on poles can be found in which prefecture?",
        answers: ['Osaka'],
        images: [
            images['jp_osaka_sticker.png'],
        ],
        full: "Osaka street sign poles often have a blue sticker with white text.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Osaka Blue Sticker",
        mode: 'prefecture'
    },
    {
        id: 97,
        question: "Red/white chevron stickers are common on poles in which prefecture?",
        answers: ['Nara'],
        images: [
            images['jp_nara_chevron.png'],
        ],
        full: "Red/white chevron stickers are very common on poles in Nara.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Nara Red/White Chevron",
        mode: 'prefecture'
    },
    {
        id: 98,
        question: "Yellow stickers with a black bar on top are found in which prefecture?",
        answers: ['Okayama'],
        images: [
            images['jp_okayama_sticker.png'],
        ],
        full: "Okayama yellow pole sticker, note the black bar on top. The numbers can be used to region guess, refer to the source for more information.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Okayama Yellow Sticker",
        mode: 'prefecture'
    },
    {
        id: 99,
        question: "Two yellow stickers (with the one on the top having numbers and either being fully yellow or having a blue top) is indicative of which prefecture?",
        answers: ['Tottori'],
        images: [
            images['jp_tottori_sticker.png'],
        ],
        full: "Two yellow stickers, with the top one having numbers and either being fully yellow or having a blue top, can be found on signposts in Tottori. The top sticker can be used to regionguess Tottori, refer to the source for more information.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Tottori Yellow Sticker",
        mode: 'prefecture'
    },
    {
        id: 100,
        question: "Yellow-red-yellow stickers are generally unique to which region?",
        answers: ['Shikoku'],
        images: [
            images['jp_shikoku_sticker.png'],
        ],
        full: "Yellow/red/yellow stickers on poles are mostly unique to Shikoku. (very rare elsewhere).",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Shikoku Yellow/Red/Yellow Sticker",
        mode: 'region'
    },
    {
        id: 101,
        question: "Poles with a blue and yellow sticker (occasionally it will just be blue) are found in which prefecture?",
        answers: ['Fukuoka'],
        images: [
            images['jp_fukuoka_sticker.png'],
        ],
        full: "Poles with a blue and yellow sticker (sometimes only blue) are found in Fukuoka.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Fukuoka Blue/Yellow Sticker",
        mode: 'prefecture'
    },
    {
        id: 102,
        question: "The tomare in which prefecture will have a square loop on the second character (ま)?",
        answers: ['Kagoshima'],
        images: [
            images['jp_kagoshima_tomare.png'],
        ],
        full: "Tomare road font is square here, note the ま has a square loop. This is unique to Kagoshima.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Kagoshima Tomare",
        mode: 'prefecture'
    },
    {
        id: 103,
        question: "Red stickers on poles are abundant in which prefecture?",
        answers: ['Kumamoto'],
        images: [
            images['jp_kumamoto_sticker.png'],
        ],
        full: "Red stickers are found on most poles. While they can be occasionally found elsewhere, the insane amount of them in Kumamoto confirms you’re here.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Kumamoto Red Sticker Spam",
        mode: 'prefecture'
    },
    {
        id: 104,
        question: "The tomare in which prefecture has a straight leg on the third character (れ)? (Note how the leg almost points down instead of up)",
        answers: ['Nagasaki'],
        images: [
            images['jp_nagasaki_tomare.png'],
        ],
        full: "The れ (re) in Nagasaki has a straight leg. It is unique.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Nagasaki Tomare",
        mode: 'prefecture'
    },
    {
        id: 105,
        question: "These gray stickers are commonly found on poles in which prefecture?",
        answers: ['Nagasaki'],
        images: [
            images['jp_nagasaki_sticker.png'],
        ],
        full: "Gray stickers are relatively common on poles in Nagasaki, usually with one or two being present.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Nagasaki Gray Sticker",
        mode: 'prefecture'
    },
    {
        id: 106,
        question: "Small colorful square stickers on the backs of signs are common in which prefecture?",
        answers: ['Nagasaki'],
        images: [
            images['jp_nagasaki_back_sticker.png'],
        ],
        full: "In Nagasaki, backs of signs sometimes have this small colorful square. Can be green, blue, white etc.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Nagasaki Back Square Sticker",
        mode: 'prefecture'
    },
    {
        id: 107,
        question: "Double orange stickers on poles are common in which prefecture?",
        answers: ['Oita'],
        images: [
            images['jp_oita_sticker.png'],
        ],
        full: "It is relatively common to see double orange stripes in Oita.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Oita Double Orange Sticker",
        mode: 'prefecture'
    },
    {
        id: 108,
        question: "These stripes are most commonly found on poles at major intersections in which prefecture?",
        answers: ['Saga'],
        images: [
            images['jp_saga_stripes.png'],
        ],
        full: "'Saga stripes' can be found commonly on poles in major intersections.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Saga Stripes",
        mode: 'prefecture'
    },
    {
        id: 109,
        question: "This tomare with a raised arm in the first character (止) is unique to which prefecture? (Note how the arm on the right is slanted slightly upwards)",
        answers: ['Niigata'],
        images: [
            images['jp_niigata_tomare.png'],
        ],
        full: "Niigata uses a unique tomare with a raised arm in 止.",
        source: "No Nonsense Guide to Japan", 
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Niigata Raised Arm Tomare",
        mode: 'prefecture'
    },
    {
        id: 110,
        question: "This tomare with a unique font (most noticeably in the third character, れ, where the left part is much smaller than normal), and with the first two characters aligning vertically is found in which prefecture?",
        answers: ['Fukushima'],
        images: [
            images['jp_fukushima_tomare.png'],
        ],
        full: "Fukushima uses a unique tomare which is very similar to Aichi. Remember it by how the vertical line in the kanji lines up with the one in ま, this is how it is distinctly different than Aichi.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Fukushima Tomare Font & Vertical Alignment",
        mode: 'prefecture'
    },
    {
        id: 111,
        question: "This tomare with a unique font (most noticeably in the third character, れ, where the left part is much smaller than normal), and with the first two characters NOT aligning vertically is found in which prefecture?",
        answers: ['Aichi'],
        images: [
            images['jp_aichi_tomare.png'],
        ],
        full: "Aichi uses a unique tomare font which is very similar to Fukushima. Notice how the vertical lines in the first two kanji do not align, whereas in Fukushima they do.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Aichi Tomare Font & No Vertical Alignment",
        mode: 'prefecture'
    },
    {
        id: 112,
        question: "This tomare where the third character (れ) connects to its leg at a point is unique to which prefecture?",
        answers: ['Gifu'],
        images: [
            images['jp_gifu_tomare.png'],
        ],
        full: "Gifu uses a unique tomare font. Note how the れ connects to its leg at a point.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Gifu Tomare Connected Leg",
        mode: 'prefecture'
    },
    {
        id: 113,
        question: "This tomare with the detached straight lines in the second and third characters (まれ) is unique to which prefecture?",
        answers: ['Shiga'],
        images: [
            images['jp_shiga_tomare.png'],
        ],
        full: "The tomare sign here is very unique. Note how the straight line in ま and れ is detached. This specific design is only found in Shiga.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Shiga Detached Tomare Font",
        mode: 'prefecture'
    },
    {
        id: 114,
        question: "This tomare with the short leg on the third character (れ) is found in which prefectures (all of which are in the same region)?",
        answers: ['Kumamoto', 'Miyazaki', 'Oita', 'Saga'],
        images: [
            images['jp_kyushu_tomare.png'],
        ],
        full: "Here we can see the 止まれ (tomare) font has a れ with a  short leg. 4 out of 7 prefectures in Kyushu (Kumamoto, Miyazaki, Oita, and Saga) have this font. It is not found outside of the island. This makes it easy to associate this with the island.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Kyushu Short Leg Tomare Font",
        mode: 'prefecture'
    },
    {
        id: 115,
        question: "This manhole cover is found in the biggest city of which prefecture?",
        answers: ['Hokkaido'],
        images: [
            images['jp_sapporo_manhole.png'],
        ],
        full: "Sapporo, Hokkaido uses this manhole cover of salmon swimming around Sapporo Clock Tower.",
        source: "A Japan Guide by Fanty",
        sourceURL: "https://docs.google.com/document/d/1Pm4qV16U5Yp4xMP4PnR_AUY4Td1aB0kPoCEzNRN2ESU/",
        short: "Sapporo Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 116,
        question: "This manhole cover is found in the biggest city of which prefecture?",
        answers: ['Osaka'],
        images: [
            images['jp_osaka_manhole.png'],
        ],
        full: "Osaka uses this manhole cover where Osaka Castle is depicted during cherry blossom season.",
        source: "A Japan Guide by Fanty",
        sourceURL: "https://docs.google.com/document/d/1Pm4qV16U5Yp4xMP4PnR_AUY4Td1aB0kPoCEzNRN2ESU/",
        short: "Osaka Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 117,
        question: "This manhole cover is found in the biggest city of which prefecture?",
        answers: ['Nara'],
        images: [
            images['jp_nara_manhole.png'],
        ],
        full: "Nara is famous for its deer, which are pictured in its manhole cover.",
        source: "A Japan Guide by Fanty",
        sourceURL: "https://docs.google.com/document/d/1Pm4qV16U5Yp4xMP4PnR_AUY4Td1aB0kPoCEzNRN2ESU/",
        short: "Nara Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 118,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Nagano'],
        images: [
            images['jp_matsumoto_manhole.png'],
        ],
        full: "Matsumoto, Nagano’s manhole cover depicts Temari balls, a traditional craft of the region.",
        source: "A Japan Guide by Fanty",
        sourceURL: "https://docs.google.com/document/d/1Pm4qV16U5Yp4xMP4PnR_AUY4Td1aB0kPoCEzNRN2ESU/",
        short: "Matsumoto Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 119,
        question: "This manhole cover is found in the biggest city in which prefecture?",
        answers: ['Aichi'],
        images: [
            images['jp_nagoya_manhole.png'],
        ],
        full: "Nagoya, Aichi’s manhole cover depicts a water strider. (shown upside down here)",
        source: "A Japan Guide by Fanty",
        sourceURL: "https://docs.google.com/document/d/1Pm4qV16U5Yp4xMP4PnR_AUY4Td1aB0kPoCEzNRN2ESU/",
        short: "Nagoya Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 120,
        question: "Sign backs in which prefecture sometimes uses this 'V' sticker?",
        answers: ['Gunma'],
        images: [
            images['jp_gunma_v_sticker.png'],
        ],
        full: "Gunma uses this 'V' sticker on the backs of signs.",
        source: "No Nonsense Guide to Japan",
        sourceURL: "https://docs.google.com/document/d/1Ob404T3HatGRWOG0L15nfFXuuV0FmQpEsSF80J3e0Tg/",
        short: "Gunma V Sticker",
        mode: 'prefecture'
    },
    {
        id: 121,
        question: "This manhole cover is found in the biggest city of which prefecture?",
        answers: ['Tokyo'],
        images: [
            images['jp_tokyo_manhole.png'],
        ],
        full: "Tokyo City features a nice manhole design, with a 5 petal flower and many small circles on the outside.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Tokyo Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 122,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Kanagawa'],
        images: [
            images['jp_yokohama_manhole.png'],
        ],
        full: "Yokohama, Kanagawa's manhole cover depicts a bridge with the words 'Yokohama' (intentionally blurry here).",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Yokohama Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 123,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Fukuoka'],
        images: [
            images['jp_fukuoka_manhole.png'],
        ],
        full: "This manhole cover is unique to Fukuoka.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Fukuoka Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 124,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Kumamoto'],
        images: [
            images['jp_kumamoto_manhole.png'],
        ],
        full: "This manhole cover depicting flowers is unique to Kumamoto.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Kumamoto Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 125,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Niigata'],
        images: [
            images['jp_niigata_manhole.png'],
        ],
        full: "This manhole cover depicting a sunflower and tulips is unique to Niigata.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Niigata Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 126,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Osaka'],
        images: [
            images['jp_sakai_manhole.png'],
        ],
        full: "This manhole cover is unique to Sakai, Osaka.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Sakai Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 127,
        question: "Thick white bollards with an octagonal reflector and two yellow stripes underneath the reflector are found in which prefecture?",
        answers: ['Akita'],
        images: [
            images['jp_akita_bollard.png'],
        ],
        full: "This bollard, much like Tohoku, will be a thick, white bollard with an octagonal reflector. However, this Akita prefecture variation will have two yellow stripes underneath the reflector.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.com/japan",
        short: "Akita Two Striped Bollard",
        mode: 'prefecture'
    },
    {
        id: 128,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Miyagi'],
        images: [
            images['jp_sendai_manhole.png'],
        ],
        full: "This manhole cover is unique to Sendai, Miyagi.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Sendai Manhole Cover",
        mode: 'prefecture'
    },
    {
        id: 129,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Fukuoka'],
        images: [
            images['jp_kitakyushu_manhole.png'],
        ],
        full: "This manhole cover is unique to Kitakyushu, Fukuoka.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Kitakyushu Manhole Cover",
    },
    {
        id: 130,
        question: "This manhole cover is found in a city in which prefecture?",
        answers: ['Okayama'],
        images: [
            images['jp_okayama_manhole.png'],
        ],
        full: "This manhole cover depicting the legend of <a target='_blank' href='https://muza-chan.net/japan/index.php/blog/okayama-artistic-manhole-cover-momotaro-legend'>Momotaro</a> is unique to Okayama.",
        source: "Manhole Covers of Japan",
        sourceURL: "https://docs.google.com/document/d/1Xc_KTqYffNvo4cIFfPghK3SdGtfQRxENq1NOfSAxtqk/",
        short: "Okayama Manhole Cover",
        mode: 'prefecture'
    },

]       

export const jpSources = ['Plonk It Guide', 'No Nonsense Guide to Japan', 'A Japan Guide by Fanty', 'Manhole Covers of Japan']