import allImages from '../../images';
const images = allImages['ca'];

export const caTips = [
    {
        id: 1,
        question: "In which province is this bollard found?",
        answers: ["Alberta"],
        images: [
            images['ca_alberta_bollard.png']
        ],
        full: "Alberta has a unique black and white bollard that is often found at intersections. It has a cylindrical shape and two black bands that wrap all the way around. It is one of the most useful Canadian bollards to learn because it appears so frequently.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Alberta Bollard"
    },
    {
        id: 2,
        question: "In which provinces is this bollard found?",
        answers: ["Manitoba", "Saskatchewan"],
        images: [
            images['ca_manitoba_sask_bollard.png']
        ],
        full: "Bollards in this format with just a black top are seen in Manitoba and Saskatchewan.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Manitoba/Saskatchewan Bollard"
    },
    {
        id: 3,
        question: "In which province is this bollard found?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_bollard.png']
        ],
        full: "Manitoba uses black and grey painted bollards. Most of the time an orange stripe will be present below the grey one.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Manitoba Bollard"
    },
    {
        id: 4,
        question: "In which province is this bollard found?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_bollard.png'],
        ],
        full: "British Columbia has black-and-white bollards with a thin, slightly curved shape. ",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "British Columbia Bollard"
    },
    {
        id: 5,
        question: "In which province is this bollard found?",
        answers: ['Quebec',],
        images: [
            images['ca_quebec_bollard.png']
        ],
        full: "Québec has unique bollards that are always attached to guardrails. They are long, thin white poles with a red or green reflector at the top.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Bollard"
    },
    {
        id: 6,
        question: "In which province is this rectangular marker (with varying colors) found?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_marker.png'],
        ],
        full: "You can find these rectangular markers on the sides of the roads in Québec. The colours can vary.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Rectangular Marker"
    },
    {
        id: 7,
        question: "In which province is this diamond shaped marker (with varying colors) found?",
        answers: ['Ontario',],
        images: [
            images['ca_ontario_marker.png'],
        ],
        full: "You can find these diamond-shaped markers on the sides of the roads in Ontario. The colours can vary.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Ontario Diamond Marker"

    },
    {
        id: 8,
        question: "In which province is this style of fence found?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_fences.png'],
        ],
        full: "In Ontario, you will often see these types of guardrails. They consist of cylindrical wooden posts, held together by precisely three metal wires. Some posts are marked by a reflective white band.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Ontario Fence"

    },
    {
        id: 9,
        question: "The Trans-Canada Highway in which province uses this bollard?",
        answers: ['New Brunswick'],
        images: [
            images['ca_nb_bollard.png'],
        ],
        full: "These flat, yellow bollards can be found on the Trans-Canada highway in New Brunswick.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "New Brunswick Bollard"
    },
    {
        id: 10,
        question: "In which provinces will the transformer be parallel to the road rather than perpendicular?",
        answers: ['British Columbia', 'New Brunswick'],
        images: [
            images['ca_parallel_transformer.png'],
        ],
        full: "The transformers on poles will face the road in British Columbia and New Brunswick, while in other provinces, it will be parallel with the wires. Notice how in the image, this transformer would point toward the road.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "BC/NB Parallel Transformer"
    },
    {
        id: 11,
        question: "In which province is this pole top found?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_pole_top.png'],
        ],
        full: "These distinctive pole tops are most commonly found in Ontario. They have two insulators to the side, and one on top. The side insulators are affixed to the pole with a distinct attachment. Sometimes, multiple of them can be stacked on top of each other. NOTE: They can occasionally appear in British Columbia, Nova Scotia, or other provinces more rarely.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Ontario Pole Top"
    },
    {
        id: 12,
        question: "In which province is this pole top found?",
        answers: ['Quebec',],
        images: [
            images['ca_quebec_pole_top.png'],
        ],
        full: "Some Québec poles have recognizable pole tops with insulator rods that are angled slightly upwards.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Pole Top"
    },
    {
        id: 13,
        question: "In which province is this pole label found?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_pole_label.png'],
        ],
        full: "Québec poles may sometimes have a vertical black and yellow label. There is a small ‘Q’ in the black part.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Pole Label"
    },
    {
        id: 14,
        question: "In which province do pole top insulators alternate?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_pole_tops.png'],
        ],
        full: "Pole top insulators in Saskatchewan alternate. While one pole will have one insulator on the left and two on the right, the next will have two on the left and one on the right.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Saskatchewan Alternating Pole Tops"
    },
    {
        id: 15,
        question: "In which province do the intersection signs not feature the checkerboard pattern?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_intersection_sign.png'],
        ],
        full: "Québec T-Intersection signs do not feature the chequerboard pattern.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec No Checkerboard Intersection Sign"
    },
    {
        id: 16,
        question: "In which province do intersection signs feature a rectangular border?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_newfoundland_intersection_border.png'],
        ],
        full: "Newfoundland T-Intersection signs feature a rectangular yellow border around the diamond shape.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Newfoundland Intersection Sign Rectangular Border"
    },
    {
        id: 17,
        question: "Which province sometimes has green backs of signs?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_green_backs.png'],
        ],
        full: "Road signs in British Columbia can have green backsides.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "British Columbia Green Sign Backs"

    },
    {
        id: 18,
        question: "In which province is this style of signpost found?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_signposts.png'],
        ],
        full: "Signs in British Columbia are sometimes hung down from these unique signposts.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "British Columbia Signpost"
    },
    {
        id: 19,
        question: "Which province uses concrete barriers instead of guardrails?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_barriers.png'],
        ],
        full: "British Columbia predominantly uses concrete barriers instead of guardrails. Note that the presebce of concrete barriers does not necessarily mean you are in British Columbia, as they can be found in other provinces.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "British Columbia Concrete Barriers",
    },
    {
        id: 20,
        question: "Which province sometimes has green and white stickers on signposts?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_sticker.png'],
        ],
        full: "You can sometimes find a green and white striped sticker on signposts in Manitoba.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Manitoba Pole Stickers"
    },
    {
        id: 21,
        question: "Which province sometimes has white triangles on signposts?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_signpost_triangles.png'],
        ],
        full: "Signposts in Saskatchewan can feature these white triangles. The number can vary.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Saskatchewan Signpost Triangles"
    },
    {
        id: 22,
        question: "Which province uses white dashes between yellow center road lines?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_roadlines.png'],
        ],
        full: "You can sometimes find white dashes between the yellow centre lines in Saskatchewan.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Saskatchewan Roadlines"

    },
    {
        id: 23,
        question: "Which province uses this stop sign with the word 'Arrêt'?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_stop_sign.png'],
        ],
        full: "Stop signs with the word ‘Arrêt’ are unique to Québec.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Arret Stop Sign"
    },
    {
        id: 24,
        question: "Which province uses stop signs with both the words 'Stop' and 'Arrêt'?",
        answers: ['New Brunswick'],
        images: [
            images['ca_nb_stop_sign.png'],
        ],
        full: "Stop signs with both ‘Stop’ and ‘Arrêt’ are most commonly found in New Brunswick. NOTE: Such stop signs can rarely be found in other provinces.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "New Brunswick Stop + Arrêt Stop Sign"
    },
    {
        id: 25,
        question: "Which province sequentially names its rural roads?",
        answers: ['Alberta'],
        images: [
            images['ca_alberta_road_names.png'],
        ],
        full: "Rural roads in Alberta are numbered sequentially. This makes it easy to figure out your exact location using just a road sign. Township roads (Twp Rd) run east to west. Their numbers increase the more north you go. Range roads (Rge Rd) run north to south. Their numbers increase the more west you go. These numbers reset a couple of times.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Alberta Rural Road Name System"

    },
    {
        id: 26,
        question: "Which province sometimes uses these guardrails with small poles connected by three or four metal wires on major highways?",
        answers: ['Alberta'],
        images: [
            images['ca_alberta_guardrails.png'],
        ],
        full: "This guardrail, with small poles connected by three or four metal wires, can rarely be found on major highways in Alberta.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Alberta Guardrails"
    },
    {
        id: 27,
        question: "Which province uses pyramid shaped tops on guardrail posts?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_newfoundland_pyramid_guardrail_top.png'],
        ],
        full: "In Newfoundland and Labrador, most wooden posts on guardrails have this pyramid shaped top. Occasionally, you can spot them on standalone posts and signposts as well.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Newfoundland Pyramid Guardrail Top"
    },
    {
        id: 28,
        question: "Which province commonly uses diamond shaped markers next to fire hydrants?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_newfoundland_hydrant_sign.png'],
        ],
        full: "Fire hydrants in Newfoundland often have a diamond-shaped marker next to them.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Newfoundland Hydrant Marker"
    },
    {
        id: 29,
        question: "In which province can this traffic cone be found?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_traffic_cone.png'],
        ],
        full: "Québec has unique orange and white striped traffic cones.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Traffic Cone"
    },
    {
        id: 30,
        question: "In which province can this traffic cone be found?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_traffic_cone.png'],
        ],
        full: "Ontario uses distinct orange and black striped traffic cones.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Ontario Traffic Cone"
    },
    {
        id: 31,
        question: "Which province uses this red and white chevron (rather than the traditional yellow and black)?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_chevron.png'],
        ],
        full: "Québec is the only Canadian province that uses red and white chevrons. NOTE: The rest of Canada uses the classic yellow and black chevrons.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Red and White Chevron"

    },
    {
        id: 32,
        question: "Which province typically uses this speed limit sign with 'km/h' written at the bottom?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_speed_limit_sign.png'],
        ],
        full: "Speed limit signs in British Columbia uniquely have ‘km/h’ written at the bottom.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "British Columbia Speed Limit Sign"

    },
    {
        id: 33,
        question: "Which province typically uses this speed limit sign with black strip(s) at the bottom?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_speed_limit_sign.png'],
        ],
        full: "Ontario often uses a black section on the bottom of their speed limit signs, usually saying 'km/h' and/or 'begins'.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Ontario Speed Limit Sign"
    },
    {
        id: 34,
        question: "Which province sometimes uses this speed limit sign with the French word 'Secteur'?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_speed_limit_sign.png'],
        ],
        full: "Québec speed limit signs sometimes have the French word ‘Secteur’ written on them.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Quebec Speed Limit Sign"
    },
    {
        id: 35,
        question: "Aspen trees are commonly found in forested regions of which province?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_aspen.png'],
        ],
        full: "Aspen trees are common in the forested parts of Manitoba. They are recognizable by their white, birch-like trunks. In most seasons, they have regular green leaves, but in fall coverage, they turn a characteristic bright yellow colour.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Manitoba Aspen Trees"
    },
    {
        id: 36,
        question: "In which province is there a distinctive red tint to the soil, as well as a common presence of potato farms?",
        answers: ['Prince Edward Island'],
        images: [
            images['ca_pei_red_soil.png'],
        ],
        full: "Prince Edward Island soil has a distinct red tint to it. Potato farming is also very common on PEI. NOTE: This type of soil can occasionally be seen in certain areas of New Brunswick and Nova Scotia.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Prince Edward Island Red Soil & Potato Farms"
    },
    {
        id: 37,
        question: "In which province is the balsim fir most common?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_newfoundland_balsam_fir.png'],
        ],
        full: "The Balsam Fir is a very common species of tree found in Newfoundland. It has a Christmas tree-like appearance. NOTE: While most commonly found in Newfoundland, the Balsam Fir can also grow in other parts of Eastern Canada and the US.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Newfoundland Balsam Fir"
    },
    {
        id: 38,
        question: "In which province are these wooden bins (usually octagonal) most commonly found?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_newfoundland_wooden_bin.png'],
        ],
        full: "Bins made of wood planks can be seen on Newfoundland driveways. These are most commonly octagonal, however they can have other shapes. NOTE: Although they are most common in Newfoundland, they can also sometimes be seen in other provinces, such as New Brunswick.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Newfoundland Wooden Bin"
    },
    {
        id: 39,
        question: "The north and east of which province is considered to constitute the Acadia French-speaking cultural region?",
        answers: ['New Brunswick'],
        images: [
            images['ca_acadia.png'],
        ],
        full: "Acadia is a French-speaking cultural region in the Canadian Maritimes. It does not have clearly defined borders, but is usually understood to at least include north and east New Brunswick. It has its own flag, which is very commonly seen in this region. The flag is a French-style tricolour with a yellow star in the blue band.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Acadia Region & Flag"

    },
    {
        id: 40,
        question: "Which province uses the area code 705?",
        answers: ['Ontario'],
        images: [
            images['ca_west_ont_code.png'],

        ],
        full: "Western Ontario uses the area code 705.",
        source: "Comprehensive Canada - Area Codes",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Western Ontario Area Code 705"
    },
    // {
    //     id: 40,
    //     question: "Which province typically uses rectangular signposts with small holes?",
    //     answers: ['Ontario'],
    //     images: [
    //         images['ca_ontario_holed_signposts.png'],
    //     ],
    //     full: "In Ontario, rectangular poles with small holes are usually used.",
    //     source: "Comprehensive Canada",
    //     sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
    //     short: "Ontario Holed Signpost"
    // },
    {
        id: 41,
        question: "Which province generally uses yellow fire hydrant bodies?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_hydrant.png'],
        ],
        full: "The body of Ontario fire hydrants is usually yellow. Note that this has some major exceptions, such as Hamilton, Brampton, and Niagara Falls, among others. Still, this is probably about 70-80% reliable.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Ontario Yellow Hydrant"
    },
    {
        id: 42,
        question: "The southern part of which province uses this sign style?",
        answers: ['Ontario'],
        images: [
            images['ca_south_ontario_sign.png'],
        ],
        full: "This sign style is unique to Southern Ontario. The county/township name can usually be found on the bottom. ",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Southern Ontario Street Name Sign"
    },
    {
        id: 43,
        question: "Which province uses this traffic light with the red light being significantly bigger?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_traffic_light.png'],
        ],
        full: "Traffic lights with a larger red light are unique to Ontario.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Ontario Traffic Light"
    },
    {
        id: 44,
        question: "Which province most commonly uses green house numbers, as opposed to blue?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_house_number.png'],
        ],
        full: "House numbers are mostly found in Ontario and Quebec, and they will typically be green in Ontario and blue in Quebec (with minor exceptions).",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Ontario Green House Number"
    },
    {
        id: 45,
        question: "In which province is this design of silo particularly common?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_silo.png'],

        ],
        full: "This style of silo is common in Ontario.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Ontario Silo"
    },
    {
        id: 46,
        question: "Which province uses horizontal traffic lights?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_traffic_light.png'],
        ],
        full: "Quebec uses horizontal lights. Black outline colour is the most common.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Quebec Horizontal Traffic Light"
    },
    {
        id: 47,
        question: "Which provinces use this pole top with the cross beam being near the top as opposed to further down?",
        answers: ['Quebec', 'New Brunswick'],
        images: [
            images['ca_quebec_pole.png'],
        ],
        full: "This pole top is most common in Quebec and New Brunswick. The cross beam's height makes it stand out from other poles. For example, QC/NB is more of a 'T' and Nova Scotia's pole is more of a 't', regarding height.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Quebec/New Brunswick Pole Top"
    },
    {
        id: 48,
        question: "Generally, which province tends to use double signposts more than other provinces?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_double_signpost.png'],
        ],
        full: "Quebec likes to use 2 poles to hold up signs. Note: This should be taken as a trend and not a rule, and of course, examples exist in other provinces.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Quebec Double Signpost"
    },
    {
        id: 49,
        question: "The southwest of which province sometimes has this cross on the back of its signs?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_cross.png'],
        ],
        full: "The cross on the back of signs is generally found in southwest Quebec.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Southwest Quebec Cross",

    },
    {
        id: 50,
        question: "Which province sometimes uses this horizontal bar on the backs of its signs?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_horizontal.png'],
        ],
        full: "The horizontal bar on the back of signs is most common in Quebec.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Quebec Sign Back Horizontal Bar"
    },
    {
        id: 51,
        question: "Which province is the only one to use exclusively red body fire hydrants?",
        answers: ['Quebec'],
        images: [
            images['ca_quebec_hydrant.png'],
        ],
        full: "Quebec uses exclusively red fire hydrants.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Quebec Red Hydrant"
    },
    {
        id: 52,
        question: "Which province is this pole sticker found in?",
        answers: ['Ontario'],
        images: [
            images['ca_ontario_pole_sticker.png'],
        ],
        full: "This yellow sticker (can be vertical or horizontal) is the standard one frequently used on Ontario poles. Exceptions in certain cities exist.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Ontario Pole Sticker"
    },
    {
        id: 53,
        question: "Which provinces use asymmetrical insulators most commonly?",
        answers: ['British Columbia', 'Saskatchewan'],
        images: [
            images['ca_bc_asym_insulators.png'],
        ],
        full: "Poles with asymmetrical insulators are most commonly found in Saskatchewan and British Columbia. In Saskatchewan, the poles will typically alternate between having one insulator on the left and two on the right, and vice versa. In British Columbia, this alternation will not occur. Note: Manitoba can have asymmetrical insulators, but the crossbeam will be shorter than Saskatchewan's.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "BC/SK Asymmetrical Insulators"
    },
    {
        id: 54,
        question: "Which province uses tilted insulators most frequently?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_tilted_insulators.png'],
        ],
        full: "BC often has tilted insulators.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "British Columbia Tilted Insulators"
    },
    {
        id: 55,
        question: "Which province uses this gray or yellow small rectangular pole sticker?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_pole_sticker.png'],
        ],
        full: "These metal rectangles are found on BC poles. They can be gray or yellow.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "British Columbia Pole Sticker"

    },
    {
        id: 56,
        question: "Which province often has 1 to 5 small metal circles at the bottom of its poles?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_metal_circles.png'],
        ],
        full: "BC often has these small metal circles at the bottom of the pole. There can be anywhere from 1 to 5 circles.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "British Columbia Metal Circles"
    },
    {
        id: 57,
        question: "Which provinces have a notable tinge of red on their plates?",
        answers: ['Alberta', 'New Brunswick'],
        images: [
            images['ca_alberta_plate.png'],
            images['ca_nb_plate.png']
        ],
        full: "Alberta plates are rear-only and have red lettering. New Brunswick also uses red lettering, but there will be a white space in the middle. Additionally, NB used front plates until 2019, so some cars may use them.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Alberta & NB Plate - Red Lettering"

    },
    {
        id: 58,
        question: "Which province commonly has a colored registration sticker at the bottom middle of their license plates?",
        answers: ['British Columbia'],
        images: [
            images['ca_bc_plate_sticker.png'],
        ],
        full: "Plates in British Columbia often have a very visible registration sticker in the bottom middle. This can help differentiate it from Ontario's.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "British Columbia Plate Sticker"
    },
    {
        id: 59,
        question: "This pole can be found in the north of what province?",
        answers: ['Alberta'],
        images: [
            images['ca_alberta_pole.png'],
        ],
        full: "This very distinct pole is sometimes found in Northern Alberta.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Northern Alberta Pole"
    },
    {
        id: 60,
        question: "Which province can this pole be found in?",
        answers: ['Alberta'],
        images: [
            images['ca_alberta_pole_missing_insulator.png'],
        ],
        full: "You can sometimes find this pole that looks similar to a Manitoba pole in Alberta. However, in Alberta it will always be missing an insulator on the bottom.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Alberta Pole With Missing Insulator"
    },
    {
        id: 61,
        question: "In which provinces is canola most commonly found?",
        answers: ['Alberta', 'Saskatchewan'],
        images: [
            images['ca_canola.png'],
        ],
        full: "Canola is grown in the central southern prairies, and it can be found almost nowhere else in Canada. It is visually similar to a field of bright yellow flowers. NOTE: Canola is also very common in the US state of North Dakota. ",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/canada",
        short: "Alberta/Saskatchewan Canola"

    },
    {
        id: 62,
        question: "In which province is a rumble strip on the outer lines particularly common?",
        answers: ['Alberta'],
        images: [
            images['ca_alberta_rumble_outer.png'],
        ],
        full: "Outside rumble strips are common in Alberta but found in other provinces. Additionally, Alberta is the only prairie province to use rumble strips on its center lines.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Alberta Rumble Strip Outer Line"
    },
    {
        id: 63,
        question: "Which province has a notable green tinge to its plates?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_plate.png'],
        ],
        full: "Saskatchewan plates are rear-only and have green lettering.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Saskatchewan Plate - Green Lettering"
    },
    {
        id: 64,
        question: "Which province often uses only a small metal strip on its poles?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_pole_sticker.png'],
        ],
        full: "Rural Sask pole stickers are random, but it's most common to see nothing but a small metal strip.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Saskatchewan Pole Sticker"
    },
    {
        id: 65,
        question: "Which province most commonly uses white signposts?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_white_signpost.png'],
        ],
        full: "White signposts are particularly common in Saskatchewan.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Saskatchewan White Signpost"
    },
    {
        id: 66,
        question: "Which province most frequently uses green lampposts on isolated lamps?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_green_lamppost.png'],
        ],
        full: "Saskatchewan often uses green lampposts on isolated lamps. Note that green lampposts can be less commonly found elsewhere, such as Manitoba.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Saskatchewan Green Lamppost"
    },
    {
        id: 67,
        question: "Which province most frequently uses this style of lamp with the support and curve?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_lamp.png'],
        ],
        full: "The lamps in Sask often have this support, and curve.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Saskatchewan Lamp"
    },
    {
        id: 68,
        question: "Which province uses this license plate with distinct green on the left and right?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_plate.png'],
        ],
        full: "Manitoba uses front and rear plates, with distinct green triangles on the left and right edges.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Manitoba Plate - Green on Sides"
    },
    {
        id: 69,
        question: "Which province often has gravel on the sides of its roads, which can sometimes replace outer lines?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_gravel.png'],
        ],
        full: "Manitoba often has gravel on the sides of its roads. It is common to find gravel ditches instead of white outer lines.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Manitoba Gravel"
    },
    {
        id: 70,
        question: "This pole is essentially unique to which province?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_pole.png'],
        ],
        full: "This pole is essentially unique to Manitoba.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Manitoba Pole"
    },
    {
        id: 71,
        question: "Which province most commonly uses abnormally long, supported lamps?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_lamp.png'],
        ],
        full: "If you find an excessively long, supported street lamp, it is probably Manitoba.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Manitoba Long Lamp"
    },
    {
        id: 72,
        question: "Which province often has short road names like 'Road 12W'?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_road_names.png'],
        ],
        full: "Manitoba often uses short road names like Road 12W or Road 107N. Additionally, most rural signs will be green.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Manitoba Short Road Names"
    },
    {
        id: 73,
        question: "Which province uses this license plate with blue in the middle?",
        answers: ['Nova Scotia'],
        images: [
            images['ca_ns_plate.png'],
        ],
        full: "Nova Scotia plates are rear-only and have blue lettering.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia Plate - Blue Middle"
    },
    {
        id: 74,
        question: "Which province uses the area code 306?",
        answers: ['Saskatchewan'],
        images: [
            images['ca_sask_area_code.png'],
        ],
        full: "The entire province of Saskatchewan uses the area code 306.",
        source: "Comprehensive Canada - Area Codes",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Saskatchewan Area Code 306"
    },
    {
        id: 75,
        question: "Which province uses the area code 204?",
        answers: ['Manitoba'],
        images: [
            images['ca_manitoba_area_code.png'],
        ],
        full: "The entire province of Manitoba uses the area code 204. (or rarely, 431 in pre-2012 coverage)",
        source: "Comprehensive Canada - Area Codes",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Manitoba Area Code 204"

    },
    {
        id: 76,
        question: "Which provinces uses the area code 902?",
        answers: ['Nova Scotia', 'Prince Edward Island'],
        images: [
            images['ca_ns_area_code.png'],
        ],
        full: "Nova Scotia and Prince Edward Island use the area code 902. Note that these provinces often exclude the area code.",
        source: "Comprehensive Canada - Area Codes",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia & PEI Area Code 902"
    },
    {
        id: 77,
        question: "Which provinces have poles with lighter wood and more knots compared to other Canadian poles?",
        answers: ['Nova Scotia', 'Prince Edward Island', 'New Brunswick', 'Newfoundland and Labrador'],
        images: [
            images['ca_eastern_pole.png'],
        ],
        full: "Eastern Canada has poles with lighter wood and more knots compared to other Canadian poles.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Eastern Canada Pole"
    },
    {
        id: 78,
        question: "Which provinces often use transformers at 45 degree angles? ",
        answers: ['Nova Scotia', 'Prince Edward Island'],
        images: [
            images['ca_ns_transformer.png'],
        ],
        full: "Nova Scotia and PEI often use transformers at 45 degree angles. This can be difficult to tell from certain angles. In PEI, however, they will be smaller with red text. ",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia & PEI 45 Degree Transformer"
    },
    {
        id: 79,
        question: "Which provinces commonly have a colored sticker wrapped around wires?",
        answers: ['Nova Scotia', 'New Brunswick'],
        images: [
            images['ca_ns_nb_wire_sticker.png'],
        ],
        full: "In Nova Scotia (and sometimes New Brunswick) it is common to find a sticker wrapped around wires. They are usually yellow but can be other colors.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia/New Brunswick Wire Sticker"
    },
    {
        id: 80,
        question: "Which province most frequently uses this lamp?",
        answers: ['Nova Scotia'],
        images: [
            images['ca_ns_lamp.png'],
        ],
        full: "This is the most common style of lamp in Nova Scotia.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia Lamp"
    },
    {
        id: 81,
        question: "Which province often uses sticks at the end of its guardrails? (often yellow snow poles)",
        answers: ['Nova Scotia'],
        images: [
            images['ca_ns_bollard.png'],
        ],
        full: "Nova Scotia sometimes has various sticks at the end of its guardrails. They are usually yellow snow poles.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia Guardrail Bollard"
    },
    {
        id: 82,
        question: "Which province is the only one to use exclusively double yellow middle lines?",
        answers: ['Nova Scotia'],
        images: [
            images['ca_ns_double_yellow.png'],
        ],
        full: "You will never find a solid single yellow line in Nova Scotia. Quebec also uses a lot of double yellow lines, and New Brunswick uses very few.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia Double Yellow Line"
    },
    {
        id: 83,
        question: "In which province are signs with thick white borders and curved corners most common?",
        answers: ['Nova Scotia'],
        images: [
            images['ca_ns_signage.png'],
        ],
        full: "Thick white borders with curved corner signs are common in Nova Scotia.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Nova Scotia Sign Borders & Corners",
    },
    {
        id: 84,
        question: "Which province uses the area code 506?",
        answers: ['New Brunswick'],
        images: [
            images['ca_nb_area_code.png'],
        ],
        full: "The entire province of New Brunswick uses only the area code 506.",
        source: "Comprehensive Canada - Area Codes",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "New Brunswick Area Code 506"
    },
    {
        id: 85,
        question: "In which province are double insulators most common?",
        answers: ['New Brunswick'],
        images: [
            images['ca_nb_double_insulator.png'],
        ],
        full: "Double insulators are most common in New Brunswick than any other province.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "New Brunswick Double Insulator"
    },
    {
        id: 86,
        question: "Which province often uses yellow horizontal pole stickers?",
        answers: ['New Brunswick'],
        images: [
            images['ca_nb_pole_sticker.png'],
        ],
        full: "New Brunswick often has yellow horizontal pole stickers.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "New Brunswick Yellow Pole Sticker"

    },
    {
        id: 87,
        question: "These square lamps are unique to which province?",
        answers: ['New Brunswick'],
        images: [
            images['ca_nb_square_lamp.png'],
        ],
        full: "Square lamps are unique to New Brunswick. They can be found in Nova Scotia, but will have a blue bump in that province.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "New Brunswick Square Lamp"
    },
    {
        id: 88,
        question: "Which province uses smaller transformers with red numbers?",
        answers: ['Prince Edward Island'],
        images: [
            images['ca_pei_transformer.png'],
        ],
        full: "All transformers on PEI are extra small with red text. They will also be at 45 degree angles, which can be difficult to see from certain angles.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Prince Edward Island Transformer"
    },
    {
        id: 89,
        question: "Almost all poles in which province have this white sticker?",
        answers: ['Prince Edward Island'],
        images: [
            images['ca_pei_pole_sticker.png'],
        ],
        full: "Almost all poles in PEI have this white sticker. When combined with the red dirt, you can almost always know you are in PEI.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Prince Edward Island Pole Sticker"
    },
    {
        id: 90,
        question: "Which province uses the area code 709?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_nl_area_code.png'],
        ],
        full: "The entire province of Newfoundland and Labrador uses the area code 709.",
        source: "Comprehensive Canada - Area Codes",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Newfoundland and Labrador Area Code 709"
    },
    {
        id: 91,
        question: "Which province uses 'routes' and 'lines' in highway terminology?",
        answers: ['Newfoundland and Labrador'],
        images: [
            images['ca_nl_route_line.png'],
        ],
        full: "In no other province will you see 'routes'. Intersecting roads are called 'lines', which is a term often found in Newfoundland. They are inter-community roads, much longer than lanes.",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Newfoundland and Labrador Route & Line"
    },
    {
        id: 92,
        question: "Trees in which provinces are notable for being tall and skinny?",
        answers: ['Yukon', 'Northwest Territories'],
        images: [
            images['ca_yukon_trees.png'],
        ],
        full: "Trees in Yukon and the NWT are tall and skinny (boreal).",
        source: "Comprehensive Canada",
        sourceURL: "https://docs.google.com/document/d/15DuGdsvKMqv5O-XiFwhNuczw89jVqg8XycY7brvhjP4",
        short: "Yukon/NWT Tall Skinny Trees"
    }
    
    // TO ADD: Finish Area Codes, Highway Markers, Highway Shields
    





]
export const caSources = ['Plonk It Guide', 'Comprehensive Canada', 'Comprehensive Canada - Area Codes'];
