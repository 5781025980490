import React from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import * as turf from '@turf/turf';
import japanData from './jp-prefectures.json';

const containerStyle = {
  width: '850px',
  height: '100%',
};

const center = {
  lat: 36.2048, 
  lng: 138.2529, 
};

function convertToMultiPolygon(feature) {
  if (feature.geometry.type === "Polygon") {
      feature.geometry.type = "MultiPolygon";
      feature.geometry.coordinates = [feature.geometry.coordinates];
  }
  return feature;
}

japanData.features = japanData.features.map(feature => convertToMultiPolygon(feature));


const JapanMap = ({ handleStateClick, quizMode }) => {
  const onMapClick = (event) => {
    const point = turf.point([event.latLng.lng(), event.latLng.lat()]);
    const clickedFeature = japanData.features.find((feature) => {
      if (feature.geometry.type === 'Polygon') {
        const polygon = turf.polygon(feature.geometry.coordinates);
        return turf.booleanPointInPolygon(point, polygon);
      } else if (feature.geometry.type === 'MultiPolygon') {
        return feature.geometry.coordinates.some((polygonCoords) => {
          const polygon = turf.polygon(polygonCoords);
          return turf.booleanPointInPolygon(point, polygon);
        });
      }
      return false;
    });

    if (clickedFeature) {
      if (quizMode === 'region') {
        const region = prefectureToRegion[clickedFeature.properties.name];
        console.log("Region clicked:", region);
        handleStateClick(region);
      } else {
        console.log("Prefecture clicked:", clickedFeature.properties.name);
        handleStateClick(clickedFeature.properties.name);
      }
    } else {
      console.log("No prefecture found at this location.");
    }
  };

  const formatCoordinates = (coords) => {
    const flattenCoords = (coordSet) => {
      return coordSet.map(coord => {
        if (Array.isArray(coord) && coord.length >= 2) {
          const [lng, lat] = coord;
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        }
        return null;
      }).filter(coord => coord !== null);
    };

    if (Array.isArray(coords[0][0])) {
      return coords.map(coordSet => flattenCoords(coordSet));
    } else {
      return [flattenCoords(coords)];
    }
  };

  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    // <LoadScript googleMapsApiKey={key}
    //   loadingElement={
    //     <p>Loading... <br />If the map does not load, try refreshing the page.</p>
    //   }
    // >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        onClick={onMapClick}
        options={{
          gestureHandling: 'greedy',
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
      >
        {japanData.features.map((feature, index) => {
          const paths = formatCoordinates(feature.geometry.coordinates);
          if (paths.length === 0 || paths.some(path => path.length === 0)) {
            return null;
          }
          return (
            <Polygon
              key={index}
              paths={paths.flat()}
              onClick={() => handleStateClick(feature.properties.name)}
              options={{
                fillColor: 'blue',
                fillOpacity: 0.4,
                strokeColor: 'blue',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          );
        })}
      </GoogleMap>
    // </LoadScript>
  );
};

const prefectureToRegion = {
  'Hokkaido': 'Hokkaido',
  'Aomori': 'Tohoku',
  'Iwate': 'Tohoku',
  'Miyagi': 'Tohoku',
  'Akita': 'Tohoku',
  'Yamagata': 'Tohoku',
  'Fukushima': 'Tohoku',
  'Ibaraki': 'Kanto',
  'Tochigi': 'Kanto',
  'Gunma': 'Kanto',
  'Saitama': 'Kanto',
  'Chiba': 'Kanto',
  'Tokyo': 'Kanto',
  'Kanagawa': 'Kanto',
  'Niigata': 'Chubu',
  'Toyama': 'Hokuriku',
  'Ishikawa': 'Hokuriku',
  'Fukui': 'Hokuriku',
  'Yamanashi': 'Chubu',
  'Nagano': 'Chubu',
  'Gifu': 'Chubu',
  'Shizuoka': 'Chubu',
  'Aichi': 'Chubu',
  'Mie': 'Kansai',
  'Shiga': 'Kansai',
  'Kyoto': 'Kansai',
  'Osaka': 'Kansai',
  'Hyogo': 'Kansai',
  'Nara': 'Kansai',
  'Wakayama': 'Kansai',
  'Tottori': 'Chugoku',
  'Shimane': 'Chugoku',
  'Okayama': 'Chugoku',
  'Hiroshima': 'Chugoku',
  'Yamaguchi': 'Chugoku',
  'Tokushima': 'Shikoku',
  'Kagawa': 'Shikoku',
  'Ehime': 'Shikoku',
  'Kochi': 'Shikoku',
  'Fukuoka': 'Kyushu',
  'Saga': 'Kyushu',
  'Nagasaki': 'Kyushu',
  'Kumamoto': 'Kyushu',
  'Oita': 'Kyushu',
  'Miyazaki': 'Kyushu',
  'Kagoshima': 'Kyushu',
  'Okinawa': 'Okinawa'
};

export default JapanMap;
