import React from 'react';
import { Nav, Card, Row, Col } from 'react-bootstrap';

import './Home.css'; 
import allImages from '../images';
const images = allImages['home'];

const Home = () => {
    return (
        <>
            <p className="display-5" style={{ marginTop: "20px", textAlign: "inline" }}>
                Welcome to Plonkable!
            </p>
            <br />
            <p className="lead">
                Plonkable aims to help GeoGuessr players improve their region guessing skills through customizable quizzes using tips from a variety of sources.
            </p>
            <p className="lead">
                I plan to update Plonkable as often as I can with new countries and updates to existing ones. See the <Nav.Link style={{ display: "inline" }} href="/changelog">changelog</Nav.Link> for more information.
            </p>

            <Row className="mb-4">
                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/australia" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['auflag.png']} alt="Australia" />
                                Australia
                            </Nav.Link>
                            <Card.Text>Last Updated: October 2nd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/brazil" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['brflag.png']} alt="Brazil" />
                                Brazil
                            </Nav.Link>
                            <Card.Text>Last Updated: October 2nd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/canada" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['caflag.png']} alt="Canada" />
                                Canada
                            </Nav.Link>
                            <Card.Text>Last Updated: October 2nd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/indonesia" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['idflag.png']} alt="Indonesia" />
                                Indonesia
                            </Nav.Link>
                            <Card.Text>Last Updated: October 2nd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/japan" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['jpflag.png']} alt="Japan" />
                                Japan
                            </Nav.Link>
                            <Card.Text>Last Updated: October 3rd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/malaysia" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['myflag.png']} alt="Malaysia" />
                                Malaysia
                            </Nav.Link>
                            <Card.Text>Last Updated: October 2nd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <Card className="country-card">
                        <Card.Body>
                            <Nav.Link href="/united-states" style={{ fontSize: "24px", textAlign: "center", fontWeight: "bold" }} className="btn">
                                <Card.Img variant="top" src={images['usflag.png']} alt="United States" />
                                United States
                            </Nav.Link>
                            <Card.Text>Last Updated: October 2nd, 2024</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Home;
