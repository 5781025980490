// importAll function to handle nested directories
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
      const pathParts = item.replace('./', '').split('/');
      let currentLevel = images;

      pathParts.forEach((part, index) => {
          if (index === pathParts.length - 1) {
              currentLevel[part] = r(item);
          } else {
              if (!currentLevel[part]) {
                  currentLevel[part] = {};
              }
              currentLevel = currentLevel[part];
          }
      });
  });
  return images;
}

const images = importAll(require.context('./images', true, /\.(png|jpe?g|svg)$/));

export default images;
