import React, { useEffect } from 'react';
import { GoogleMap, Polygon } from '@react-google-maps/api';
import * as turf from '@turf/turf';

// Source: https://github.com/rowanhogan/australian-states/blob/master/states.geojson
import auData from './au-states.json';

const containerStyle = {
  width: '850px',
  height: '100%',
};

const center = {
  lat: -25.2744, 
  lng: 133.7751, 
};

const AustraliaMap = ({ handleStateClick, googleLoaded, errorLoading }) => {
  const onMapClick = (event) => {
    console.log("Click event:", event);

    const point = turf.point([event.latLng.lng(), event.latLng.lat()]);

    const state = auData.features.find((feature) => {
      if (feature.geometry.type === 'Polygon') {
        const polygon = turf.polygon(feature.geometry.coordinates);
        return turf.booleanPointInPolygon(point, polygon);
      } else if (feature.geometry.type === 'MultiPolygon') {
        return feature.geometry.coordinates.some((polygonCoords) => {
          const polygon = turf.polygon(polygonCoords);
          return turf.booleanPointInPolygon(point, polygon);
        });
      }
      return false;
    });

    if (state) {
      console.log("AustraliaMap.js: State clicked:", state.properties.name);
      handleStateClick(state.properties.name); // Pass the state name directly
    } else {
      console.log("No state found at this location.");
    }
  };

  const formatCoordinates = (coords) => {
    const flattenCoords = (coordSet) => {
      return coordSet.map(coord => {
        if (Array.isArray(coord) && coord.length >= 2) {
          const [lng, lat] = coord;
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        }
        console.error('Invalid coordinate format:', coord);
        return null;
      }).filter(coord => coord !== null);
    };

    if (Array.isArray(coords[0][0])) {
      return coords.map(coordSet => flattenCoords(coordSet));
    } else {
      return [flattenCoords(coords)];
    }
  };

  // Check if Google Maps is loaded before rendering the map
  // if (!googleLoaded) {
  //   if (errorLoading) {
  //     return <p>Error loading Google Maps. Please try refreshing the page.</p>;
  //   }
  //   return <p>Loading map...</p>;
  // }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={4}
      onClick={onMapClick}
      options={{
        gestureHandling: 'greedy',
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      {auData.features.map((feature, index) => {
        const paths = formatCoordinates(feature.geometry.coordinates);

        if (paths.length === 0 || paths.some(path => path.length === 0)) {
          console.warn('No valid paths for feature:', feature.properties.name);
          return null;
        }

        return (
          <Polygon
            key={index}
            paths={paths.flat()}
            onClick={() => handleStateClick(feature.properties.name)}
          />
        );
      })}
    </GoogleMap>
  );
};

export default AustraliaMap;
