import React, { useState, useEffect } from 'react';
import './UnitedStates.css';
import TipQuizzer from '../../TipQuizzer/TipQuizzer.js';
import { usTips, usSources } from './UnitedStatesTips.js';
import ErrorBoundary from '../../ErrorBoundary.js';
import MapLoader from '../../MapLoader.js';


const UnitedStates = () => {
    const [googleLoaded, setGoogleLoaded] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false); 
    const [idFilter, setIdFilter] = useState(usTips.map(tip => tip.id));

    const onIdFilterChange = (updatedIdFilter) => {
        setIdFilter(updatedIdFilter);
    };

    useEffect(() => {
        console.log('idFilter state updated:', idFilter);
    }, [idFilter]);

    const filteredTips = usTips.filter(tip => idFilter.includes(tip.id));
    const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    return (
        <>  
            {!googleLoaded && !errorLoading && <p>Loading Google Maps...</p>}
            {errorLoading && <p>Error loading Google Maps. Please try again later.</p>}
            <p></p><br/><br/><br/><br/><br/><br/>

            {googleLoaded && (
                <div className="map-container">
                    <ErrorBoundary>
                        <TipQuizzer 
                            tips={filteredTips}
                            allTips={usTips} 
                            sources={usSources} 
                            country={'United States'} 
                            handleIdFilterChange={onIdFilterChange} 
                        />
                    </ErrorBoundary>
                </div>
            )}

            <MapLoader 
                googleMapsApiKey={key} 
                setGoogleLoaded={setGoogleLoaded} 
                setErrorLoading={setErrorLoading} 
            />
        </>
    );
};

export default UnitedStates
