import React, { useState, useEffect } from 'react';
import Map from '../Map';
import './TipQuizzer.css';
import parse from 'html-react-parser';
import CustomizationModal from './CustomizationModal';
import { Button } from 'react-bootstrap';
import JapanMap from '../pages/Japan/JapanMap';

// Utility function to shuffle an array
const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};

// Utility function to get a random element from an array
const getRandomElement = (array) => {
    return array[Math.floor(Math.random() * array.length)];
};

const TipQuizzer = ({ tips, allTips, sources, country, handleIdFilterChange }) => {
    const [shuffledTips, setShuffledTips] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [feedbackClass, setFeedbackClass] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [idFilter, setIdFilter] = useState([]);
    const [mapKey, setMapKey] = useState(Date.now()); // Used to force map re-render

    // Shuffle the tips when they are passed in (already filtered from the parent)
    useEffect(() => {
        setShuffledTips(shuffleArray(tips));
    }, [tips]);

    // Whenever tips change, reset the map key to force re-render
    useEffect(() => {
        setMapKey(Date.now());
    }, [shuffledTips]);

    useEffect(() => {
        if (shuffledTips.length > 0 && shuffledTips[currentQuestionIndex]) {
            const currentTip = shuffledTips[currentQuestionIndex];
            if (currentTip.images && currentTip.images.length > 0) {
                let ind = getRandomElement(currentTip.images);
                setSelectedImage(ind);
            }
        }
    }, [shuffledTips, currentQuestionIndex]);
    
    useEffect(() => {
        if (selectedState !== null) {
            handleAnswer();
        }
    }, [selectedState]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            const activeElement = document.activeElement;
            
            // Check if the focused element is a button or dropdown
            const isInteractiveElement = ['INPUT', 'TEXTAREA', 'BUTTON', 'SELECT'].includes(activeElement.tagName);
    
            // Trigger next tip if space is pressed and active element is not interactive
            if (!isInteractiveElement && (event.code === 'Space' || event.key === ' ')) {
                event.preventDefault(); // Prevent the default action for the space bar
                handleNext(); // Proceed to the next tip
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentQuestionIndex, shuffledTips.length]);
    
    
    

    const handleAnswer = () => {
        const currentTip = shuffledTips[currentQuestionIndex];
        if (selectedState && currentTip.answers.some(answer => answer.toLowerCase() === selectedState.toLowerCase())) {
            setFeedbackClass('green-glow');
            setFeedback(`Correct! You selected ${selectedState}`);
        } else {
            setFeedbackClass('red-glow');
            setFeedback(`Incorrect! You selected ${selectedState}, but the correct answer${currentTip.answers.length > 1 ? 's were' : ' was'} ${currentTip.answers.join(', ')}.`);
        }
        setShowFeedback(true);
    };

    const handleNext = () => {
        setSelectedState(null);
        setShowFeedback(false);
        setFeedbackClass('');
        if (currentQuestionIndex + 1 < shuffledTips.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setShuffledTips(shuffleArray(tips)); // Reshuffle for the next round
            setCurrentQuestionIndex(0);
        }
    };

    const onStateClick = (stateName) => {
        setSelectedState(stateName);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleIdFilterChangeInternal = (updatedIdFilter) => {
        setIdFilter(updatedIdFilter);
        if (handleIdFilterChange) {
            handleIdFilterChange(updatedIdFilter);
        }
    };

    return (
        <div className="tip-quizzer">
            <div className="content-container">
                {shuffledTips.length > 0 ? (
                    <>
                        {/* Display the question and image when tips are available */}
                        <div className="tip-container">
                            <p className="center-text">{shuffledTips[currentQuestionIndex]?.question}</p>
                            {selectedImage && <img src={selectedImage} alt="Tip" className="tip-image" />}
                        </div>
    
                        {/* Show the appropriate map based on the country */}
                        <div className="map-container">
                            {country === 'Japan' ? (
                                <JapanMap
                                    key={mapKey} // Force re-render of the map when the key changes
                                    handleStateClick={onStateClick}
                                    quizMode={shuffledTips[currentQuestionIndex]?.mode}
                                />
                            ) : (
                                <Map
                                    key={mapKey} // Force re-render of the map when the key changes
                                    handleStateClick={onStateClick}
                                    country={country}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    // Display message when there are no tips selected
                    <div>No tips available. Please customize your selection.</div>
                )}
            </div>
    
            {/* Show the feedback container and button only when there are tips */}
            {shuffledTips.length > 0 && (
                <>
                    <div className="feedback-container" style={{ minHeight: '150px' }}>
                        {showFeedback ? (
                            <>
                                <span className={`feedback ${feedbackClass}`}>{feedback}</span>
                                <br /> <br />
                                {shuffledTips[currentQuestionIndex]?.full && (
                                    <>
                                        {parse(shuffledTips[currentQuestionIndex].full)}
                                        <div>
                                            <br />
                                            <p>
                                                Source: <a target="_blank" href={shuffledTips[currentQuestionIndex].sourceURL}>{shuffledTips[currentQuestionIndex].source}</a>
                                            </p>
                                            
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="empty-feedback">

                            </div>
                            
                        )}
                    </div>
    
        
                </>
            )}
    
            {/* Show the customization modal button regardless of tips */}
            <div>
        {/* Other components and elements */}
        
        {/* Show the customization modal button regardless of tips */}
       
        </div>
        <div className="button-container">
            <Button className="skip-button" style={{height: "46px"}} onClick={handleNext}>
                {showFeedback ? "Next" : "Skip"}
            </Button>
            <Button className="customize-button" variant="primary" onClick={handleShowModal}>
                Customize
            </Button>
        </div>

            <CustomizationModal
            show={showModal}
            handleClose={handleCloseModal}
            tips={allTips}
            sources={sources}
            handleIdFilterChange={handleIdFilterChangeInternal}
            country={country}
            />
        </div>
    );
};

export default TipQuizzer;
