import React from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import * as turf from '@turf/turf';

// source: https://github.com/superpikar/indonesia-geojson/blob/master/indonesia-en.geojson
import indonesiaData from './id-regions.json';

const containerStyle = {
  width: '850px',
  height: '100%',
};

const center = {
  lat: -0.7893, 
  lng: 113.9213,
};

const IndonesiaMap = ({ handleStateClick, quizMode }) => {
  const onMapClick = (event) => {
    const point = turf.point([event.latLng.lng(), event.latLng.lat()]);
    const province = indonesiaData.features.find((feature) => {
      if (feature.geometry.type === 'Polygon') {
        const polygon = turf.polygon(feature.geometry.coordinates);
        return turf.booleanPointInPolygon(point, polygon);
      } else if (feature.geometry.type === 'MultiPolygon') {
        return feature.geometry.coordinates.some((polygonCoords) => {
          const polygon = turf.polygon(polygonCoords);
          return turf.booleanPointInPolygon(point, polygon);
        });
      }
      return false;
    });

    if (province) {
      console.log("Province clicked:", province.properties.state);
      handleStateClick(province.properties.state); 
    } else {
      console.log("No province found at this location.");
    }
  };

  const formatCoordinates = (coords) => {
    const flattenCoords = (coordSet) => {
      return coordSet.map(coord => {
        if (Array.isArray(coord) && coord.length >= 2) {
          const [lng, lat] = coord;
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        }
        return null;
      }).filter(coord => coord !== null);
    };

    if (Array.isArray(coords[0][0])) {
      return coords.map(coordSet => flattenCoords(coordSet));
    } else {
      return [flattenCoords(coords)];
    }
  };

  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    // <LoadScript googleMapsApiKey={key}
    //   loadingElement={
    //     <p>Loading... <br />If the map does not load, try refreshing the page.</p>
    //   }
    // >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        onClick={onMapClick}
        options={{
          gestureHandling: 'greedy',
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
      >
        {indonesiaData.features.map((feature, index) => {
          const paths = formatCoordinates(feature.geometry.coordinates);
          if (paths.length === 0 || paths.some(path => path.length === 0)) {
            return null;
          }
          return (
            <Polygon
              key={index}
              paths={paths.flat()}
              onClick={() => handleStateClick(feature.properties.name)}
              options={{
                fillColor: 'blue',
                fillOpacity: 0.4,
                strokeColor: 'blue',
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          );
        })}
      </GoogleMap>
    // </LoadScript>
  );
};

export default IndonesiaMap;
