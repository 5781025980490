import React from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import * as turf from '@turf/turf';

// Source: https://gist.github.com/M1r1k/d5731bf39e1dfda5b53b4e4c560d968d
import canadaData from './ca-provinces.json';

const containerStyle = {
  width: '850px',
  height: '100%',
};

const center = {
  lat: 52.1304,  
  lng: -96.3468, 
};

const CanadaMap = ({ handleStateClick, showTips }) => {

  const onMapClick = (event) => {
    const point = turf.point([event.latLng.lng(), event.latLng.lat()]);

    const province = canadaData.features.find((feature) => {
      if (feature.geometry.type === 'Polygon') {
        const polygon = turf.polygon(feature.geometry.coordinates);
        return turf.booleanPointInPolygon(point, polygon);
      } else if (feature.geometry.type === 'MultiPolygon') {
        return feature.geometry.coordinates.some((polygonCoords) => {
          const polygon = turf.polygon(polygonCoords);
          return turf.booleanPointInPolygon(point, polygon);
        });
      }
      return false;
    });

    if (province) {
      console.log("CanadaMap.js: Province clicked:", province.properties.name);
      handleStateClick(province.properties.name); 
    }
  };

  const formatCoordinates = (coords) => {
    const flattenCoords = (coordSet) => {
      return coordSet.map(coord => {
        if (Array.isArray(coord) && coord.length >= 2) {
          const [lng, lat] = coord;
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        }
        return null;
      }).filter(coord => coord !== null);
    };

    if (Array.isArray(coords[0][0])) {
      return coords.map(coordSet => flattenCoords(coordSet));
    } else {
      return [flattenCoords(coords)];
    }
  };

  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

  return (
    <>
   {/* <LoadScript googleMapsApiKey={key}> */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={3}
        onClick={onMapClick}
        options={{
          gestureHandling: 'greedy', 
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
      >

        {showTips && canadaData.features.map((feature, index) => {
          const paths = formatCoordinates(feature.geometry.coordinates);
          if (paths.length === 0 || paths.some(path => path.length === 0)) {
            console.warn('No valid paths for feature:', feature.properties.name);
            return null;
          }
          return (
            <Polygon
              key={index}
              paths={paths.flat()}
              onClick={() => handleStateClick(feature.properties.name)}
            />
          );
        })}
      </GoogleMap>
   {/* </LoadScript> */}
    </>
  );
};

export default CanadaMap;
