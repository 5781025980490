import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavBar.css';

const NavBar = () => {
  return (
    <Navbar bg expand="lg" className="navbar-custom">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand><p style={{fontWeight:"bold",fontSize:"32px"}}>Plonkable</p></Navbar.Brand>
        </LinkContainer>

        {/* Navbar toggle for mobile view */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            {/* Dropdown for country selection */}
            <NavDropdown title="Select Country" id="country-dropdown">
            <LinkContainer to="/australia">
                <NavDropdown.Item>Australia</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/brazil">
                <NavDropdown.Item>Brazil</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/canada">
                <NavDropdown.Item>Canada</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/indonesia">
                <NavDropdown.Item>Indonesia</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/japan">
                <NavDropdown.Item>Japan</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/malaysia">
                <NavDropdown.Item>Malaysia</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/united-states">
                <NavDropdown.Item>United States</NavDropdown.Item>
              </LinkContainer>
              {/* <LinkContainer to="/mexico">
                <NavDropdown.Item>Mexico</NavDropdown.Item>
              </LinkContainer> */}





            </NavDropdown>

            {/* <LinkContainer style={{paddingLeft:"25px",paddingRight:"25px"}} to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer> */}
            <LinkContainer to="/changelog">
              <Nav.Link>Changelog</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
