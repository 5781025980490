// Map.js
import React from 'react';
import UnitedStatesMap from './pages/UnitedStates/UnitedStatesMap';
import CanadaMap from './pages/Canada/CanadaMap';
import BrazilMap from './pages/Brazil/BrazilMap';
import JapanMap from './pages/Japan/JapanMap';
import AustraliaMap from './pages/Australia/AustraliaMap';
import IndonesiaMap from './pages/Indonesia/IndonesiaMap';
import MalaysiaMap from './pages/Malaysia/MalaysiaMap';

const Map = ({ handleStateClick, country }) => {
    if (country === 'United States') {
        return <UnitedStatesMap handleStateClick={handleStateClick} />;
    } else if (country === 'Canada') {
        return <CanadaMap handleStateClick={handleStateClick} />;
    } else if (country === 'Brazil') {
        return <BrazilMap handleStateClick={handleStateClick} />;
    } else if (country === 'Japan') {
        return <JapanMap handleStateClick={handleStateClick} />;
    } else if (country === 'Australia') {   
        return <AustraliaMap handleStateClick={handleStateClick} />;
    } else if (country === 'Indonesia') {
        return <IndonesiaMap handleStateClick={handleStateClick} />;
    } else if (country === 'Malaysia') { // not malasyia :^)
        return <MalaysiaMap handleStateClick={handleStateClick} />;
    }
    
    return null;
};

export default Map;
