import React, { useState, useEffect } from 'react';
import AustraliaMap from './AustraliaMap.js'; 
import './Australia.css';
import TipQuizzer from '../../TipQuizzer/TipQuizzer.js';
import { auTips, auSources } from './AustraliaTips.js'; 
import ErrorBoundary from '../../ErrorBoundary';
import MapLoader from '../../MapLoader.js'; 

const Australia = () => {
    const [idFilter, setIdFilter] = useState(auTips.map(tip => tip.id)); 
    const [googleLoaded, setGoogleLoaded] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false); 

    const onIdFilterChange = (updatedIdFilter) => {
        setIdFilter(updatedIdFilter);
    };

    useEffect(() => {
        console.log('idFilter state updated:', idFilter);
    }, [idFilter]);

    const filteredTips = auTips.filter(tip => idFilter.includes(tip.id));
    const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

    return (
        <>  
            {!googleLoaded && !errorLoading && <p>Loading Google Maps...</p>}
            {errorLoading && <p>Error loading Google Maps. Please try again later.</p>}

            <p></p>
            <br/><br/><br/><br/><br/><br/>
            {googleLoaded && (
                <div className="map-container">
                    <ErrorBoundary>
                        <TipQuizzer 
                            tips={filteredTips} 
                            allTips={auTips} 
                            sources={auSources} 
                            country={'Australia'} 
                            handleIdFilterChange={onIdFilterChange} 
                        />
                    </ErrorBoundary>
                </div>
            )}
                  
            <MapLoader 
                googleMapsApiKey={key}
                setGoogleLoaded={setGoogleLoaded} 
                setErrorLoading={setErrorLoading} 
            />
        </>
    );
}

export default Australia;
