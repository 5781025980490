import React from "react";
import { Accordion } from "react-bootstrap";

const Changelog = () => {
    return (
        <>
        <br/>
        <p style={{fontWeight:"lighter",fontSize:"32px"}}>Changelog</p>
        <br/>
        <p style={{fontWeight:"lighter",fontSize:"24px"}}>Planned Changes:</p>
        <p style={{fontWeight:"lighter",fontSize:"20px"}}>New Countries: India, Mexico, Spain</p>
        <p style={{fontWeight:"lighter",fontSize:"20px"}}>Country Updates & Expansions: Australia, Brazil, Malaysia, Japan</p>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Accordion style={{ width: '35%' }} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>October 4, 2024</Accordion.Header>
                    <Accordion.Body>
                        - Official Release
                    </Accordion.Body>
                </Accordion.Item> 
            </Accordion>
        </div>
        </>
    );
}

export default Changelog;
