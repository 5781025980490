import React from 'react';
import { Nav } from 'react-bootstrap';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            
            <p style={{fontSize:"40px"}}>Page could not be found. Sorry!</p>
            <Nav.Link style={{fontSize:"24px",textDecoration:"underline"}} href="/">Back to Home →</Nav.Link>
        </div>
    );
};

export default NotFound;