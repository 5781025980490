import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p style={{fontWeight:"lighter"}}>___________________________________</p>
                <p>© Plonkable 2024</p>
                <p>For feedback or help, send me a request on <a href="https://www.geoguessr.com/user/5aa5926b42305f89a0f9f86d" target="_blank">GeoGuessr</a>, or use <a href="https://forms.gle/QzGFgvt2CSVgx5fe9" target="_blank">this form</a>.</p>
            </div>
        </footer>
    );
}
