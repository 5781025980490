import allImages from '../../images';
const images = allImages['us'];

export const usTips = [
    {
        id: 1,
        question: "In which state is this speed limit sign found?",
        answers: ["Oregon"],
        images: [
            images['us_oregon_speed.png']
        ],
        full: "Speed signs in Oregon often simply have the word ‘Speed’ instead of the standard ‘Speed Limit’.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Oregon Speed Limit Sign"
    },
    {
        id: 2,
        question: "In which state is this bollard found?",
        answers: ["Nevada"],
        images: [
            images['us_nevada_bollard.png'],
            images['us_nevada_bollard2.png']
        ],
        full: "This bollard is found in Nevada. It consists of a reflector on a thin stick. The reflector is a vertical rectangle that consists of a white field surrounded by a black outline.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Nevada Bollard"
    },
    {
        id: 3,
        question: "In which state is this yellow or red band found around signposts?",
        answers: ["Texas"],
        images: [
            images['us_texas_band.png'],
            images['us_texas_band2.png']
        ],
        full:"Yellow or red bands can commonly be seen around signposts in Texas.",
        source: "Plonk It Guide",
        sourceURL:"https://www.plonkit.net/united-states-2",
        short: "Texas Band"
    },
    {
        id: 4,
        question: "In which state can these reflectors be found on stop signs in rural areas?",
        answers: ["Kansas"],
        images: [
            images['us_kansas_signpost_reflector.png']
        ],
        full: "These white reflectors on signposts are unique to Kansas. They are most commonly seen on ‘stop’ signs in rural areas.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Kansas Signpost Reflector"
        
    },
    {
        id: 5,
        question: "In which state are these yellow signs placed before bridges or beginnings and ends of large highways?",
        answers: ["Minnesota"],
        images: [
            images['us_minnesota_triangle_bridge_sign.png']
        ],
        full: 'In Minnesota, these yellow signs with vertical, black triangles are placed before bridges at most major river crossings and at the beginnings and ends of guardrail sections on large highways.',
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Minnesota Triangle Bridge Sign"
    },
    {
        id: 6,
        question: "In which state are these black and white markers (with varying shapes) attached to most rural stop signs?",
        answers: ["Minnesota"],
        images: [
            images['us_minnesota_bnw_marker.png']
        ],
        full: 'On most rural ‘stop’ signposts in Minnesota, a black-and-white marker is attached to the posts horizontally. The shape may vary slightly.',
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Minnesota BNW Marker"
    },
    {
        id: 7,
        question: "Which state sometimes has these 'fraction' street signs?",
        answers: ["West Virginia"],
        images: [
            images['us_west_virginia_fraction_sign.png']
        ],
        full: 'West Virginia sometimes features street signs with a symbol that resembles a fraction inside a circle.',
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "West Virginia Fraction Street Sign"
    }, 
    {
        id: 8,
        question: "Which state has these green signs with 3 rows of numbers on the backs of most rural stop signs?",
        answers:[ "Oklahoma"],
        images: [
            images['us_oklahoma_green_stop_sign_back.png'],
            images['us_oklahoma_green_stop_sign_back2.png']
        ],
        full: 'In Oklahoma, you can often find a green rectangular sign with 3 rows of numbers attached to the back of stop signs at rural intersections.',
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Oklahoma Green Sign on Stop Sign Backs"
    },
    {
        id: 9,
        question: "Which state has these white, rectangular signs at intersections with the numbers of state routes?",
        answers: ["Pennsylvania"],
        images: [
            images['us_pennsylvania_sr_sign.png'],
            images['us_pennsylvania_sr_sign2.png']
        ],
        full: "Rectangular white signs can sometimes be seen attached to signposts at intersections in Pennsylvania. On top, the number of the converging state route is written.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Pennsylvania SR Sign"
    },
    {
        id: 10,
        question: "Which state has these white rectangular signs at intersections?",
        answers: ["Virginia"],
        images: [
            images['us_virginia_white_sign.png']
        ],
        full: "Rectangular white signs can sometimes be seen at intersections in Virginia.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Virginia White Sign"
    },
    {
        id: 11,
        question: "Which state often has these white signs attached to signposts?",
        answers: ["North Carolina"],
        images: [
            images['us_north_carolina_white_sign.png']
        ],
        full: "White signs attached to signposts can commonly be seen in North Carolina.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "North Carolina White Sign"
    },
    {
        id: 12,
        question: "Which state has these black signs at intersections?",
        answers: ["South Carolina"],
        images: [
            images['us_south_carolina_black_sign.png']
        ],
        full: "Black street signs with white text can commonly be found at intersections in South Carolina.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "South Carolina Black Sign"
    },
    {
        id: 13,
        question: "Which states have small green reference markers with three rows of numbers?",
        answers: ["Vermont","New York"],
        images: [
            images['us_green_reference_marker.png'],
            images['us_green_reference_marker2.png']
        ],
        full: "Vermont and New York feature small rectangular green signs on stop sign posts with three rows of horizontal numbers. The top row of numbers correlates to the highway or road number that the road with the stop sign intersects with. In Vermont, these will typically be attached to stop signs whereas New York can use other sign types. Additionally, the first number in the top row will be '0' in Vermont, and not in New York.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Vermont Green Sign on Stop Signs"
    },
    {
        id: 14,
        question: "Which state has these white bollards with black reflectors?",
        answers: ["Wisconsin"],
        images: [
            images['us_wisconsin_bollard.png']
        ],
        full: "These simple white bollards with black reflectors are unique to Wisconsin.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Wisconsin Bollard"
    },
    {
        id: 15,
        question: "Which state most commonly has signs with two signposts?",
        answers: ["Michigan"],
        images: [
            images['us_michigan_double_signpost.png']
        ],
        full: "Signs attached to two signposts can be seen in Michigan. This is most common on speed limit and diamond warning signs. Not all signs are attached to two signposts. NOTE: Double signposts can also be seen in other states, but are not as common.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Michigan Double Signpost"
    },
    {
        id: 16,
        question: "In which state are these stop lights and signs hanging from wires most commonly found?",
        answers: ["Michigan"],
        images: [
            images['us_michigan_hanging_sign.png']
        ],
        full: "Many large intersections in Michigan have stop lights and signs that hang from wires that stretch across the middle of the intersection. These markers are commonly in yellow boxes. NOTE: These are not completely unique to Michigan, but are most commonly seen there.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Michigan Hanging Sign"
    },
    {
        id: 17,
        question: "Which state most commonly uses street numbers and arrows in addition to the street name?",
        answers: ["North Carolina"],
        images: [
            images['us_north_carolina_arrow_sign.png']
        ],
        full: "North Carolina commonly uses a style of street signs showing street numbers and arrows in addition to the street name. NOTE: Other states occasionally feature street signs with similar designs, so use other clues before guessing.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "North Carolina Arrow Sign"
    },
    {
        id: 18,
        question: "In which state is concrete pavement on main highways most commonly found?",
        answers: ["Iowa"],
        images: [
            images['us_iowa_concrete_highway.png']
        ],
        full: "Concrete paving on main highways is much more common in Iowa than in other states.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Iowa Concrete Highway"
    },
    {
        id: 19,
        question: "Which state sometimes features a black strip between the two yellow road lines?",
        answers: ["California"],
        images: [
            images['us_california_black_middle_line.png'], 
        ], 
        full: "A black stripe can sometimes be seen in between the two yellow, middle road lines in California.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "California Black Middle Line"
    },
    {
        id: 20,
        question: "Which state's roads often have a grainy, 'salt-and-pepper'-like road surface?",
        answers: ["Texas"],
        images: [ 
            images['us_texas_grainy_roads.png'], // has moving indicator
        ],
        full: "Texan roads often have a grainy, ‘salt and pepper’-like road surface. NOTE: Similar pavement can sometimes be seen in adjacent states such as New Mexico, Oklahoma, and Colorado.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "Texas Grainy Road"
    },
    {
        id: 21,
        question: "Which states use a lettering system for their country roads?",
        answers: ["Missouri", "Wisconsin"],
        images: [
            images["us_missouri_letter_county_road.png"]
        ],
        full: "Wisconsin and Missouri are the only two states that use a lettering system (A - ZZZ) for their county highways. Wisconsin signs typically have the word ‘county’ above the letter, while Missouri signs typically do not. The borders of the signs also vary slightly.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "MI/WI County Road Lettering System"
    },
    {
        id: 22,
        question: "Which states sometimes have thicker middle road lines?",
        answers: ['West Virginia', 'Virginia', 'Maryland'],
        images: [
            images['us_thick_middle_lines.png']
        ],
        full: "Some roads in West Virginia, Virginia, and Maryland use middle yellow lines that are thicker than traditional yellow road lines. NOTE: These are not present on all roads in these states, and can be found elsewhere, so use this tip in conjunction with other clues.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "WV/VA/MD Thick Road Lines"
    },
    {
        id: 23,
        question: "Which states often use the street naming system of 'number + cardinal direction', particularly in urban areas?",
        answers: ['Utah', 'Idaho'],
        images: [
            images['us_number_direction_street_name.png']
        ],
        full: "Streets in Utah and Idaho, particularly in urban areas, are often named by using a combination of a number and cardinal direction (i.e. ‘600 South.’). NOTE: This style of street naming can be found rarely in other Western states.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "UT/ID Street Naming"
    },
    {
        id: 24,
        question: "Which states use a red or green rectangular sign to mark the ends of bridges and guardrails?",
        answers: ['Maine', 'New Hampshire', 'Massachusetts'],
        images: [
            images['us_rectangle_bridge_sign.png']
        ],
        full: "Maine, New Hampshire, and Massachusetts use small green and red rectangular signs to mark the ends of bridges and guardrails. NOTE: Not all bridges and guardrails in these states will have these markers.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/united-states-2",
        short: "MN/NH/MA Rectangle Bridge Sign"
    },
    {
        id: 25,
        question: "Which states sometimes have a noticeably larger gap between yellow lines?",
        answers: ['Texas', 'Arkansas', 'Louisiana', 'Mississippi', 'Oregon', 'Washington', 'Alabama', 'Michigan'],
        images: [
            images['us_wide_middle_gap.png']
        ],
        full: 'Yellow lines with a noticeably larger gap in between them are most common in Texas, Arkansas, Louisiana, Mississippi, and Oregon. They can also be found to a lesser extent in Washington, Alabama, and Michigan. They can also rarely appear in other states, so consider other clues before guessing.',
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "Wide Middle Line Gap"

    },
    {
        id: 26,
        question: "Which states commonly use this pole plate?",
        answers: ['Wisconsin', 'Michigan'],
        images: [
            images['us_wisconsin_pole_plate.png'],
            images['us_wisconsin_pole_plate2.png']
        ],
        full: 'A rectangular white metal plate, with an orange rectangle and diamond, can be seen on most poles in Wisconsin and the Michigan peninsula.',
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "WI/MI Pole Plate"

    },
    {
        id: 27,
        question: "In which states are square guardrails most commonly used?",
        answers: ['New York', 'Wyoming'],
        images: [
            images['us_square_guardrail.png']
        ],
        full: "Square guardrails are the most dominant (but not the only) type of guardrail in the states of New York and Wyoming. They have a long bar across the top, with several square legs supporting them. These guardrails can be found, to a lesser extent, in some adjacent states.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "NY/WY Square Guardrail"
    },
    {
        id: 28,
        question: "In which states is this bollard with a diamond top mainly found?",
        answers: ['South Dakota', 'Montana', 'North Dakota', 'Wyoming', 'Nebraska'],
        images: [
            images['us_diamond_bollard.png']
        ],
        full: "The most common type of roadside bollard in South Dakota and Montana features a dark metal post with a white diamond on each side of the top.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',   
        short: "Diamond Bollard"     
    },
    {
        id: 29,
        question: "Which states have these rectangular raised pavement markers?",
        answers: ['Washington','Oregon','California','Arizona','New Mexico','Texas','Arkansas','Louisiana','Mississippi','Alabama','Georgia','Florida','South Carolina','Massachusetts'],
        images: [
            images['us_raised_pavement_marker.png']
        ],
        full: "These rectangular raised pavement markers are found in the south and west of the US and also in Massachusetts.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "Rectangular Raised Pavement Markers"    
    },
    {
        id: 30,
        question: "Which states have this snowplowable raised pavement marker on highways?",
        answers: ['Illinois','Indiana','Kentucky','Tennessee','Ohio','West Virginia','Virginia','Pennsylvania','North Carolina','New Jersey','Delaware','Maryland','District of Columbia'],
        images: [
            images['us_snowplowable_marker.png']
        ],
        full: "Snowplowable raised pavement markers that are built into the road and somewhat resemble the letter H can only be found on highways in these eastern states: Illinois, Indiana, Tennessee, Ohio, West Virginia, Virginia, Pennsylvania, North Carolina, New Jersey, Delaware, and Maryland. ",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "Snowplowable Raised Pavement Markers"
    },
    {
        id: 31,
        question: "Which state commonly has three yellow stripes at the bottom of poles?",
        answers: ['California'],
        images: [
            images['us_california_pole_stripes.png']
        ],
        full: "Utility poles in California usually have markings at the bottom of the pole that consist of 3 yellow stripes. NOTE: This can also be less commonly found in other states, such as <a target='_blank' href='https://www.google.com/maps/@44.3871255,-123.1097743,3a,90y,205.56h,79.52t/data=!3m7!1e1!3m5!1sNmHbCcHo1MsecqoS1a_KYA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DNmHbCcHo1MsecqoS1a_KYA%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D195.51118327273852%26pitch%3D1.9683847482186536%26thumbfov%3D133!7i16384!8i8192?coh=205409&entry=ttu'>Oregon</a>.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "California Poles"
    },
    {
        id: 32,
        question: "Which state uses red, green, or blue signs at the ends of driveways usually including town name and road name?",
        answers: ['Wisconsin', 'Michigan'],
        images: [
            images['us_wisconsin_driveway_sign.png']
        ],
        full: "A horizontal blue, red, or green sign can commonly be seen at the ends of driveways in Wisconsin and the Michigan peninsula. The house number is present, in addition to either the town name and or the road name (usually). NOTE: Nearby states often use similar markers, but typically lack any town or street name information and only feature numbers.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "Wisconsin Driveway Sign"
    },
    {
        id: 33,
        question: "Which states commonly have short rock walls in rural areas?",
        answers: ['Connecticut','Rhode Island','Massachusetts','New Hampshire'],
        images: [
            images['us_rock_walls.png']
        ],
        full: "In rural areas of Connecticut, Rhode Island, Massachusetts, and New Hampshire, short rock walls are commonly visible adjacent to the road. NOTE: These rock walls can be found rarely in adjacent states.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "New England Rock Walls"
    },
    {
        id: 34,
        question: "In which states are this Pueblo Revival style of architecture most commonly found?",
        answers: ['New Mexico','Arizona','Colorado'],
        images: [ 
            images['us_pueblo_revival_house.png'],
        ],
        full: "Pueblo Revival style houses are commonly seen in New Mexico, and Arizona and Colorado to a lesser extent. They are typically constructed of adobe or brick and concrete. They have rounded edges and flat roofs, and are usually sand-brown in colour.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.net/united-states-2',
        short: "Pueblo Revival Architecture"
    },
    {
        id: 35,
        question: "In which state is this green and white sign found?",
        answers: ['Illinois'],
        images: [
            images['us_illinois_sign.png'],
        ],
        full: "This sign is unique to Illinois. From what I can tell, the top number gives the national/state road.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Illinois Green Sign"
    },
    {
        id: 36,
        question: "Which state has these simple blue signs on posts?",
        answers: ['Indiana'],
        images: [
            images['us_indiana_blue_sign.png'],
        ],
        full: "This sign is unique to Indiana.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Indiana Blue Sign"
    
    },
    {
        id: 37,
        question: "Which state has these green plaques on overpass bridges?",
        answers: ['Mississippi'],
        images: [
            images['us_mississippi_overpass_bridge_plaque.png'],
        ],
        full: "Overpass bridges in Mississippi have green plaques with white numbers on them.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Mississippi Overpass Bridge Green Plaque"

    },
    {
        id: 38,
        question: "Which state uses yellow direction boxes for exits?",
        answers: ['Mississippi'],
        images: [
            images['us_mississippi_yellow_direction_box.png'],
        ],
        full: "This yellow direction box is only found in Mississippi.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Mississippi Yellow Direction Box"
    },
    {
        id: 39,
        question: "Which state uses this mile marker?",
        answers: ['Nevada'],
        images: [
            images['us_nevada_mile_marker.png'],
        ],
        full: "This mile marker is unique to Nevada. The middle part is an abbreviation of the county name - Nye in this case.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Nevada Mile Marker"
    },
    {
        id: 40,
        question: "Which state uses this style of marker on overpasses?",
        answers: ['Alabama'],
        images: [
            images['us_alabama_overpass_marker.png'],
        ],
        full: "In Alabama overpasses have these mile markers hanging from them.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Alabama Overpass Mile Marker"
    },
    {
        id: 41,
        question: "Which state tends to glue these stripes onto overpass support pillars?",
        answers: ['Alabama'],
        images: [
            images['us_alabama_overpass_stripes.png'],
        ],
        full: "Alabama also likes to glue these stripes to overpass support pillars.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Alabama Overpass Stripes"
    },
    {
        id: 42,
        question: "Which state uses this postal marker?",
        answers: ['California'],
        images: [
            images['us_california_postal_marker.png'], // not best quality image
        ],
        full: "California doesn't use mile markers, it instead uses postal markers that are white instead of green and have a county abbreviation.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "California Postal Marker",

    },
    {
        id: 43,
        question: "Which state uses the word 'parish' instead of 'county'?",
        answers: ['Louisiana'],
        images: [
            images['us_louisiana_parish.png'],
        ],
        full: "Louisiana doesn’t call its counties counties but uses the word parish instead. This is a relic of the French colonial past of the state. You might occasionally spot the word on an intersection sign, for example. The location is guaranteed to be in Louisiana because no other state uses the word parish in that way.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Louisiana Parishes",
    },
    // {
    //     id: 43,
    //     question: "Which state commonly uses these stickers on its poles?",
    //     answers: ['Hawaii'],
    //     images: [
    //         images['us_hawaii_pole_sticker.png'],
    //     ],
    //     full: "Hawaii commonly uses these yellow pole stickers. There might also be thinner lines on some occasions, making the stickers look like California ones. However, there’s always one very thick one which you wouldn’t see in California.",
    //     source: "A Unique USA",
    //     sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
    //     short: "Hawaii Yellow Pole Sticker"
    // },
    {
        id: 44,
        question: "Which state has thicker black stripes on these signs?",
        answers: ['Louisiana'],
        images: [  
            images['us_louisiana_thick_stripes.png'],
        ],
        full: "The black stripes on these signs are a bit thicker in Louisiana than in other states. The difference is noticeable when comparing this image to a <a target='_blank' href='https://www.google.com/maps/@37.8950441,-89.842569,3a,15y,28.19h,84.43t/data=!3m7!1e1!3m5!1sHjJJjJoYXMWoM1P-csaf4Q!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DHjJJjJoYXMWoM1P-csaf4Q%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D27.902039662364604%26pitch%3D5.505628603928827%26thumbfov%3D18!7i16384!8i8192?coh=205409&entry=ttu'>standard sign.</a>",
        // full: "The black stripes on these signs are a bit thicker in Louisiana than in other states. There will be four black sections as opposed to <a target='_blank' href='https://www.google.com/maps/@37.8950441,-89.842569,3a,15y,28.19h,84.43t/data=!3m7!1e1!3m5!1sHjJJjJoYXMWoM1P-csaf4Q!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DHjJJjJoYXMWoM1P-csaf4Q%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D27.902039662364604%26pitch%3D5.505628603928827%26thumbfov%3D18!7i16384!8i8192?coh=205409&entry=ttu'>six or more</a> in other states. Note that this only works for <a target='blank' href='https://www.google.com/maps/@39.586082,-76.080312,3a,17.5y,169.82h,74.05t/data=!3m5!1e1!3m3!1s3gbCOVVFYZ4VobIvVQwY3g!2e0!6shttps%3A%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3D3gbCOVVFYZ4VobIvVQwY3g%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D169.82128702231134%26pitch%3D15.952126660485376%26thumbfov%3D18?coh=205410'>signs of the standard size.</a>",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Louisiana Thick Stripes"
    },
    {
        id: 45,
        question: "Which state uses these unique interstate signs with a dividing line between numbers?",
        answers: ['New Hampshire'],
        images: [
            images['us_new_hampshire_interstate_marker.png'],
        ],
        full: "These signs should only be found in New Hampshire (notice the dividing line between the two numbers).",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "New Hampshire Interstate Sign",
    },
    {
        id: 46,
        question: "Which state uses these mile markers for US routes?",
        answers: ["New Hampshire"],
        images: [
            images['us_new_hampshire_us_route_marker.png'],
        ],
        full: "These US route mile markers are specific to New Hampshire.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "New Hampshire US Route Marker"
    },
    {
        id: 47,
        question: "Which state uses these black and white stripes on overpass supports?",
        answers: ['South Carolina'],
        images: [
            images['us_south_carolina_overpass_stripes.png'],
        ],
        full: "These black and white stripes on an overpass support are a dead giveaway that it’s South Carolina. Sometimes a <a target='_blank' href='https://www.google.com/maps/@34.293376,-81.546267,3a,45.6y,109.60h,87.33t/data=!3m5!1e1!3m3!1sL1PxdL9OviY_EE_dPJ4zEQ!2e0!6shttps%3A%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DL1PxdL9OviY_EE_dPJ4zEQ%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D109.60486718758354%26pitch%3D2.671681207185742%26thumbfov%3D46?coh=205410'>sign</a> with yellow and black stripes is used instead. Note that every overpass in South Carolina will also have <a target='_blank' href='https://www.google.com/maps/@33.3251306,-80.5546956,3a,19.1y,137.17h,98.62t/data=!3m7!1e1!3m5!1sz7EsMiKBZZqo7tjtOAShlw!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3Dz7EsMiKBZZqo7tjtOAShlw%26cb_client%3Dmaps_sv.share%26w%3D900%26h%3D600%26yaw%3D136.87473715375734%26pitch%3D-1.888600926761697%26thumbfov%3D81!7i16384!8i8192?coh=205409&entry=ttu'>clearance</a> posted.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "South Carolina Overpass Support Stripes"
    },
    {
        id: 48,
        question: "Which state uses this mile marker for state routers?",
        answers: ['Tennessee'],
        images: [
            images['us_tennessee_mile_marker.png'],
        ],
        full: "Tennessee state routes have these unique mile markers. The larger number is the mile (resets at county borders) and the smaller bottom one is the state route number.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Tennessee State Route Mile Markers"
    },
    {
        id: 49,
        question: "Which state uses this yellow license plate with a blue bar at the bottom?",
        answers: ['Alabama'],
        images: [
            images['us_alabama_plate.png'],
        ],
        full: "This plate has been in use since January 2022. It’s quite easy to see the blue strip at the bottom; the rest of the plate should be yellow. Alabama is written at the top in blue and it’s not very visible through the blur.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Alabama Yellow Plate"
    },
    {
        id: 50,
        question: "Which states use dark yellow license plates?",
        answers: ['Alaska', 'New York'],
        images: [
            images['us_yellow_plate.png'],
        ],
        full: "Alaska and New York use dark yellow license plates. Landscape should be enough to tell these apart, generally, but New York's plate also has a black bar at the top, which is sometimes noticeable.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Alaska/NY Dark Yellow Plates"
    },
    {
        id: 51,
        question: "The license plate of which state has hints of blue and yellow with purple at the bottom (these colors can vary based on lighting or angle)?",
        answers: ['Arizona'],
        images: [
            images['us_arizona_plate.png'],
        ],
        full: "The Arizona license plate might appear as purple or blue depending on the angle you’re looking at it from and the blur. Arizona has used the same plate design since the 90s so there is only a very slim chance to see an older red plate.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Arizona License Plate"
    },
    {
        id: 52,
        question: "Which states use license plates with this shade of blue at the top and white at the bottom?",
        answers: ['Connecticut', 'Illinois'],
        images: [
            images['us_connecticut_plate.png'],
        ],
        full: "Connecticut and Illinois use a blue-white plate, which look nearly identical when blurred.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "CT/IL Blue/White License Plate"
    },
    {
        id: 53,
        question: "This black and yellow license plate is used in which state?",
        answers: ['Delaware'],
        images: [
            images['us_delaware_plate.png'],
        ],
        full: "Delaware has been using the same plate design since 1962, which may appear as either mostly black or mostly yellow when blurred.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Delaware Black/Yellow License Plate"

    },
    {
        id: 54,
        question: "This license plate with green and an orange blob in the middle is used in which state?",
        answers: ['Florida'],
        images: [
            images['us_florida_plate.png'],
        ],
        full: "A Florida plate appears as white with an orange blob alongside a bit of green in the middle.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Florida License Plate"
    },
    {
        id: 55,
        question: "Which state has red, white, and blue stripes on its license plate (somewhat similar to the Dutch flag)?",
        answers: ['Idaho'],
        images: [
            images['us_idaho_plate.png'],
        ],
        full: "Idaho's license plate uses red, white, and blue stripes. This is a very distinctive plate and is easily recognizable from behind the blur.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Idaho License Plate"
    },
    {
       id: 56,
       question: "This license plate with a bar of blue at the left is found in which state?",
       answers: ['Indiana'],
         images: [
              images['us_indiana_plate.png'],
         ],
        full: "Indiana uses multiple different license plate designs. The most distinct one is based on the US flag and has a blue bar on the left.",   
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Indiana USA Flag License Plate" 
    },
    {
        id: 57,
        question: "License plates with blue at the top and yellow at the bottom with white in the middle are used in which states?",
        answers: ['Iowa', 'Pennsylvania'],
        images: [
            images['us_iowa_plate.png'],
        ],
        full: "Iowa and Pennsylvania use a blue-yellow plate design with a white section in the middle. In Iowa, the blue and yellow are both lighter, although the difference can be subtle. Additionally, Iowa uses front plates, while Pennsylvania does not.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "IA/PA Blue/Yellow License Plate"
    },
    {
        id: 58,
        question: "Which state uses plates with red text on a white background?",   
        answers: ['Massachusetts'],
        images: [
            images['us_massachusetts_plate.png'],
        ],
        full: "Massachusetts plates have red text on them. Thus, they look reddish when blurred.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Massachusetts Red Text License Plate"
    },
    {
        id: 59,
        question: "License plates that are blue and yellow without a white section in the middle are used in which state?",
        answers: ['Nevada'],
        images: [
            images['us_nevada_plate.png'],
        ],
        full: "This blue and yellow plate is used in Nevada. Note that there is no white section in the middle, distinguishing it from the Iowa and Pennsylvania plates.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Nevada Blue/Yellow License Plate"
    },
    {
        id: 60,
        question: "This noticeably green license plate is used in which state?",
        answers: ['New Hampshire'],
        images: [
            images['us_new_hampshire_plate.png'],
        ],
        full: "New Hampshire uses a green license plate. It is not as green as the plate used in neighboring Vermont.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "New Hampshire Green License Plate"
    },
    {
        id: 61,
        question: "This green license plate is used in which state?",
        answers: ['Vermont'],
        images: [
            images['us_vermont_plate.png'],
        ],
        full: "Vermont uses a green license plate. It is noticeably darker and more fully green than the New Hampshire plate.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Vermont Green License Plate"
    },
    {
        id: 62,
        question: "Light yellow license plates are found in which state?",
        answers: ['New Jersey'],
        images: [
            images['us_new_jersey_plate.png'],
        ],
        full: "New Jersey uses light yellow license plates. It’s important to memorize the shade of yellow because neighboring New York uses a darker shade of yellow (almost orange) on its plates. NJ plates are always a light shade of yellow.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "New Jersey Light Yellow License Plate" 
    },
    {
        id: 63,
        question: "Which state uses fully yellow or fully green license plates?",
        answers: ['New Mexico'],
        images: [
            images['us_new_mexico_plate.png'],
        ],
        full: "New Mexico uses fully yellow or fully green license plates. The yellow plate is a more bright yellow than New Jersey, New York, or Alaska. Landscape should help differentiate NM from other states with green plates, those being New Hampshire and Vermont.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "New Mexico Yellow/Green License Plate"
    },
    {
        id: 64,
        question: "Which state uses a white license plate with a green blob in the middle?",
        answers: ['Oregon'],
        images: [
            images['us_oregon_plate.png'],
        ],
        full: "Oregon uses a white license plate with a green tree in the middle, which is visible through the blur.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Oregon Green Tree License Plate"
    },
    {
        id: 65,
        question: "This license plate with brown at the bottom and blue at the top is used in which state?",
        answers: ['South Dakota'],  
        images: [
            images['us_south_dakota_plate.png'],
        ],
        full: "South Dakota uses a license plate depicting Mount Rushmore. This is visible as a brown section under a blue one through the blur.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "South Dakota Brown/Blue License Plate"
    },
    {
        id: 66,
        question: "This license plate depicting an orange object on a blue background is used in which state?",
        answers: ['Utah'],
        images: [
            images['us_utah_plate.png'],
        ],
        full: "Utah's license plate shows Delicate Arch in Arches National Park. The orange arch is visible on a blue background through the blur.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Utah Orange Arch License Plate"
    },
    {
        id: 67,
        question: "Which state uses a license plate that appears white with a blue and sometimes red tint at the bottom?",
        answers: ['Washington'],
        images: [
            images['us_washington_plate.png'],
        ],
        full: "Washington has used some form of this plate with a blueish mountain since 1987. Appears white with a blue and sometimes red tint.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Washington White/Blue License Plate"
    },
    {
        id: 68,
        question: "Which state uses a license plate appearing blue in the center with some yellow on the edges?",
        answers: ['Wyoming'],
        images: [
            images['us_wyoming_plate.png'],
        ],
        full: "Wyoming uses a license plate depicting the beach. The blur makes the plate appear to be blue in the center with some yellow on the edges.",
        source: "A Unique USA",
        sourceURL: "https://docs.google.com/document/d/11iDvKVU4REZMsDbHaXvQ776yyL9EmmCiW8urZeiF3wI/",
        short: "Wyoming Blue/Yellow License Plate"
    },
    {
        id: 69,
        question: "Which state exclusively uses the phone code 208?",
        answers: ['Idaho'],
        images: [
            images['us_idaho_code.png'],
        ],
        full: "The state of Idaho uses only the 208 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "208 Idaho Area Code"
    },
    {
        id: 70,
        question: "Which state excluively uses the phone code 406?",
        answers: ['Montana'],
        images: [
            images['us_montana_code.png'],
        ],
        full: "The state of Montana uses only the 406 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "406 Montana Area Code"
    },
    {
        id: 71,
        question: "Which state exclusively uses the phone code 307?",
        answers: ['Wyoming'],
        images: [
            images['us_wyoming_code.png'],
        ],
        full: "The state of Wyoming uses only the 307 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "307 Wyoming Area Code"
    },
    {
        id: 72,
        question: "Which state exclusively uses the phone code 701?",
        answers: ['North Dakota'],
        images: [
            images['us_north_dakota_code.png'],
        ],
        full: "The state of North Dakota uses only the 701 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "701 North Dakota Area Code"
    },
    {
        id: 73,
        question: "Which state exclusively uses the phone code 605?",
        answers: ['South Dakota'],
        images: [
            images['us_south_dakota_code.png'],
        ],
        full: "The state of South Dakota uses only the 605 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "605 South Dakota Area Code"
    },
    {
        id: 74,
        question: "Which state exclusively uses the phone code 302?",
        answers: ['Delaware'],
        images: [
            images['us_delaware_code.png'],
        ],
        full: "The state of Delaware uses only the 302 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "302 Delaware Area Code"
    },
    {
        id: 75,
        question: "Which state exclusively uses the phone code 401?",
        answers: ['Rhode Island'],
        images: [
            images['us_rhode_island_code.png'],
        ],
        full: "The state of Rhode Island uses only the 401 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "401 Rhode Island Area Code"
    },
    {
        id: 76,
        question: "Which state exclusively uses the phone code 802?",
        answers: ['Vermont'],
        images: [
            images['us_vermont_code.png'],
        ],
        full: "The state of Vermont uses only the 802 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "802 Vermont Area Code"
    },
    {
        id: 77,
        question: "Which state exclusively uses the phone code 603?",
        answers: ['New Hampshire'],
        images: [
            images['us_new_hampshire_code.png'],
        ],
        full: "The state of New Hampshire uses only the 603 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "603 New Hampshire Area Code"
    },
    {
        id: 78,
        question: "Which state exclusively uses the phone code 207?",
        answers: ['Maine'],
        images: [
            images['us_maine_code.png'],
        ],
        full: "The state of Maine uses only the 207 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "207 Maine Area Code"
    },
    {
        id: 79,
        question: "Which state exclusively uses the phone code 808?",
        answers: ['Hawaii'],
        images: [
            images['us_hawaii_code.png'],
        ],
        full: "The state of Hawaii uses only the 808 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "808 Hawaii Area Code"
    },
    {
        id: 80,
        question: "Which state exclusively uses the phone code 907?",
        answers: ['Alaska'],
        images: [
            images['us_alaska_code.png'],
        ],
        full: "The state of Alaska uses only the 907 area code.",
        source: "Plonk It Guide - Phone Codes",
        sourceURL: "https://www.plonkit.net/united-states",
        short: "907 Alaska Area Code"

    },
    
]

export const usSources = ['Plonk It Guide', 'A Unique USA', 'Plonk It Guide - Phone Codes'];
