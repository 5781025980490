import allImages from '../../images';
const images = allImages['my'];


export const myTips = [
    {
        id: 1,
        question: "What state uses the text 'TEL TNB' on the bottom of its black stickers?",
        answers: ['Johor'],
        images: [
            images['my_johor_sticker.png'],
        ],
        full: "Black stickers with the text ‘TEL TNB’ at the bottom, are only found in Johor.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Johor TEL TNB Sticker"
    },
    {
        id: 2,
        question: "In which states can you find Generation 4 coverage?",
        answers: ['Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Penang', 'Selangor', 'Terengganu'],
        images: [
            images['my_gen_4.png'],
        ],
        full: "Generation 4 coverage is only found in the peninsular part of Malaysia. The Borneo side features only Generation 3 coverage.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Mainland Generation 4 Coverage"
    },
    {
        id: 3,
        question: "In which state can you find this long white sticker on poles?",
        answers: ['Sarawak'],
        images: [
            images['my_sarawak_sticker.png'],
        ],
        full: "White pole stickers are only found on electricity poles on the island of Borneo, in Sarawak.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Sarawak White Pole Sticker"
    },
    {
        id: 4,
        question: "Black stickers on poles can be found in which states?",
        answers: ['Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Penang', 'Selangor', 'Terengganu'],
        images: [
            images['my_mainland_sticker.png'],
        ],
        full: "Black pole stickers are only found on electricity poles in mainland Malaysia.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Mainland Black Pole Sticker"
    },
    {
        id: 5,
        question: "Lamp posts with two small circles can be most commonly found in which states?",
        answers: ['Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Penang', 'Selangor', 'Terengganu'],
        images: [
            images['my_mainland_lamp_post.png'],
        ],
        full: "Lamp posts with two small circles on the support are mainly found on mainland Malaysia.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Mainland Lamp Post"
    },
    {
        id: 6,
        question: "Lamps with this triangle shaped support are most commonly found in which states?",
        answers: ['Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Penang', 'Selangor', 'Terengganu'],
        images: [
            images['my_triangle_support.png'],
        ],
        full: "Lamp posts with a triangular metal support are mainly found on mainland Malaysia.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Mainland Triangle Support"
    },
    {
        id: 7,
        question: "These poles with a 2/1 insulator configuration and a long diagonal wooden support are typical of which state?",
        answers: ['Sarawak'],
        images: [
            images['my_sarawak_pole.png'],
        ],
        full: "Electricity poles on the Borneo side typically have a 2-1 insulator configuration, and a long diagonal wooden support. They are exclusive to the Sarawak region of the island. NOTE: Indonesian poles can look similar, but they are usually not made of wood, and use smaller or no diagonal supports.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Sarawak Pole"
    },
    {
        id: 8,
        question: "These poles with two parallel pole tops are found in which state?",
        answers: ['Sabah'],
        images: [
            images['my_sabah_pole.png'],
        ],
        full: "Thin metal poles with two parallel pole tops are exclusive to the Sabah region of Borneo.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Sabah Pole"
    },
    {
        id: 9,
        question: "This lamp post with a boomerang-shaped support is typical of which state?",
        answers: ['Sarawak'],
        images: [
            images['my_sarawak_lamp.png'],
        ],
        full: "This lamp post with a supporting piece of sheet metal resembling a boomerang is found in Sarawak. NOTE: It is more common in cities other than Kuching.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Sarawak Boomerang Lamp Support"
    },
    {
        id: 10,
        question: "Trash bins with a logo resembling the letter S are found in which states?",
        answers: ['Malacca', 'Negeri Sembilan', 'Johor'],
        images: [
            images['my_malacca_bin.png'],
        ],
        full: 'Trash bins with a logo resembling an ‘S’ are found in the states of Negeri Sembilan, Malacca and Johor.',
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "South Mainland S Trash Bin"
    },
    {
        id: 11,
        question: "Trash bins with a logo resembling leaves are found in which states?",
        answers: ['Kedah', 'Perlis'],
        images: [
            images['my_kedah_bin.png'],
        ],
        full: "Trash bins with a logo resembling leaves are found in Perlis and Kedah.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "North Mainland Leaves Trash Bin"
    },
    {
        id: 12,
        question: "Trash bins with the words 'Alam Flora', sometimes featuring a logo with a sunny landscape as shown, are found in which states?",
        answers: ['Kuala Lumpur', 'Putrajaya', 'Pahang'],
        images: [
            images['my_kl_bin.png'],
        ],
        full: "Trash bins with this logo featuring a sunny landscape, saying ‘Alam Flora’ are found in Pahang, Kuala Lumpur and Putrajaya. The logo will occasionally be absent, and it will just say ‘Alam Flora’.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Central Mainalnd Alam Flora Trash Bin"
    },
    {
       id: 13,
       question: "Yellow street signs are exclusive to which state?",
       answers: ['Johor'],
        images: [
            images['my_johor_sign.png'],
        ],
        full: "Yellow street name signs are specific to the state of Johor. NOTE: This arrow-shaped street sign, regardless of its colour, is exclusive to Malaysia.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Johor Yellow Street Sign"
    },
    {
        id: 14,
        question: "Green street signs in an arrow shape with a yellow hexagonal symbol on the left (saying KJR) are found in which state?",
        answers: ['Sabah'],
        images: [
            images['my_sabah_sign.png'],
        ],
        full: "Green street signs that form an arrow shape and have a yellow hexagonal symbol with the letters JKR on the left side are found in Sabah.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Sabah Green Arrow Street Sign"
    },
    {
        id: 15,
        question: "Within Malaysian Borneo, which state is generally more mountainous?",
        answers: ['Sabah'],
        images: [
            images['my_sabah_mountain.png'],
        ],
        full: "Assuming you are on Borneo: the state of Sabah is very mountainous, and much of the coverage is on mountain roads like this one. NOTE: Sarawak generally has flatter roads. Even if you see mountains, they will usually be at a distance. ",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Sabah Elevation"
    },
    {
        id: 16,
        question: "Isolated rock formations, such as those shown, in an otherwise flat landscape can be found in which state?",
        answers: ['Perlis'],
        images: [
            images['my_perlis_rock_formation.png'],
        ],
        full: "These isolated rock formations, rising abruptly from an otherwise flat landscape, can be found near the Thai border in Perlis state.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/malaysia",
        short: "Perlis Rock Formation"
    },
    {
        id: 17,
        question: "If you see a bollard, you are almost always be in which states?",
        answers: ['Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Penang', 'Selangor', 'Terengganu'],
        images: [
            images['my_mainland_bollard.png'],
        ],
        full: "Bollards are almost always Peninsula!",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Mainland Bollard"
    },
    {
        id: 18,
        question: "Black car in generation 3 coverage is generally most common in which states?",
        answers: ['Sarawak', 'Johor', 'Malacca'],
        images: [
            images['my_black_car.png'],
        ],
        full: "Black car is generally found in the south of the country. This applies to mainland and Borneo, where it does not exist in Sabah but does in Sarawak. In mainland, Malacca is over 95% black car, while Johor is over 80%.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Southern Black Car"

    },
    {
        id: 19,
        question: "Rifts are most common in which states?",
        answers: ['Kedah', 'Penang'],
        images: [
            images['my_kedah_rift.png'],
        ],
        full: "Larger sky rifts are most common in Kedah and sometimes found in Penang.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Kedah Rift"
    
    },
    {
        id: 20,
        question: "Black stickers with TEL: 15454 are most common in which states?",
        answers: ['Johor', 'Malacca', 'Pahang'],
        images: [
            images['my_tel_sticker.png'],
        ],
        full: "Phone numbers are significantly more common south than north. They are most common in the states of Johor, Malacca, and Pahang, but can also be found somewhat commonly in Selangor, Kelantan, and Perlis.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Southern TEL: 15454 Black Sticker"
    },
    {
        id: 21,
        question: "Black stickers with the phone number 15454 and a lightbulb symbol, as well as usually being placed higher on the pole, being square shaped, and having a unique Times New Roman font can be found in which state?",
        answers: ['Malacca'],
        images: [
            images['my_malacca_sticker.png'],
        ],
        full: "Malacca stickers are the easiest to identify. They tend to be placed higher on the poles, they always have both the phone number and the lightbulb, and they also often have a Times New Roman font which is unique. The sticker is also normally square.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Malacca 15454 Lightbulb Black Sticker"

    },
    {
        id: 22,
        question: "Black stickers that are longer and feature a plain red bulb without the red and blue paint are typical of which state?",
        answers: ['Perlis'],
        images: [
            images['my_perlis_sticker.png'],
        ],
        full: "In Perlis the black stickers are regularly longer and often feature a plain red bulb (without red and blue paint).",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Perlis Long Bulb Black Sticker"

    },
    {
        id: 23,
        question: "This green rounded street sign is unique to a major city in which state?",
        answers: ['Sarawak'],
        images: [
            images['my_kuching_sign.png'],
        ],
        full: "This green rounded street sign is unique to Kuching, the capital of Sarawak.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Kuching Green Rounded Street Sign"
    },
    {
        id: 24,
        question: "This pole with three levels of thickness are most common in which state?",
        answers: ['Sabah'],
        images: [
            images['my_sabah_pole2.png'],
        ],
        full: "Sabah often uses these thin metal poles with 3 levels of thickness. They are not exclusive to Sabah, but are rare in Sarawak.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Sabah Three Level Pole"
    },
    {
        id: 25,
        question: "Black stickers with red and blue paint under them are common in which states?",
        answers: ['Malacca', 'Selangor', 'Perak', 'Kuala Lumpur', 'Putrajaya'],
        images: [
            images['my_rb_paint.png'],
        ],
        full: "This paint, common in Malacca, Perak, Selangor, KL, and Putrajaya can be found on the sticker or under. This is most useful for Perak which, unlike many states that look similar, frequently has the paint underneath the sticker. In Malacca almost every sticker has r+b on it. Sometimes colors other than red are used, but one of the two is almost always present.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Western Red Blue Paint Black Sticker"
    },
    {
        id: 26,
        question: "This lightbulb on a black sticker is most common in which states?",
        answers: ['Malacca', 'Selangor', 'Perlis', 'Kuala Lumpur', 'Putrajaya'],
        images: [
            images['my_lightbulb_sticker.png'],
        ],
        full: "The lightbulb, found commonly in Malacca, Perlis, Selangor, KL, and Putrajaya, is useful for the Perlis / Kedah 50/50. It's also more common in Generation 4.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Western Lightbulb Black Sticker"
    },
    // could possibly delete tip 27, bc it's not super helpful/consistent
    {
        id: 27,
        question: "States which most commonly use 3 characters in the first line of their black stickers include?",
        answers: ['Perlis', 'Kedah', 'Kelantan', 'Terengganu'],
        images: [
            images['my_3char_sticker.png'],
        ],
        full: "States which most commonly use 3 characters in the first line of their black stickers include Perlis, Kedah, Kelantan, and Terengganu. Of these, Perlis and Kedah also commonly use 2 characters, while Kelantan and Terengganu will mostly only use 3. This should be used alongside other clues, as it is relatively inconsistent.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Northern 3 Char Black Sticker"
    },
    {
        id: 28,
        question: "In which states is it most common to find letters after the 1st line of the text on black stickers?",
        answers: ['Perlis', 'Kedah', 'Kelantan', 'Terengganu', 'Pahang'],
        images: [
            images['my_2ndlineletter_sticker.png'],
        ],
        full: "The general trend here is that the further north you go, the more likely you are to see a letter after Line 1. This is most common in Perlis, Kedah, Kelantan, Terengganu, and Pahang. This should be used alongside other clues. Note: This does not include letters that follow numbers, e.g. '5a'.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Northern 2nd Line Letter Black Sticker"
    },
    {
        id: 29,
        question: "These yellow and black traffic lights with holes are found in which state?",
        answers: ['Sarawak'],
        images: [
            images['my_sarawak_traffic_light.png'],
        ],
        full: "Traffic lights with the black and yellow and holes are Sarawak-specific. Size of the holes differs from city to city.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Sarawak Traffic Light"
    },
    {
        id: 30,
        question: "This trash bin design is found in which state?",
        answers: ['Sarawak'],
        images: [
            images['my_sarawak_bin.png'],
        ],
        full: "This is the generic traffic bin for Sarawak, most common in Kuching but found elsewhere throughout the state. The design can vary, but this is the most common one.",
        source: "The Malaysia Doc",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Sarawak Trash Bin"
    },
    {
        id: 31,
        question: "This is the state flag of which state?",
        answers: ['Perlis'],
        images: [
            images['my_perlis_flag.png'],
        ],
        full: "The flag of Perlis is yellow and blue, somewhat resembling an upside-down flag of Ukraine.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Perlis Flag"
    },
    {
        id: 32,
        question: "This is the state flag of which state?",
        answers: ['Kedah'],
        images: [
            images['my_kedah_flag.png'],
        ],
        full: "The flag of Kedah is red with its coat of arms in the top left corner.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Kedah Flag"
    },
    {
        id: 33,
        question: "This is the state flag of which state?",
        answers: ['Penang'],
        images: [
            images['my_penang_flag.png'],
        ],
        full: "The flag of Penang is a vertical tricolor of blue-white-yellow (reversed here) and features a palm tree in the middle.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Penang Flag"
    },
    {
        id: 34,
        question: "This is the state flag of which state?",
        answers: ['Perak'],
        images: [
            images['my_perak_flag.png'],
        ],
        full: "The flag of Perak is a horizontal tricolor of white, yellow, and black.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Perak Flag"
    },
    {
        id: 35,
        question: "This is the state flag of which state?",
        answers: ['Kelantan'],
        images: [
            images['my_kelantan_flag.png'],
        ],
        full: "The flag of Kelantan is red with a symbol derived from the state's coat of arms in the middle.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Kelantan Flag"
    },
    {
        id: 36,
        question: "This is the state flag of which state?",
        answers: ['Terengganu'],
        images: [
            images['my_terengganu_flag.png'],
        ],
        full: "The flag of Terengganu is black with a thick white border and features a white star and crescent in the center (the flag is reversed here).",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Terengganu Flag"
    },
    {
        id: 37,
        question: "This is the state flag of which state?",
        answers: ['Pahang'],
        images: [
            images['my_pahang_flag.png'],
        ],
        full: "The flag of Pahang is comprised of a white and black horizontal band.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Pahang Flag"
    },
    {
        id: 38,
        question: "This is the state flag of which state?",
        answers: ['Selangor', 'Kuala Lumpur', 'Putrajaya'],
        images: [
            images['my_selangor_flag.png'],
        ],
        full: "The flag of Selangor features four rectangles, two red, and two yellow. The upper left corner features a white crescent and star. This flag is also used by Kuala Lumpur and Putrajaya.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Selangor Flag"
    },
    {
        id: 39,
        question: "This is the state flag of which state?",
        answers: ['Negeri Sembilan'],
        images: [
            images['my_negeri_sembilan_flag.png'],
        ],
        full: "The flag of Negeri Sembilan is yellow with a black and red square in the top left corner.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Negeri Sembilan Flag"
    },
    {
        id: 40,
        question: "This is the state flag of which state?",
        answers: ['Malacca'],
        images: [
            images['my_malacca_flag.png'],
        ],
        full: "The flag of Malacca features a blue section with a yellow star and crescent, then a red and white horizontal stripe.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Malacca Flag"
    },
    {
        id: 41,
        question: "This is the state flag of which state?",
        answers: ['Johor'],
        images: [
            images['my_johor_flag.png'],
        ],
        full: "The flag of Johor is blue with a red section in the top left corner, featuring a white crescent and star.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Johor Flag"
    },
    {
        id: 42,
        question: "This is the state flag of which state?",
        answers: ['Sarawak'],
        images: [
            images['my_sarawak_flag.png'],
        ],
        full: "The flag of Sarawak is yellow with a red and black diagonal stripe (being the only state flag to have diagonal stripes), and a yellow star in the middle.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Sarawak Flag"
    },
    {
        id: 43,
        question: "This is the state flag of which state?",
        answers: ['Sabah'],
        images: [
            images['my_sabah_flag.png'],
        ],
        full: "The flag of Sabah has horizontal stripes of blue, white, and red, with a silhouette of Mount Kinabalu in the center.",
        source: "The Malaysia Doc - State Flags",
        sourceURL: "https://docs.google.com/document/d/1BYu38SKT5Gej-jjHZ0cESvmRGPGT1KqaBpRgQa3Ctz0",
        short: "Sabah Flag"
    },
]

let mainland = ['Johor', 'Kedah', 'Kelantan', 'Malacca', 'Negeri Sembilan', 'Pahang', 'Perak', 'Perlis', 'Penang', 'Selangor', 'Terengganu'];


export const mySources = ['Plonk It Guide', 'The Malaysia Doc', 'The Malaysia Doc - State Flags']