import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MapLoader = ({ googleMapsApiKey, setGoogleLoaded, setErrorLoading }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isApiLoaded = typeof window.google !== 'undefined';

    if (!isApiLoaded) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&v=weekly`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        console.log('Google Maps API successfully loaded.');
        setGoogleLoaded(true);
        setErrorLoading(false);
        
        if (window.initMap) {
          window.initMap();
        }
        
        const currentPath = window.location.pathname;
        navigate(currentPath);
      };

      script.onerror = () => {
        console.error('Error loading Google Maps script.');
        setErrorLoading(true);
      };

      document.head.appendChild(script);
    } else {
      setGoogleLoaded(true);
    }

    return () => {
      const scriptTag = document.querySelector(`script[src*="maps.googleapis.com"]`);
      if (scriptTag) {
        document.head.removeChild(scriptTag);
      }
    };
  }, [googleMapsApiKey, navigate, setGoogleLoaded, setErrorLoading]);

  return null; 
};

export default MapLoader;
