import allImages from '../../images';
const images = allImages['id'];

export const idTips = [
    {
        id: 1,
        question: "Which provinces most commonly use this triangle attachment under the horizontal bar in its poles?",
        answers: ['West Java', 'Riau', 'Banten', 'South Sumatra'],
        images: [
            images['id_wjava_triangle.png'],
        ],
        full: "You can find these poles with a triangle attachment under the horizontal bar in West Java & Banten, and to a lesser extent Riau and South Sumatra.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "West Java (& Others) Triangle Attachment"
    },
    {
        id: 2,
        question: "Which province uses this black-white-yellow square bollards?",
        answers: ['Central Sulawesi'],
        images: [
            images['id_csula_bollard.png'],
        ],
        full: "Central Sulawesi features these unique bollards that are painted black on the bottom, white in the middle, and yellow on the top.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Central Sulawesi Bollards"
    },
    {
        id: 3,
        question: "These painted fences are typically found in which region?",
        answers: ['North Sulawesi', 'Central Sulawesi', 'West Sulawesi', 'South Sulawesi', 'Southeast Sulawesi', 'Gorontalo'],
        images: [
            images['id_sula_fences.png'],
        ],
        full: "These painted fences are typically found on Sulawesi. They will be mostly painted white with blue or red.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Sulawesi Painted Fences"
    },
    {
        id: 4,
        question: "Bridges with green paint on the sides are found in which provinces?",
        answers: ['South Kalimantan', 'Central Kalimantan'],
        images: [
            images['id_kali_bridge.png'],
        ],
        full: "Green paint can often be seen on the side of bridges in South and Central Kalimantan.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Kalimantan Bridge Green Paint"

    },
    {
        id: 5,
        question: "Oil plantations are typically found in which provinces?",
        answers: ['Riau', 'North Sumatra', 'South Sumatra', 'Aceh', 'West Sumatra', 'Bengkulu', 'Jambi', 'Lampung', 'Bangka-Belitung Islands', 'Riau Islands', 'South Kalimantan', 'Central Kalimantan', 'East Kalimantan', 'West Kalimantan', 'North Kalimantan'],
        images: [
            images['id_oil_plantation.png'],
        ],
        full: "Within Indonesia, oil palm plantations are mostly concentrated in Sumatra and Kalimantan. Palm plantations can rarely be seen in Sulawesi.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Sumatra/Kalimantan Oil Plantations"
    },
    {
        id: 6,
        question: "Which provinces have the most concentrated rice production?",
        answers: ['West Java', 'Central Java', 'East Java', 'Special Region of Yogyakarta', 'Banten', 'Jakarta', 'South Sumatra', 'South Sulawesi'],
        images: [
            images['id_rice_field.png'],
        ],
        full: "The majority of rice production is concentrated in Java, but can sometimes be found in other provinces, namely South Sulawesi and South Sumatra.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Java Rice Production"
    },
    {
        id: 7,
        question: "Which provinces are considerably dryer than the rest of Indonesia?",
        answers: ['West Nusa Tenggara', 'East Nusa Tenggara'],
        images: [
            images['id_nusas_dryness.png'],
        ],
        full: "The Nusas typically look drier than other provinces in Indonesia. The islands get especially dry towards the east, especially in Generation 3. Vegetation tends to look less tropical, but instead more brown with a lack of leaves. Sumba Island is also the driest of the Nusas. NOTE: While less common, other provinces such as Java can look similarly dry. Make sure to look out for other clues before guessing.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Nusa Islands Dryness"
    },
    {
        id: 8,
        question: "The north of which island can have this savannah-like landscape as well as a white car in Generation 3 coverage?",
        answers: ['East Nusa Tenggara'],
        images: [
            images['id_sumba_savannah.png'],
        ],
        full: "This savannah landscape is occasionally seen on North Sumba. NOTE: In Generation 3, you will always see a white car.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Sumba Savannah + White Car"
    },
    {
        id: 9,
        question: "Red soil can be often found in which province?",
        answers: ['Bangka-Belitung Islands'],
        images: [
            images['id_bangka_red_soil.png'],
        ],
        full: "Red soil can often be found on Batam and the Banga Belitung islands, located east of mainland Sumatra.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Bangka=Belitung Red Soil"
    },
    {
        id: 10,
        question: "Tiled roofs are most commonly found in which region?",
        answers: ['West Java', 'Central Java', 'East Java', 'Special Region of Yogyakarta', 'Banten', 'Jakarta'],
        images: [
            images['id_java_tiled_roofs.png'],
        ],
        full: "Tiled roofs are most commonly found on the island of Java, and sometimes nearby provinces such as southern Sumatra, the Lesser Sunda Islands, and South Kalimantan. NOTE: The tiles on roofs in South Kalimantan and Jambi look slightly different. The tiles are usually less rounded but flatter.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Java Tiled Roofs"
    },
    {
        id: 11,
        question: "Sheet metal roofs are most commonly found in which provinces?",
        answers: ['South Kalimantan', 'Central Kalimantan', 'East Kalimantan', 'West Kalimantan', 'North Kalimantan', 'Riau', 'North Sumatra', 'South Sumatra', 'Aceh', 'West Sumatra', 'Bengkulu', 'Jambi', 'Lampung', 'Bangka-Belitung Islands', 'Riau Islands', 'North Sulawesi', 'Central Sulawesi', 'West Sulawesi', 'South Sulawesi', 'Southeast Sulawesi', 'Gorontalo', 'West Nusa Tenggara', 'East Nusa Tenggara', 'Bali', 'West Papua', 'Papua', 'Maluku', 'North Maluku'],
        images: [
            images['id_metal_roof.png'],
        ],
        full: "Plain sheet metal roofs are more commonly found toward the north, where tiled roofs are not found. They are most commonly found on Kalimantan, Sulawesi and Sumatra.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Non-Java Sheet Metal Roofs"
    },
    {
        id: 12,
        question: "These roofs with horizontal slots inside the triangles are most commonly found in which province?",
        answers: ['South Sulawesi'],
        images: [
            images['id_ssula_roof.png'],
        ],
        full: "These horizontal slots inside the triangles of the roofs are almost unique to Southern Sulawesi. NOTE: They can rarely be found in East Kalimantan and West Nusa.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "South Sulawesi Roof Slots"
    },
    {
        id: 13,
        question: "Uniquely high-pitched roofs are found in which province?",
        answers: ['East Nusa Tenggara'],
        images: [
            images['id_sumba_roof.png'],
        ],
        full: "You can find these distinct roofs with uniquely high-pitched peaks on Sumba.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Sumba High-Pitched Roofs"
    },
    {
        id: 14,
        question: "A unique style of architecture with crown-like roof pinnacles and guardian statues is found in which province?",
        answers: ['Bali'],
        images: [
            images['id_bali_architecture.png'],
        ],
        full: "Architecture in Bali is unique because it was influenced by old Balinese culture and Hinduism. The most important thing to look for are these crown-like roof pinnacles, found everywhere in Bali. Guardian statues and slit-drum towers are common too. Andesite, a dark, volcanic rock, is a common building material in Bali. You can often see gates, walls, and other intricately carved ornaments built from this material.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Bali Architecture"
    },
    {
        id: 15,
        question: "These distinct boat-shaped roofs with spires can be found in which province?",
        answers: ['West Sumatra'],
        images: [
            images['id_wsuma_roof.png'],
        ],
        full: "West Sumatra features these distinct boat-shaped roofs. They are similar to the ones found in North Sumatra, but the ones here will be more angled upward and feature spires.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "West Sumatra Boat Roofs"
    },
    {
        id: 16,
        question: "These boat-shaped roofs without spires are found in which province?",
        answers: ['North Sumatra'],
        images: [
            images['id_nsuma_roof.png'],
        ],
        full: "North Sumatra features distinct boat-shaped roofs similar to the ones in West Sumatra, however the roofs are less angled upward and they do not feature spires. NOTE: These roofs are most commonly found near Lake Toba, due to its sacred history.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "North Sumatra Boat Roofs"
    },
    {
        id: 17,
        question: "Roofs with wooden chips are found in which provinces?",
        answers: ['Central Kalimantan', 'East Kalimantan', 'South Kalimantan'],
        images: [
            images['id_kali_roof.png'],
        ],
        full: "On Kalimantan (typically Central, East, and South), you can find these unique roofs made out of wooden chips.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Kalimantan Wooden Chip Roofs"
    },
    {
        id: 18,
        question: "Gable horns are most commonly found on roofs in which provinces?",
        answers: ['South Sulawesi', 'Riau', 'Central Kalimantan', 'South Kalimantan'],
        images: [
            images['id_gable_horns.png'],
        ],
        full: "Roofs with gable horns can be found most commonly in South Sulawesi, Riau and Central and South Kalimantan. NOTE: Horned roofs are often found with Bugis roofs in South Sulawesi. Use this tip along with other clues to determine which province you are in.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Roof Gable Horns"

    },
    {
        id: 19,
        question: "Generation 4 coverage in which province can have a hazy view on one side and a clear view on the other?",
        answers: ['North Kalimantan'],
        images: [
            images['id_nkali_haze.png'],
        ],
        full: "In North Kalimantan, you can sometimes see a one-sided blur, having a clear view on one side but a hazy view on the other. (This image shows the hazy side, <a href='https://maps.app.goo.gl/zCEccEkGrP8fxSRG9'>here</a> is the clear side.) NOTE: This blur can only be seen in Generation 4. Similar blurs in Generation 3 can be seen elsewhere around Kalimantan.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "North Kalimantan Haze"
    },
    {
        id: 20,
        question: "A blurry smudge can be seen on some roads in which province?",
        answers: ['South Sulawesi'],
        images: [
            images['id_ssula_smudge.png'],
        ],
        full: "A blurry smudge can be seen on many of these roads in southern Sulawesi. This is found in July 2022 coverage, which may have been updated in some parts.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "South Sulawesi Smudge"
    },
    {
        id: 21,
        question: "Generation 3 antenna coverage is most common in which region?",
        answers: ['East Java', 'Special Region of Yogyakarta', 'Banten', 'Jakarta', 'West Java', 'Central Java'],
        images: [
            images['id_java_antenna.png'],
        ],
        full: "Generation 3 antenna coverage is most commonly found on Java, but can very rarely be seen on Bali in and around Denpasar. It is most commonly seen in East Java, as most of the antenna coverage in the west has been overwritten by Generation 4.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Java Antenna Coverage"
    },
    {
        id: 22,
        question: "Generation 3 black car coverage is most common in which region?",
        answers: ['North Sulawesi', 'Central Sulawesi', 'West Sulawesi', 'South Sulawesi', 'Southeast Sulawesi', 'Gorontalo'],
        images: [
            images['id_sula_black_car.png'],
        ],
        full: "Black Generation 3 coverage can be found almost everywhere in Indonesia, but is most common in Sulawesi.",
        source: "Plonk It Guide",
        sourceURL: "https://www.plonkit.net/indonesia",
        short: "Sulawesi Black Car Coverage"
    },
    {
        id: 23,
        question: "This pole with a triangle connector and a rod at a 135 degree angle is typical of which province?",
        answers: ['West Java', 'Banten', 'Jakarta'],
        images: [
            images['id_wjava_pole.png'],
        ],
        full: "Western Java’s poles often have triangle connectors, a rod with a 135º angle, and/or a large indentation resembling those of poles found in Chile. Note that triangle connectors may be found elsewhere in Indonesia.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_0",
        short: "West Java 135 Deg Pole"
    },
    {
        id: 24,
        question: "Poles with an inverted triangle top and only one layer are typical of which province?",
        answers: ['West Java', 'Banten', 'Jakarta'],
        images: [
            images['id_wjava_pole2.png'],
        ],
        full: "In West Java,a pole may sometimes only have this inverted triangle pole top, but when there is a second layer below it, the previous rules regarding triangle connectors apply. These poles with multiple layers are also found in Aceh and North Sumatra.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e49780169a_0_17",
        short: "West Java Inverted Triangle Pole Top"
    },
    {
        id: 25,
        question: "This pole with no crossbar and a single insulator is most common in which province?",
        answers: ['Central Java', 'Special Region of Yogyakarta'],
        images: [
            images['id_cjava_pole.png'],
        ],
        full: "Key characteristics of a Central Java pole include no crossbar and a single insulator at the top. It may occasionally have two insulators, reflecting how all types of poles 'double up' from time to time.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_82",
        short: "Central Java Single Insulator Pole"

    },
    {
        id: 26,
        question: "This pole with insulators making an upside down T shape is found most commonly in which provinces?",
        answers: ['Central Java', 'Special Region of Yogyakarta'],
        images: [
            images['id_cjava_pole2.png'],
        ],
        full: "These poles where insulators make an upside-down T-shape are found in Central Java. A second pole top underneath is most common in West Kalimantan, but can also be seen in Central Java and elsewhere.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e49780169a_0_50",
        short: "Central Java T-Shaped Insulators"
    },
    {
        id: 27,
        question: "This symmetric trident pole is mostly found in which provinces?",
        answers: ['Central Java', 'Special Region of Yogyakarta'],
        images: [
            images['id_cjava_pole3.png'],
        ],
        full: "Symmetric trident poles (where the pole rises higher than the crossbar and insulators form a trident-like shape) is mostly limited to Central Java and Yogyakarta. Exceptions can be found in West, Central, and South Kalimantan.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e49780169a_0_40",
        short: "Central Java Trident Pole"
    },
    {
        id: 28,
        question: "This pole top, when placed on top of a generic concrete pole instead of on another pole top, is most common in which provinces?",
        answers: ['Central Java', 'Special Region of Yogyakarta'],
        images: [
            images['id_cjava_pole4.png'],
        ],
        full: "This pole top features a metal extension sitting on top of another pole, held by two vertical beams, which may or may not include support beams. In Central Java and Yogyakarta, this generally sits atop a featureless concrete pole, but provinces like West Sumatra frequently use this extension atop a provinceal pole top.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e49780169a_0_45",
        short: "Central Java Metal Extension Pole Top"
    },
    {
        id: 29,
        question: "Which province mostly uses generic asymmetric poles, but can also have this variant where the pole extends far above the crossbar in a 'bulge'?",
        answers: ['East Java'],
        images: [
            images['id_ejava_pole.png'],
        ],
        full: "East Java mostly uses generic asymmetric poles (the only province in Java to do so), similar to the dominant design in Kalimantan and Southern Sumatra, but also uses a variant that extends the pole far above the crossbar in a “bulge”, as shown. When spotting a “bulging” asymmetric pole, there is well over a 90% chance that it is in East Java. However, they can be found in and around South Kalimantan, notably in the fields around Banjarmasin.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2ea3f80af2e_0_6",
        short: "East Java Bulging Asymmetric Pole"
    },
    {
        id: 30,
        question: "This asymmetrical pole, sometimes with the short horizontal metal beam at the bottom, is standard in which provinces?",
        answers: ['Central Kalimantan', 'East Kalimantan', 'South Kalimantan', 'North Kalimantan'],
        images: [
            images['id_kali_pole.png'],
        ],
        full: "Kalimantan, bar West Kalimantan, uses generic asymmetric poles. Some have this short horizontal metal beam.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_567",
        short: "Kalimantan Asymmetric Pole"
    },
    {
        id: 31,
        question: "Support beams in which province use these purple and green bands?",
        answers: ['Central Kalimantan'],
        images: [
            images['id_ckali_band.png'],
        ],
        full: "Some support poles along the highways of Kalimantan have colorful bands. Central Kalimantan uses purple and green, and East Kalimantan uses a distinctly lighter pink and a similar shade of green. See the source for specific roads this is applicable to, as exceptions can exist outside of them.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e51e1c6a66_1_71",
        short: "Central Kalimantan Purple/Green Beam Bands"
    },
    {
        id: 32,
        question: "Support beams in which province use these pink and green bands?",
        answers: ['East Kalimantan'],
        images: [
            images['id_ekali_band.png'],
        ],
        full: "Some support poles along the highways of Kalimantan have colorful bands. East Kalimantan uses pink and green, and Central Kalimantan uses a distinctly darker purple and a similar shade of green. See the source for specific roads this is applicable to, as exceptions can exist outside of them.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e51e1c6a66_1_71",
        short: "East Kalimantan Pink/Green Beam Bands"
    },
    {
        id: 33,
        question: "Generally, in which provinces can you commonly find asymmetrical poles with two support beams?",
        answers: ['Aceh', 'North Sumatra', 'Riau', 'Jambi', 'South Sumatra', 'Bangka-Belitung Islands', 'Bengkulu', 'Lampung', 'West Sulawesi', 'South Sulawesi', 'Southeast Sulawesi'],
        images: [
            images['id_asym_2_support.png'],
        ],
        full: "Asymmetrical poles with one support beam can be found in most of Sumatra, Sulawesi, and Kalimantan. However, Kalimantan will never have an asymmetric pole with two supports, so avoid sending there if you see one.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e49780169a_0_60",
        short: "Asymmetrical Pole with 2 Supports"
    },
    {
        id: 34,
        question: "This metal pole with two support beams at 45 degree angles and a symmetric design is typical of which province?",
        answers: ['West Kalimantan'],
        images: [
            images['id_wkali_pole.png'],
        ],
        full: "This is the standard simple symmetric design for West Kalimantan poles. They can occasionally be made out of concrete and/or have only one support beam, but this is uncommon.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_572",
        short: "West Kalimantan Symmetric 2-Support Pole"
    },
    {
        id: 35,
        question: "Which provinces use this pole with long support beams spanning half the length of the entire cross bar?",
        answers: ['East Nusa Tenggara', 'West Nusa Tenggara', 'Bali'],
        images: [
            images['id_nusas_pole.png'],
        ],
        full: "These are “Nusa poles”: Long support beams, usually spanning half the length of the crossbar. Concrete poles are more common in West Nusa and Bali; Metal poles are more common in East Nusa. There are many provinceal variants on the Nusas poles, which are covered in other tips.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_87",
        short: "Nusa Poles"

    },
    {
        id: 36,
        question: "This Nusas pole variant has an extended horizontal beam at the base of the support beam(s) and is most common in which province?",
        answers: ['South Sumatra'],
        images: [
            images['id_ssuma_pole.png'],
        ],
        full: "This variant is quite similar to the regular Nusas form, but features an extended horizontal beam at the base of the support beam(s).",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e51e1c6a66_1_10",
        short: "South Sumatra Pole"
    },
    {
        id: 37,
        question: "This Nusas pole variant has a support beam that does not evenly bisect the space between the two insulators, as well as overall being off-centered, and is most common in which province?",
        answers: ['West Sulawesi'],
        images: [
            images['id_wsula_pole.png'],
        ],
        full: "Nusa-adjacent poles in Sulawesi are almost exclusively asymmetric, and are often abnormally off-center or large. Some are, however, quite normal-looking.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e51e1c6a66_1_14",
        short: "West Sulawesi Pole"
    },
    {
        id: 38,
        question: "Rectangular connectors are typical of poles in which province?",
        answers: ['Bali'],
        images: [
            images['id_bali_connector.png'],
        ],
        full: "Rectangle connectors are found almost entirely on poles in Bali. Note that not every pole will have one.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_15",
        short: "Bali Rectangular Connector"
    },
    {
        id: 39,
        question: "These A-frame poles are most common in which provinces?",
        answers: ['West Kalimantan', 'Central Kalimantan', 'East Kalimantan', 'South Kalimantan', 'North Kalimantan', 'West Nusa Tenggara', 'East Nusa Tenggara', 'Bali'],
        images: [
            images['id_aframe.png'],
        ],
        full: "A-frame poles are, by far, most common in Kalimantan and the Lesser Sunda Islands.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e8f36207a7_0_2",
        short: "Kalimantan A-Frame Poles"

    },
    {
        id: 40,
        question: "Brackets in which provinces are 'U-shaped', in that their cross section would be a 'U'? Note the small indents in the bracket in the picture.",
        answers: ['North Sulawesi', 'Central Sulawesi', 'Gorontalo'],
        images: [
            images['id_nsula_pole.png'],
        ],
        full: "Poles in northern Sulawesi – North Sulawesi, Gorontalo, and Central Sulawesi – generally feature this “U-shaped” bracket. The majority of these poles will have the “U” facing outward, but some invert the U such that it faces the pole. This pole is exclusive to northern Sulawesi – zero exceptions.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_92",
        short: "Northern/Central Sulawesi U-Shaped Bracket"
    },
    {
        id: 41,
        question: "Poles in which provinces have noticeably shorter support beams?",
        answers: ['South Sulawesi', 'Southeast Sulawesi'],
        images: [
            images['id_ssula_pole.png'],
        ],
        full: "In addition to the poles mentioned previously for southern Sulawesi, the province often has poles with support beams that are shorter than average. This is easily identifiable by the reduced distance between where the pole and support beam(s) intersect the crossbar. Poles in Southeast Sulawesi will also sometimes move the pole closer to the center. ",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_7",
        short: "Sulawesi Short Support Beams"
    },
    {
        id: 42,
        question: "If you see a single yellow band on a support pole, in which provinces could you be on the main road of?",
        answers: ['West Sulawesi', 'East Kalimantan'],
        images: [
            images['id_yellow_band.png'],
        ],
        full: "The main road through West Sulawesi uses support poles featuring nothing but a single yellow stripe. In 2023, the main road around Balikpapan started to use these poles, too.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2ee7a295a09_0_15",
        short: "West Sulawesi / Balikpapan Yellow Band Poles"
    },
    {
        id: 43,
        question: "Poles with twisted support beams are most common in which province?",
        answers: ['North Maluku'],
        images: [
            images['id_maluku_pole.png'],
        ],
        full: "A wide variety of West-Kalimantan-esque poles can be found in North Maluku, namely ones with twisted support beams or with a bracket in the middle.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g20e04d21aa4_0_97",
        short: "North Maluku Twisted Support Beams"
    },
    {
        id: 44,
        question: "These metal asymmetrical poles with a bulge that feature an additional ledge at the top are most common in which provinces?",
        answers: ['Bengkulu' ,'North Sulawesi', 'Central Sulawesi', 'Gorontalo'],
        images: [
            images['id_bengkulu_pole.png'],
        ],
        full: "The pole shown, at first similar to the bulging asymmetric poles found in East Java, is always metal and features an additional ledge at the top of the bulge. It is most common in Bengkulu near the town of Curup, and so may be called “Curup poles” – despite also existing in northern Sulawesi.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_25",
        short: "Bengkulu Curup Pole"
    },
    {
        id: 45,
        question: "Poles that are symmetric and have one insulator on one side and two on the other are most commonly found in which province?",
        answers: ['North Sumatra'],
        images: [
            images['id_nsumatra_pole.png'],
        ],
        full: "North Sumatra poles are commonly defined as symmetric and having 1 insulator on one side and 2 on the other side. However, there are poles that follow these rules that are not found in North Sumatra. Most of these are concentrated around North Sumatra, such as in Aceh and Riau.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_25",
        short: "North Sumatra 1-2 Insulator Pole"

    },
    {
        id: 46,
        question: "This X-pole with roughly equal height and length is most common in which province?",
        answers: ['Riau'],
        images: [
            images['id_riau_xpole.png'],
        ],
        full: "This huge squared X (height and length are visibly equal). Either extremely tall or extremely wide, much more so than similar X-poles in other parts of the country.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL : "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_20",
        short: "Riau X-Pole"
    },
    {
        id: 47,
        question: "This pole with small triangle-shaped wires running down the pole?",
        answers: ['Riau'],
        images: [
            images['id_riau_pole.png'],
        ],
        full: "This Riau-exclusive pole is very tall – much taller than any other pole. It has small triangle-shaped wires running down the pole.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_20",
        short: "Riau Triangle Pole"
    },
    {
        id: 48,
        question: "Poles with L-shaped rods are most common in which province?",
        answers: ['Riau', 'Bangka-Belitung Islands', 'Riau Islands'],
        images: [
            images['id_riau_pole.png'],
        ],
        full: "L-shaped rods – shortened here to “L-rods” – are unique to Riau Islands, Riau, and Bangka-Belitung Islands. As the name suggests, they are shaped like an L, with a sharp 90º angle at the base.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_37",
        short: "Riau L-Pole"
    },
    {
        id: 49,
        question: "This pole with an insulator resting on top of a rod is most common in which province?",
        answers: ['Bangka-Belitung Islands'],
        images: [
            images['id_bangka_pole.png'],
        ],
        full: "Bangka-Belitung has two unique poles: one with an insulator resting atop a rod and another with a pentagon-shaped rod on top?",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2ee7a295a09_0_82",
        short: "Bangka Belitung Insulator Pole"

    },
    {
        id: 50,
        question: "This pole with a pentagon-shaped rod on top is most common in which province?",
        answers: ['Bangka-Belitung Islands'],
        images: [
            images['id_bangka_pole2.png'],
        ],
        full: "Bangka-Belitung has two unique poles: one with an insulator resting atop a rod and another with a pentagon-shaped rod on top?",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2ee7a295a09_0_82",
        short: "Bangka Belitung Pentagon Pole"
    },
    {
        id: 51,
        question: "Poles extending past the crossbar with two 60 degree angle support beams are typical of which province?",
        answers: ['Aceh'],
        images: [
            images['id_aceh_pole.png'],
        ],
        full: "Characteristics of Aceh poles include the pole extending past the crossbar and two 60 degree angle support beams. Aceh poles can also have a fairly long beam between support poles, similar to the North Sumatra trident pole. Urban Aceh often has poles with two or three layers – the top layer will still have a 60º angle, but the lower layer(s) will have 45º angles.",
        source: "Introduction to Indonesian Utility Poles",
        sourceURL: "https://docs.google.com/presentation/d/1eK_t3Yw6kpDqf81QvdasU8yuZYApe1kapqfHzxG85Iw/edit#slide=id.g2e2731e3942_0_42",
        short: "Aceh Pole"
    },
    {
        id: 52,
        question: "Islands in what province can be recognized by this cabbage plant, almost akin to 'Hokkaido cabbage'?",
        answers: ['North Sumatra'],
        images: [
            images['id_nias_cabbage.png'],
        ],
        full: "Nias island (a part of North Sumatra) can be easily recognized by its vegetation, you will see often plants which look similar to the “Hokkaido cabbages” with these purple tinted leaves.",
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "Nias Cabbage Plant"
    },
    {
        id: 53,
        question: "Which province is known for its oil palm plantations and commonly using these large pipes on the sides of roads?",
        answers: ['Riau'],
        images: [
            images['id_riau_pipe.png'],
        ],
        full: "Riau province is characterized by its omnipresence of oil palm trees, they are present very often in the province. Riau also features these pipes, which are very common.",
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "Riau Oil Palms & Pipes"
    },
    {
        id: 54,
        question: "Which province uses this pole with connecting bars in a 30/35 degree angle from the middle of the main bar to an extra attachment bar?",
        answers: ['West Sumatra'],
        images: [
            images['id_wsuma_pole.png'],
        ],
        full: "West Sumatra poles feature connecting bars that go in a 30/35° from the middle of the main bar. The bars will go not immediately to the pole, but to a extra attachment bar.",
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "West Sumatra Pole"
    },
    {
        id: 55,
        question: "In which province can you sometimes find the word 'Nagari' instead of the word 'Negeri'? ",
        answers: ['West Sumatra'],
        images: [
            images['id_wsuma_nagari.png'],
        ],
        full: 'The word “nagari” (rather than the more commonly used "negeri") is unique to West Sumatra. It means village.',
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "West Sumatra Nagari"
    },
    {
        id: 56,
        question: "Long stickers on poles (with varying colors) are common in which province?",
        answers: ['West Java'],
        images: [
            images['id_wjava_sticker.png'],
        ],
        full: "West Java features this unique long sticker (the color doesn't matter).",
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "West Java Sticker"
    },
    {
        id: 57,
        question: "Which province uses this yellow sticker (with varying formats) on poles?",
        answers: ['Central Java'],
        images: [
            images['id_cjava_sticker.png'],
        ],
        full: "Central Java features this yellow sticker. Keep in mind that the formats may slightly differ.",
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "Central Java Yellow Sticker"
    },
    {
        id: 58,
        question: "This pavement with two lines splitted is most common in which province?",
        answers: ['Central Java'],
        images: [
            images['id_cjava_pavement.png'],
        ],
        full: "Central Java features this pavement, with two lines splitted, it’s like 80% Central Java, can be West or East otherwise.",
        source: "An Introductory Indonesia Guide",
        sourceURL: "https://docs.google.com/document/d/1MOGq5HlvxqOZSlTsGVTurawStKt9dvRAMTYECHR54oM",
        short: "Central Java Pavement"
    }
   
    
]

let sulawesi = ['North Sulawesi', 'Central Sulawesi', 'West Sulawesi', 'South Sulawesi', 'Southeast Sulawesi', 'Gorontalo'];
let sumatra = ['Riau', 'North Sumatra', 'South Sumatra', 'Aceh', 'West Sumatra', 'Bengkulu', 'Jambi', 'Lampung', 'Bangka-Belitung Islands', 'Riau Islands'];
let kalimantan = ['South Kalimantan', 'Central Kalimantan', 'East Kalimantan', 'West Kalimantan', 'North Kalimantan'];
let java = ['West Java', 'Central Java', 'East Java', 'Special Region of Yogyakarta', 'Banten', 'Jakarta'];

export const idSources = ['Plonk It Guide', 'Introduction to Indonesian Utility Poles', 'An Introductory Indonesia Guide']
// maybe the one ID guide document?
