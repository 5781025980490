import React, { useState } from 'react';
import './App.css';
import { Analytics } from "@vercel/analytics/react";
import Header from './Header/Header.js';
import NavBar from './NavBar/NavBar.js';
import Footer from './Footer/Footer.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home/Home.js';
import UnitedStates from './pages/UnitedStates/UnitedStates.js';
import Mexico from './pages/Mexico/Mexico.js';
import Canada from './pages/Canada/Canada.js';
import Brazil from './pages/Brazil/Brazil.js';
import Japan from './pages/Japan/Japan.js';
import Australia from './pages/Australia/Australia.js';
import Indonesia from './pages/Indonesia/Indonesia.js';
import Malaysia from './pages/Malaysia/Malaysia.js';
import Changelog from './Changelog/Changelog.js';
import NotFound from './NotFound.js';
import MapLoader from './MapLoader'; 

function App() {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  return (
    <div className="app-container">
      <div className="content">
        <Analytics />
        <BrowserRouter>
          <MapLoader
            googleMapsApiKey={googleMapsApiKey}
            setGoogleLoaded={setGoogleLoaded}
            setErrorLoading={setErrorLoading}
          />
          <div style={{ marginTop: "10px" }}>
            <NavBar />
          </div>
          <Routes>
            <Route path="/" index element={<Home googleLoaded={googleLoaded} errorLoading={errorLoading} />} />
            <Route path="/united-states" element={<UnitedStates googleLoaded={googleLoaded} />} />
            <Route path="/mexico" element={<Mexico googleLoaded={googleLoaded} />} />
            <Route path="/canada" element={<Canada googleLoaded={googleLoaded} />} />
            <Route path="/brazil" element={<Brazil googleLoaded={googleLoaded} />} />
            <Route path="/japan" element={<Japan googleLoaded={googleLoaded} />} />
            <Route path="/australia" element={<Australia googleLoaded={googleLoaded} />} />
            <Route path="/indonesia" element={<Indonesia googleLoaded={googleLoaded} />} />
            <Route path="/malaysia" element={<Malaysia googleLoaded={googleLoaded} />} />
            <Route path="/changelog" element={<Changelog />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
