import React, { useEffect } from 'react';
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';
import * as turf from '@turf/turf';

// BR State GeoJSON Source: https://github.com/codeforgermany/click_that_hood/blob/main/public/data/brazil-states.geojson
import brazilData from './br-states.json';

const containerStyle = {
  width: '850px',
  height: '100%',
};

const center = {
  lat: -14.2350, 
  lng: -51.9253, 
};

const BrazilMap = ({ handleStateClick, showTips }) => {

  const onMapClick = (event) => {
    const point = turf.point([event.latLng.lng(), event.latLng.lat()]);
    const state = brazilData.features.find((feature) => {
      if (feature.geometry.type === 'Polygon') {
        const polygon = turf.polygon(feature.geometry.coordinates);
        return turf.booleanPointInPolygon(point, polygon);
      } else if (feature.geometry.type === 'MultiPolygon') {
        return feature.geometry.coordinates.some((polygonCoords) => {
          const polygon = turf.polygon(polygonCoords);
          return turf.booleanPointInPolygon(point, polygon);
        });
      }
      return false;
    });

    if (state) {
      handleStateClick(state.properties.name);
    } else {
      console.log("No state found at this location.");
    }
  };

  const formatCoordinates = (coords) => {
    const flattenCoords = (coordSet) => {
      return coordSet.map(coord => {
        if (Array.isArray(coord) && coord.length >= 2) {
          const [lng, lat] = coord;
          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        }
        return null;
      }).filter(coord => coord !== null);
    };

    if (Array.isArray(coords[0][0])) {
      return coords.map(coordSet => flattenCoords(coordSet));
    } else {
      return [flattenCoords(coords)];
    }
  };

  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    // <LoadScript googleMapsApiKey={key}
    //   loadingElement={
    //     <p>Loading... <br />If the map does not load, try refreshing the page.</p>
    //   }
    // >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={4}
        onClick={onMapClick}
        options={{
          gestureHandling: 'greedy',
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }}
      >
        {/* Only render polygons if tips are not excluded */}
        {showTips && brazilData.features.map((feature, index) => {
          const paths = formatCoordinates(feature.geometry.coordinates);
          if (paths.length === 0 || paths.some(path => path.length === 0)) {
            return null;
          }
          return (
            <Polygon
              key={index}
              paths={paths.flat()}
              onClick={() => handleStateClick(feature.properties.name)}
            />
          );
        })}
      </GoogleMap>
    // </LoadScript>
  );
};

export default BrazilMap;
