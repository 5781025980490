import allImages from '../../images';
const images = allImages['au'];

export const auTips = [
    {
        id: 1,
        question: "This bus stop is found in which state?",
        answers: ['Victoria'],
        images: [
            images['au_victoria_bus_stop.png'],
        ],
        full: "This white and orange bus stop is found in Victoria.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Victoria Bus Stop",
        links: ["https://maps.app.goo.gl/mjwLSWWXcu6TZgUX8"]
    },
    {
        id: 2,
        question: "This bus stop is found in which state?",
        answers: ['Australian Capital Territory'],
        images: [
            images['au_act_bus_stop.png'],
        ],
        full: "This blue and yellow bus stop is found in the Australian Capital Territory.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "ACT Bus Stop",
        links: [
            "https://maps.app.goo.gl/3KHzFnTshhjhwura7"
        ]
    },
    {
        id: 3,
        question: "This bus stop is found in which state?",
        answers: ['New South Wales'],
        images: [
            images['au_nsw_bus_stop.png'],
        ],
        full: "This dark blue bus stop with a yellow circle with a bus in the middle is found in New South Wales. The shape of the sign itself may vary, but this design is the same.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NSW Bus Stop"
    },
    {
        id: 4,
        question: "This bus stop is found in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_bus_stop.png'],
        ],
        full: "This blue and white bus stop is found in Queensland. It will often have a semicircular-ish shape.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Queensland Bus Stop"
    },
    {
       id: 5,
       question: "This bus stop is found in which state?",
        answers: ['South Australia'],
        images: [
            images['au_sa_bus_stop.png'],
        ],
        full: "This yellow bus stop is found in South Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "South Australia Bus Stop"

    },
    {
        id: 6,
        question: "This bus stop is found in which state?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_bus_stop.png'],
        ],
        full: "This tall and thin bus stop typically with green and yellow is found in Western Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Western Australia Bus Stop"
    },
    {
        id: 7,
        question: "This bus stop with the word 'Metro' is found in which state?",
        answers: ['Tasmania'],
        images: [
            images['au_tas_bus_stop.png'],
        ],
        full: "This bus stop with the word 'Metro' is found in Tasmania. It can have various designs, but will always say 'Metro'.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Tasmania Bus Stop"
    },
    {
        id: 8,
        question: "Yellow signposts are specific to which state?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_signpost.png'],
        ],
        full: "Yellow signposts are specific to Western Australia. NOTE: Although South Australia does not use yellow signposts for regular road signs, SA bus stops will have yellow posts.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Yellow Signpost"
    },
    {
        id: 9,
        question: "Bollards with brown paint on the bottom are found in which state?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_bollard.png'],
        ],
        full: "Bollards with brown paint at the bottom are most commonly found in Western Australia. NOTE: Brown paint at the bottom of exposed bollards can also rarely be seen in other states, however the brown section will not be as large.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Bottom Brown Paint Bollard"
    },
    {
        id: 10,
        question: "Black and white striped bollards are found in which state?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_striped_bollard.png'],
        ],
        full: "These black-and-white stripy bollards are unique to Western Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Striped Bollard"
    },
    {
        id: 11,
        question: "Poles in which region often have green painted bottoms?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_green_pole.png'],
        ],
        full: "Poles in Western Australia often have green painted bases, and sometimes a white sticker or a red and white band attached.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Green Painted Pole Base"
    },
    {
        id: 12,
        question: "This trident pole is found in which states?",
        answers: ['Western Australia', 'Victoria'],
        images: [
            images['au_trident_pole.png'],
        ],
        full: "A trident-like pole top can sometimes be seen in Western Australia and Victoria.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA/Vic Trident Pole"

    },
    {
        id: 13,
        question: "This dark green property marker can be found in which state?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_green_marker.png'],
        ],
        full: "You can find these dark green property markers on the sides of roads in Western Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Green Property Marker"
    },
    {
        id: 14,
        question: "This white property marker can be found in which state?",
        answers: ['South Australia'],  
        images: [
            images['au_sa_white_marker.png'],
        ],
        full: "You can find these white property markers on the sides of roads in South Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "SA White Property Marker"

    },
    {
        id: 15,
        question: "Stobie poles are found in which state?",
        answers: ['South Australia'],
        images: [
            images['au_sa_pole.png'],
        ],
        full: "The iconic Stobie pole, made of steel and concrete, is specific to South Australia. The pole tops (trident or rotated letter “E”) are also unique. NOTE: A few thousand of these poles can be found in other states, most notably Tasmania.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "SA Stobie Pole"
    },
    {
        id: 16,
        question: "Which state is the only one to use 25 kph in school zones?",
        answers: ['South Australia'],
        images: [
            images['au_sa_school_zone.png'],
        ],
        full: "South Australia is the only Australian state that uses a 25 speed limit in school zones.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "SA School Zone 25 KPH"
    },
    {
        id: 17,
        question: "Flat signposts (rather than the standard round) can sometimes be found in which states?",
        answers: ['Western Australia', 'South Australia'],
        images: [
            images['au_flat_signpost.png'],
        ],
        full: "Some signposts in South Australia and Western Australia are flat rather than round. NOTE: These signposts are usually yellow in Western Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA/SA Flat Signpost"
    },
    {
        id: 18,
        question: "Gaps in outer roadlines are found in which states?",
        answers: ['Australian Capital Territory', 'South Australia'],
        images: [
            images['au_roadline_gap.png'],
        ],
        full: "In South Australia and the ACT, you can sometimes find gaps in the outer roadlines. NOTE: In the ACT, there will always be a reflector in the gap. This is only occasionally the case in SA.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "ACT/SA Roadline Gap"
    },
    {
        id: 19,
        question: "These rusty poles with multiple holes are found in which state?",
        answers: ['Northern Territory'],
        images: [
            images['au_nt_pole.png'],
        ],
        full: "These rusty metal poles, containing several holes, are unique to the Northern Territory. NOTE: These poles can also sometimes be found in some areas of northern WA, such as Wyndham.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NT Holey Pole"
    },
    {
        id: 20,
        question: "Yellow stickers on signposts are sometimes found in which state?",
        answers: ['Northern Territory'],
        images: [
            images['au_nt_sticker.png'],
        ],
        full: "Signposts in the Northern Territory can feature these yellow stickers.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NT Signpost Yellow Sticker"
    },
    {
        id: 21,
        question: "A speed limit of 130 can only be found in which state?",
        answers: ['Northern Territory'],
        images: [
            images['au_nt_speed_limit.png'],
        ],
        full: "Within Australia, a speed limit of 130 km/h can only be found in the Northern Territory.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NT 130 Speed Limit"
    },
    {
        id: 22,
        question: "Traffic lights with a white outline are found in which states?",
        answers: ['Western Australia', 'Queensland', 'New South Wales', 'Australian Capital Territory', 'Tasmania'],
        images: [
            images['au_traffic_light_outline.png'],
        ],
        full: "Traffic lights with a white outline around the frame are found in the eastern and western states of WA, QLD, NSW, ACT and Tas. Traffic lights without a border are found in the remaining, central states.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Traffic Light White Outline in Outer States"
    },
    {
        id: 23,
        question: "Road signs with a fluorescent border are almost exclusively found in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_outline.png'],
        ],
        full: "Road signs with a fluorescent border are almost exclusively found in Queensland.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Queensland Fluorescent Border Sign"
    },
    {
        id: 24,
        question: "This black pole marking, often accompanied by diagonal numbers, is found in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_pole_marking.png'],
        ],
        full: 'These black markings are specific to electricity poles in Queensland. They are often accompanied by diagonal numbers.',
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Queensland Black Pole Marking"
    },
    {
        id: 25,
        question: "Circular blue stickers on poles are found in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_blue_sticker.png'],
        ],
        full: "Circular blue stickers can be found on Queensland poles. You may occasionally see the same type of sticker in a different colour, or with the colour almost completely faded. NOTE: Blue stickers can also be found on French poles.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Queensland Blue Pole Sticker"
    },
    {
        id: 26,
        question: "This pole top with coils that are angled upwards on both sides can be found in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_pole_top.png'],
        ],
        full: "Queensland features these unique pole tops with coils that are angled upwards on the sides.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Queensland Angled Coil Pole Top"
    },
    {
        id: 27,
        question: "Red no stopping signs are only found in which state?",
        answers: ['New South Wales'],
        images: [
            images['au_nsw_no_stopping.png'],
        ],
        full: "New South Wales is the only state to use red ‘No Stopping’ signs. NOTE: All other states use white versions.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NSW Red No Stopping Sign"
    },
    {
        id: 28,
        question: "Which states sometimes use this thinner version of the Keep Left sign without an arrow?",
        answers: ['Queensland', 'New South Wales', 'Northern Territory'],
        images: [
            images['au_thin_keep_left.png'],
        ],
        full: "Some ‘Keep Left’ signs in New South Wales, Queensland, and the Northern Territory use this thinner version, which does not include an arrow.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "QLD/NSw/NT Thin Keep Left Sign"
    },
    {
        id: 29,
        question: "Guardrail endings featuring a black dash on a yellow background rather than a white arrow on a black background are found in which states?",
        answers: ['Australian Capital Territory', 'New South Wales'],
        images: [
            images['au_nsw_guardrail_ending.png'],
        ],
        full: "This guardrail ending, featuring a black dash on a yellow background, is almost exclusive to New South Wales and the ACT.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "ACT/NSW Yellow Guardrail Ending"
    },
    {
       id: 30,
       question: "Signs that say 'No Parking' and 'No Stopping' rather than simply the associated symbol are found in which state?",
       answers: ['Australian Capital Territory'],
         images: [
              images['au_act_sign.png'],
         ], 
        full: "The white ‘No Stopping’ and ‘No Parking’ signs that include text are unique to the Australian Capital Territory.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "ACT No Parking/No Stopping Sign"

    },
    {
        id: 31,
        question: "The majority of concrete poles in the country are found in which state?",
        answers: ['Victoria'],
        images: [
            images['au_vic_concrete_pole.png'],
        ],
        full: "According to a 2006 review, 64% of all concrete poles (rather than the more standard wooden) are located in the state of Victoria. NOTE: Victoria itself has about 25% concrete poles, so do not expect poles in the state to typically be concrete.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Victoria Concrete Pole"

    },
    {
        id: 32,
        question: "This pole top with three circular coils can be found in which state?",
        answers: ['Victoria'],
        images: [
            images['au_vic_pole_top.png'],
        ],
        full: "Victorian pole tops feature 3 circular coils; 2 on the sides, and 1 vertical one at the top. At times, other extensions can be seen on these poles.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Victoria 3 Coil Pole Top"
    },
    {
        id: 33,
        question: "Hydrant poles with a blue circle and a red section on top are found in which state?",
        answers: ['Victoria'],
        images: [
            images['au_vic_hydrant_pole.png'],
        ],
        full: "You can find these white hydrant poles that feature a blue circle and a red section on top of it in Victoria.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Victoria Hydrant Pole"
    },
    {
        id: 34,
        question: "Fire hydrants with red painted tops can be found in which state?",
        answers: ['Victoria'],
        images: [
            images['au_vic_hydrant.png'],
        ],
        full: "These fire hydrants with red painted tops are unique to Victoria.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Victoria Red Top Hydrant"
    },
    {
        id: 35,
        question: "Roads starting with the letter 'C' can only be found in which states?",
        answers: ['Tasmania', 'Victoria'],
        images: [
            images['au_tas_c_road.png'],
        ],
        full: "Roads that start with the letter ‘C’ are only found in Victoria and Tasmania. In addition, South Australia has some C roads near the border with Victoria. NOTE: Since these are smaller and less major roads, Generation 1 or 2 is common.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Tas/Vic C Road"
    },
    {
        id: 36,
        question: "Olive green possom guards are found in which state?",
        answers: ['Tasmania'],
        images: [
            images['au_tas_possom_guard.png'],
        ],
        full: "Olive-green possum guards are only found on electricity poles in Tasmania. NOTE: Possum guards are found all over Australia. The colour is what distinguishes them.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Tasmania Olive Green Possum Guard"

    },
    {
        id: 37,
        question: "In which state can white directional signs be found?",
        answers: ['Tasmania'],
        images: [
            images['au_tas_white_sign.png'],
        ],
        full: "White directional signs can only be found in Tasmania.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Tasmania White Directional Sign"
    },
    {
        id: 38,
        question: "Reflectors that are shorter than usual are most common in which states?",
        answers: ['Queensland', 'South Australia'],
        images: [
            images['au_short_reflector.png'],
        ],
        full: "Reflectors that are shorter in height than the regular ones are most common in Queensland and South Australia. NOTE: Short reflectors can be found in every state, but are much less common outside of QLD and SA.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "QLD/SA Short Reflector"
    },
    {
        id: 39,
        question: "Which state is generally characterized by reddish-orangish soil?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_red_soil.png'],
        ],
        full: "Western Australia, and especially the Perth area, is known for its reddish-orange soil. NOTE: Although this is very useful, there are numerous areas in WA with light soil, and conversely there are areas in other states with reddish-orange soil.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Red Soil"
    },
    {
        id: 40,
        question: "Which state most commonly has these brush plants?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_plant.png'],
        ],
        full: "These brush plants are commonly seen in Western Australia.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA Brush Plant"
    },
    {
        id: 41,
        question: "The area characterized by dark red soil and rich green grass and trees is found in which state?",
        answers: ['Northern Territory'],
        images: [
            images['au_darwin.png'],
        ],
        full: "The vegetation in the area surrounding Darwin (at the north of the Northern Territory) is extremely distinct. It features dark red soil, as well as rich green grass and trees. The tree trunks are typically black.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Darwin Area Vegetation"
    },
    {
        id: 42,
        question: "These termite mounds are most common in which state?",
        answers: ['Northern Territory'],
        images: [
            images['au_nt_termite_mound.png'],
        ],
        full: "Termite mounds can sometimes be seen in the Northern Territory and rarely northern WA and QLD. They appear as brown, spiky formations rising out of the ground.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NT Termite Mounds"
    },
    {
        id: 43,
        question: "Which state(s) use the area code 03?",
        answers: ['Victoria', 'Tasmania'],
        images: [
            images['au_03_code.png'],
        ],
        full: "The area code 03 is used in Victoria and Tasmania.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "Vic/Tas 03 Area Code"
    },
    {
        id: 44,
        question: "Which state(s) use the area code 02?",
        answers: ['New South Wales', 'Australian Capital Territory'],
        images: [
            images['au_02_code.png'],
        ],
        full: "The area code 02 is used in New South Wales and the Australian Capital Territory.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "NSW/ACT 02 Area Code"
    },
    {
        id: 45,
        question: "Which state(s) use the area code 07?",
        answers: ['Queensland'],
        images: [
            images['au_07_code.png'],
        ],
        full: "The area code 07 is used in Queensland.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "QLD 07 Area Code"
    },
    {
        id: 46,
        question: "Which state(s) use the area code 08?",
        answers: ['Western Australia', 'South Australia', 'Northern Territory'],
        images: [
            images['au_08_code.png'],
        ],
        full: "The area code 08 is used in Western Australia, South Australia, and the Northern Territory.",
        source: 'Plonk It Guide',
        sourceURL: 'https://www.plonkit.com/australia',
        short: "WA/SA/NT 08 Area Code"
    },
    {
        id: 47,
        question: "Octagonal wooden poles are common in the largest city of which state?",
        answers: ['New South Wales'],
        images: [
            images['au_sydney_pole.png'],
        ],
        full: "Octagonal wooden poles are common in Sydney, New South Wales.",
        source: 'Definitive Australia Guide',
        sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
        short: "Sydney Octagonal Pole"

    },
    {
        id: 48,
        question: "This pole is most common in which state?",
        answers: ['New South Wales'],
        images: [
            images['au_nsw_pole.png'],
        ],
        full: "This type of pole is most common in New South Wales.",
        source: 'Definitive Australia Guide',
        sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
        short: "NSW Pole"
    },
    {
        id: 49,
        question: "This pole is most common in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_pole.png']
        ],
        full: "This type of pole resembling a < symbol is most common in Queensland.",
        source: "Definitive Australia Guide",
        sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
        short: "Queensland < Pole"
    },
    // {
    //     id: 49,
    //     question: "Which state uses this bolt above the number and at the bottom of speed limit signs?",
    //     answers: ['New South Wales'],
    //     images: [
    //         images['au_nsw_speed_limit.png'],
    //     ],
    //     full: "New South Wales uses a bolt inside the circle, above the number, and at the bottom of speed limit signs.",
    //     source: 'Definitive Australia Guide',
    //     sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
    //     short: "NSW Speed Limit Bolt"
    // },
    {
        id: 50,
        question: "Plates with a tinge of red are found in which state?",
        answers: ['Queensland'],
        images: [
            images['au_qld_plate.png'],
        ],
        full: "Plates with red text are found in Queensland. Green plates were stopped in 2001, but you still might see them particularly in Gen 2.",
        source: 'Definitive Australia Guide',
        sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
        short: "QLD Red Plate"
    },
    {
        id: 51,
        question: "Yellow plates are found in which state?",
        answers: ['New South Wales'],
        images: [
            images['au_nsw_plate.png'],
        ],
        full: "Yellow plates are found in New South Wales.",
        source: 'Definitive Australia Guide',
        sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
        short: "NSW Yellow Plate"
    },
    {
        id: 52,
        question: "Sloped kerbs are most common in which state?",
        answers: ['Western Australia'],
        images: [
            images['au_wa_kerb.png'],
        ],
        full: "These sloped kerbs (as opposed to straight 90 degree kerbs) are most common in Western Australia.",
        source: 'Definitive Australia Guide',
        sourceURL: "https://docs.google.com/presentation/d/1ZXxLem3YRLWw_tmP6v31VrkiqMvcoE1GXWbTN7Pi0Ds/edit#slide=id.g1122d5ba1de_0_0",
        short: "WA Sloped Kerb"
    },


]

export const auSources = ['Plonk It Guide', 'Definitive Australia Guide']