import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';

const CustomizationModal = ({ show, handleClose, tips, sources, handleIdFilterChange, country }) => {
    const [idFilter, setIdFilter] = useState(tips.map(tip => tip.id));

    useEffect(() => {
        setIdFilter(tips.map(tip => tip.id));
    }, [tips]);

    const toggleTip = (tipId) => {
        const updatedIdFilter = idFilter.includes(tipId)
            ? idFilter.filter(id => id !== tipId)
            : [...idFilter, tipId];
        setIdFilter(updatedIdFilter);
        handleIdFilterChange(updatedIdFilter);
    };

    const includeAllTipsFromSource = (source) => {
        const tipsFromSource = tips.filter(tip => tip.source === source).map(tip => tip.id);
        const updatedIdFilter = Array.from(new Set([...idFilter, ...tipsFromSource]));
        setIdFilter(updatedIdFilter);
        handleIdFilterChange(updatedIdFilter);
    };

    const excludeAllTipsFromSource = (source) => {
        const tipsFromSource = tips.filter(tip => tip.source === source).map(tip => tip.id);
        const updatedIdFilter = idFilter.filter(id => !tipsFromSource.includes(id));
        setIdFilter(updatedIdFilter);
        handleIdFilterChange(updatedIdFilter);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Customize Tips</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Tabs defaultActiveKey="all" id="source-tabs">
                        <Tab eventKey="all" title="All">
                            <Form.Group>
                                <Form.Label>{`${country} has ${tips.length} tips.`}</Form.Label> {/* Updated Text */}
                                {tips.map(tip => (
                                    <Form.Check 
                                        key={tip.id}
                                        type="checkbox"
                                        label={tip.short}
                                        value={tip.id}
                                        onChange={() => toggleTip(tip.id)}
                                        checked={idFilter.includes(tip.id)}
                                    />
                                ))}
                            </Form.Group>
                        </Tab>
                        {sources.map(source => (
                            <Tab key={source} eventKey={source} title={source}>
                                <Form.Group>
                                    <Form.Label>{source}</Form.Label>
                                    <Button variant="primary" onClick={() => includeAllTipsFromSource(source)}>
                                        Include All
                                    </Button>
                                    <Button variant="danger" onClick={() => excludeAllTipsFromSource(source)} style={{marginLeft: '10px'}}>
                                        Exclude All
                                    </Button>
                                    {tips.filter(tip => tip.source === source).map(filteredTip => (
                                        <Form.Check 
                                            key={filteredTip.id}
                                            type="checkbox"
                                            label={filteredTip.short}
                                            value={filteredTip.id}
                                            onChange={() => toggleTip(filteredTip.id)}
                                            checked={idFilter.includes(filteredTip.id)}
                                        />
                                    ))}
                                </Form.Group>
                            </Tab>
                        ))}
                    </Tabs>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomizationModal;
